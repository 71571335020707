import React from "react";
import {
  Card,
  ProgressBar,
  Table,
} from "react-bootstrap";
import { MdOpenInFull } from "react-icons/md";
import { useNavigate } from "react-router";
import moment from "moment";
import { cypherService } from "../../../service/cypherService";

const MyQuests = (props) => {

  const {  questList } = props;

  const navigate = useNavigate();

  const expandCard = (e) => {
    let elem = e.target.parentElement.closest(".card");
    if (elem.classList.contains("expand")) {
      elem.classList.remove("expand");
    } else {
      elem.classList.add("expand");
    }
    setTimeout(() => {
      cypherService.setSessionStorage('expandParentQuestCount', props.questCount);
      cypherService.setSessionStorage('expandParentQuestList', JSON.stringify(props.questList));
      navigate("/parent/my-quest");
    }, 200);
  };

  const getProgressBar = (row) => {
    if (row.scorePercentage < 40) {
      return (
        <div className="progress-row">
          <ProgressBar variant="danger" now={row.scorePercentage} />
          <span>{row.score}</span>
        </div>
      )
    }
    else if (row.scorePercentage >= 40 && row.scorePercentage < 80) {
      return (
        <div className="progress-row">
          <ProgressBar variant="warning" now={row.scorePercentage} />
          <span>{row.score}</span>
        </div>
      )
    }
    else {
      return (
        <div className="progress-row">
          <ProgressBar variant="success" now={row.scorePercentage} />
          <span>{row.score}</span>
        </div>
      )
    }
  }

  const getTableData = () => {
    if (questList && questList.length === 0) {
      return (
        <div className="no-data">
          No data found
        </div>
      );
    }
    else {
      return (
        <Table responsive className="homework-table dashboard-table">
          <thead>
            <tr>
              <th>Quest</th>
              <th>Completion Status</th>
              <th>Assigned</th>
              <th>Deadline</th>
            </tr>
          </thead>
          <tbody>
            {
              questList && questList.map((item, index) => (
                <tr>
                  <td>{item.questName}</td>
                  <td>{getProgressBar(item)}</td>
                  <td>{moment(item.assignedDate).format("DD/MM/YYYY")}</td>
                  <td>{moment(item.deadLine).format("DD/MM/YYYY")}</td>
                </tr>
              ))
            }
          </tbody>
        </Table>
      )
    }
  }

  return (
    <>
      <Card className="my-quests">
        <Card.Title>
          <span>My Quests</span>
          <div
            className="expand"
            onClick={(e) => {
              expandCard(e);
            }}
          >
            <MdOpenInFull />
          </div>
        </Card.Title>
        <Card.Body className="card-body">
          {getTableData()}
        </Card.Body>
      </Card>
    </>
  );
};

export default MyQuests;
