import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { cypherService } from "../../../service/cypherService";
import { EndpointService } from "../../../service/endpointService";
import HTTPService from "../../../service/HTTPService";

const DeleteModal = (props) => {

  const { rowData, onHide, getTableItem, setFilterParams, setParentSearchTxtBoxValue, loader, setLoader } = props;
  const [questName, setQuestName] = useState("");
  const [questId, setQuestId] = useState("");
  
  useEffect(() => {
    if (rowData) {
      setQuestName(rowData.row.original.questName);
      setQuestId(rowData.row.original.questId);
    }
  }, [rowData]);

  const deleteQuest = () => {
    onHide();
    setLoader(true);
    const apiUrl = EndpointService.deleteQuest + questId;
    HTTPService.delete(apiUrl, null)
      .then((response) => {
        setLoader(false);
        if (response.status.success == "Success") {
          toast.success(response.status.message);
          setParentSearchTxtBoxValue('');
          cypherService.setSessionStorage('createElement', 'quest');
          let tempSelectedGrade = cypherService.getSessionStorage('selectedFilterGrade');
          let tempSelectedGradeStatus = cypherService.getSessionStorage('selectedFilterGradeStatus');
          let tempFilterParams = {
            "quest": tempSelectedGrade,
            "questStatus": tempSelectedGradeStatus
          }
          setFilterParams({ ...tempFilterParams });
          cypherService.setSessionStorage("expandQuestCount", null);
          cypherService.setSessionStorage("expandQuestList", null);
          getTableItem(tempFilterParams, '', 0, 10);
        }
        if (response.status.success == "Fail") {
          toast.error(response.status.message);
        }
      })
      .catch((error) => {
        toast.error(error.response.data.status.message);
        throw error;
      });
  };

  return (
    <Modal {...props} size="md" centered className="delete-modal">
      <Modal.Header>
        <Modal.Title>Delete</Modal.Title>
      </Modal.Header>
      <Modal.Body className="show-grid">
        <p>
          Are you sure you want to delete <strong>{questName}?</strong>
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-primary" onClick={props.onHide}>
          Cancel
        </Button>
        <Button
          onClick={() => {
            deleteQuest();
          }}
        >
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteModal;
