import { Route, Routes } from "react-router-dom";
import Dashboard from "../components/teacher/dashboard/Dashboard";
import CreateQuestion from "../components/teacher/createQuestion/CreateQuestion";
import AssignQuestion from "../components/teacher/assignQuestion/AssignQuestion";
import Login from "../components/login/Login";
import ForgotPassword from "../components/login/ForgotPassword";
import MyQuestList from "../components/teacher/myQuests/MyQuestList";
import MyStudentList from "../components/teacher/myStudent/MyStudentList";
import ProtectedRoute from "./ProtectedRoute";
import ResetPassword from "../components/login/ResetPassword";
import MyReportList from "../components/teacher/myReports/MyReportList";
import ParentDashboard from "../components/parent/dashboard/ParentDashboard";
import MyReportDetailsList from "../components/teacher/myReports/MyReportDetails";
import ParentMyQuestList from "../components/parent/myQuests/MyQuestList";
import SchoolDashboard from "../components/school/dashboard/Dashboard";
import SchoolMyQuestList from "../components/school/myQuests/MyQuestList";
import AllTeachersList from "../components/school/allTeachers/AllTeachersList";
// import MyReportDetailsList from "../components/teacher/myReports/MyReportDetails";

function Routing() {
  // const [user, setUser] = useState(cypherService.getSessionStorage("appToken"))
  return (
    <Routes>  
      <Route path="/" element={<Login />}></Route>
      <Route path="/forgot-password" element={<ForgotPassword />}></Route>
      <Route path="/reset-password" element={<ResetPassword />}></Route>

      {/* protected url map */}
      <Route element={<ProtectedRoute />}>
        <Route path="/teacher/dashboard" element={<Dashboard />} />
        <Route path="/teacher/assign-quest" element={<AssignQuestion />}></Route>
        <Route path="/teacher/create-questions" element={<CreateQuestion />}></Route>
        <Route path="/teacher/my-quest" element={<MyQuestList />}></Route>
        <Route path="/teacher/my-student" element={<MyStudentList />}></Route>
        <Route path="/teacher/my-report" element={<MyReportList />}></Route>
        <Route path="/teacher/my-report-details" element={<MyReportDetailsList />}></Route>
        <Route path="/parent/dashboard" element={<ParentDashboard />}></Route>
        <Route path="/parent/my-quest" element={<ParentMyQuestList />}></Route>
        <Route path="/school/dashboard" element={<SchoolDashboard />}></Route>
        <Route path="/school/my-quest" element={<SchoolMyQuestList />}></Route>
        <Route path="/school/all-teachers" element={<AllTeachersList />}></Route>
      </Route>

      {/* <Route path="*" element={<PageNotFound />} /> */}
    </Routes>
  );
}
export default Routing;
