import React, { useEffect, useState } from 'react'
import { Button, Form, InputGroup, Spinner } from "react-bootstrap";
import { ErrorMessage, FieldArray, Formik } from "formik";
import { toast } from "react-toastify";
import { EndpointService } from "../../../service/endpointService";
import HTTPService from "../../../service/HTTPService";
import { Schema } from "./Schema";
import { MdAdd } from 'react-icons/md';

const MultiChoiceQuestion = (props) => {

  const [initialValues, setIntitialValues] = useState();
  const [loader, setLoader] = useState(false);
  const [editLoader, setEditLoader] = useState(false);
  const [editQuestionDetails, setEditQuestionDetails] = useState(null);

  useEffect(() => {
    setIntitialValues({
      question: '',
      options: [
        {
          option: ''
        },
        {
          option: ''
        },
        {
          option: ''
        },
        {
          option: ''
        }
      ],
      answer: ''
    });
  }, [])

  useEffect(() => {
    if (props.selectedQuestionDetail) {
      getEditQuestionDetail()
    }
    else {
      setIntitialValues({
        question: '',
        options: [
          {
            option: ''
          },
          {
            option: ''
          },
          {
            option: ''
          },
          {
            option: ''
          }
        ],
        answer: ''
      });
    }
  }, [props.selectedQuestionDetail])

  const getEditQuestionDetail = () => {
    setEditLoader(true);
    let url = EndpointService.getQuestQuestionUrl + props.selectedQuestionDetail.questQuestionaireId;
    HTTPService.get(url).then((response) => {
      if (response.status.success.toLowerCase() === 'success') {
        let tempQuestionDetails = response.data;
        setEditLoader(false);
        setEditQuestionDetails(tempQuestionDetails);
        if (tempQuestionDetails) {
          let tempEditValue = {
            question: tempQuestionDetails.question,
            options: [],
            answer: ''
          }
          tempQuestionDetails.questQuestionaireOption.sort(sortByDisplaySequence);
          tempQuestionDetails.questQuestionaireOption.map((item, index) => {
            tempEditValue.options.push({ option: item.option });
            if (item.isCorrectAnswer) {
              tempEditValue.answer = index.toString();
            }
          })
          setIntitialValues({ ...tempEditValue });
        }
      }
    })
      .catch((error) => {
        setEditLoader(false);
        toast.error(error.response.data.status.message);
      })
  }

  const sortByDisplaySequence = (valueOne, valueTwo) => {
    if (valueOne.displaySequence < valueTwo.displaySequence) {
      return -1;
    }
    if (valueOne.displaySequence > valueTwo.displaySequence) {
      return 1;
    }
    return 0;
  }

  const onSubmit = (values, { resetForm }) => {
    if (editQuestionDetails === null) {
      saveQuestion(values, resetForm);
    }
    else {
      updateQuestion(values, resetForm);
    }
  }

  const saveQuestion = (values, resetForm) => {
    setLoader(true);
    let url = EndpointService.saveQUestionaireUrl;
    let optionsPayload = [];
    values.options && values.options.map((item, index) => {
      optionsPayload.push({
        "displaySequence": index + 1,
        "isCorrectAnswer": values.answer === index.toString() ? true : false,
        "option": item.option
      })
    })
    let payload = {
      "quest": {
        questId: props.questId
      },
      "questQuestionaireOption": optionsPayload,
      "question": values.question,
      "questionType": "MCQ"
    }
    url = url + `?questQuestionaireDto=${encodeURIComponent(JSON.stringify(payload))}`
    HTTPService.post(url, payload).then((response) => {
      setLoader(false);
      if (response.status.success.toLowerCase() === 'success') {
        toast.success(response.status.message);
        resetForm();
        props.getQuestionList();
      }
    })
      .catch((error) => {
        setLoader(false);
        toast.error(error.response.data.status.message);
      })
  }

  const updateQuestion = (values, resetForm) => {
    setLoader(true);
    let url = EndpointService.saveQUestionaireUrl;
    let optionsPayload = [];
    values.options && values.options.map((item, index) => {
      optionsPayload.push({
        "displaySequence": index + 1,
        "isCorrectAnswer": values.answer === index.toString() ? true : false,
        "option": item.option
      })
    })
    editQuestionDetails.questQuestionaireOption.map((item) => {
      optionsPayload.map((data) => {
        if (item.displaySequence === data.displaySequence) {
          data['questQuestionaireOptionId'] = item.questQuestionaireOptionId;
        }
      })
    })
    let payload = {
      "quest": {
        questId: props.questId
      },
      "questQuestionaireOption": optionsPayload,
      "question": values.question,
      "questQuestionaireId": editQuestionDetails.questQuestionaireId,
      "questionType": "MCQ"
    }
    url = url + `?questQuestionaireDto=${encodeURIComponent(JSON.stringify(payload))}`
    HTTPService.put(url, payload).then((response) => {
      setLoader(false);
      if (response.status.success.toLowerCase() === 'success') {
        toast.success(response.status.message);
        resetForm();
        props.getQuestionList();
      }
    })
      .catch((error) => {
        setLoader(false);
        toast.error(error.response.data.status.message);
      })
  }

  const checkBoxChange = (setFieldValue, answerIndex) => {
    setFieldValue('answer', answerIndex.toString())
  }

  const getButtonName = () => {
    if (editQuestionDetails === null) {
      return "Add Question"
    }
    else {
      return "Save Question"
    }
  }

  return (
    <div className="mcq-quest">
      {
        editLoader ? (
          <div className="loader-block">
            <Spinner animation="grow" variant="primary" />
          </div>
        ) : (
            <Formik
              initialValues={initialValues}
              validationSchema={Schema.mcqSchema}
              enableReinitialize={true}
              onSubmit={onSubmit}
            >
              {({
                handleChange,
                handleSubmit,
                setFieldValue,
                values,
                handleBlur,
                getFieldProps
              }) => {
                return (
                  <Form className="mcq-quest-form" onSubmit={handleSubmit} autoComplete="off">
                    <div className="question">
                      <Form.Group controlId="formQuestion">
                        <Form.Label className="quest-heading">Question</Form.Label>
                        <Form.Control name="question" type="text" disabled={props.questStatus !== 'Assign Quest'}
                           placeholder="Type a Question here" {...getFieldProps('question')} />
                        <ErrorMessage component="div" name="question" className="error-msg" />
                      </Form.Group>
                    </div>
                    <div className="answer">
                      <FieldArray
                        name="options"
                        render={arrayHelpers => {
                          let optionsList = values && values.options;
                          return (
                            <div>
                              {optionsList && optionsList.length > 0 ? (
                                optionsList.map((item, index) => (
                                  <>
                                    <InputGroup className="mb-3">
                                      <Form.Control
                                        type="text"
                                        disabled={props.questStatus !== 'Assign Quest'}
                                        name={`options.${index}.option`}
                                        placeholder="Type a Answer here"
                                        {...getFieldProps(`options.${index}.option`)}
                                      />
                                      <Form.Check
                                        inline
                                        className='mcq-checbox'
                                        value={`${index.toString()}`}
                                        disabled={(values && values.options[index].option === '') || props.questStatus !== 'Assign Quest'}
                                        checked={values && values.answer === `${index.toString()}`}
                                        name="answer"
                                        type="checkbox"
                                        onChange={() => checkBoxChange(setFieldValue, index)}
                                        onBlur={handleBlur}
                                      />
                                      <ErrorMessage component="span" name={`options.${index}.option`} className="error-msg" />
                                    </InputGroup>                                    
                                  </>
                                ))
                              ) : null}
                              <div className="d-grid">
                                {
                                  values && values.options.length < 5 ? (
                                    <>
                                      <Button variant="outline-primary" disabled={props.questStatus !== 'Assign Quest'} className="btn-dashed"
                                         onClick={() => arrayHelpers.push({ option: '' })}>
                                        <MdAdd /> Add
                                      </Button>
                                    </>
                                  ) : null
                                }
                                <ErrorMessage component="div" name={`answer`} className="error-msg" />
                              </div>
                            </div>
                          )
                        }}
                      />
                    </div>
                    <div className="text-end mt-5 pt-2">
                      <Button variant="primary" type='submit' disabled={loader || props.questStatus !== 'Assign Quest'}>
                        {!loader ? getButtonName() : 'Saving...'}
                      </Button>
                    </div>
                  </Form>
                )
              }}
            </Formik>
        )
      }
    </div>
  );
};

export default MultiChoiceQuestion;
