import React, { useEffect, useState } from 'react'
import { Button, Form, Col, Row, Spinner } from "react-bootstrap";
import { ErrorMessage, FieldArray, Formik } from "formik";
import { toast } from "react-toastify";
import { EndpointService } from "../../../service/endpointService";
import HTTPService from "../../../service/HTTPService";
import { Schema } from "./Schema";
import { MdAdd, MdOutlineArrowRightAlt } from 'react-icons/md';

const Matching = (props) => {

  const [initialValues, setIntitialValues] = useState();
  const [loader, setLoader] = useState(false);
  const [editLoader, setEditLoader] = useState(false);
  const [editQuestionDetails, setEditQuestionDetails] = useState(null);

  useEffect(() => {
    setIntitialValues({
      question: '',
      columnA: '',
      columnB: '',
      options: [
        {
          'columnA-option': '',
          'columnB-option': ''
        },
        {
          'columnA-option': '',
          'columnB-option': ''
        },
        {
          'columnA-option': '',
          'columnB-option': ''
        },
        {
          'columnA-option': '',
          'columnB-option': ''
        }
      ]
    });
  }, [])

  useEffect(() => {
    if (props.selectedQuestionDetail) { 
      getEditQuestionDetail()
    }
    else {
      setIntitialValues({
        question: '',
        columnA: '',
        columnB: '',
        options: [
          {
            'columnA-option': '',
            'columnB-option': ''
          },
          {
            'columnA-option': '',
            'columnB-option': ''
          },
          {
            'columnA-option': '',
            'columnB-option': ''
          },
          {
            'columnA-option': '',
            'columnB-option': ''
          }
        ]
      });
    }
  }, [props.selectedQuestionDetail])

  const getEditQuestionDetail = () => {
    setEditLoader(true);
    let url = EndpointService.getQuestQuestionUrl + props.selectedQuestionDetail.questQuestionaireId;
    HTTPService.get(url).then((response) => {
      setEditLoader(false);
      if (response.status.success.toLowerCase() === 'success') {
        let tempQuestionDetails = response.data;
        setEditQuestionDetails(tempQuestionDetails);
        if (tempQuestionDetails) {
          let tempEditValue = {
            question: tempQuestionDetails.question,
            columnA: tempQuestionDetails.matchingHeader1,
            columnB: tempQuestionDetails.matchingHeader2,
            options: []
          }
          let tempOptions = [];
          tempQuestionDetails.questQuestionaireOption.map((item) => {
            tempOptions.push({
              'columnA-option': item.option,
              'columnB-option': item.questQuestionaireOptionMatching.optionPair
            })
          });
          tempEditValue.options = tempOptions;
          setIntitialValues({ ...tempEditValue });
        }
      }
    })
      .catch((error) => {
        setEditLoader(false);
        toast.error(error.response.data.status.message);
      })
  }

  const onSubmit = (values, { resetForm }) => {
    if (editQuestionDetails === null) {
      saveQuestion(values, resetForm);
    }
    else {
      updateQuestion(values, resetForm);
    }
  }

  const saveQuestion = (values, resetForm ) => {
    setLoader(true);
    let url = EndpointService.saveQUestionaireUrl;
    let optionsPayload = [];
    values.options && values.options.map((item, index) => {
      optionsPayload.push({
        "option": values.options[index]['columnA-option'],
        "displaySequence": index + 1,
        "questQuestionaireOptionMatching": {
          "optionPair": values.options[index]['columnB-option']
        }
      })
    })
    let payload = {
      "quest": {
        questId: props.questId
      },
      "questQuestionaireOption": optionsPayload,
      "question": values.question,
      "matchingHeader1": values.columnA,
      "matchingHeader2": values.columnB,
      "questionType": "MFP"
    }
    url = url + `?questQuestionaireDto=${encodeURIComponent(JSON.stringify(payload))}`
    HTTPService.post(url, payload).then((response) => {
      setLoader(false);
      if (response.status.success.toLowerCase() === 'success') {
        toast.success(response.status.message);
        resetForm();
        props.getQuestionList();
      }
    })
      .catch((error) => {
        setLoader(false);
        toast.error(error.response.data.status.message);
      })
  }

  const updateQuestion = (values, resetForm) => {
    setLoader(true);
    let url = EndpointService.saveQUestionaireUrl;
    let optionsPayload = [];
    values.options && values.options.map((item, index) => {
      optionsPayload.push({
        "option": values.options[index]['columnA-option'],
        "displaySequence": index + 1,
        "questQuestionaireOptionMatching": {
          "optionPair": values.options[index]['columnB-option']
        }
      })
    })
    editQuestionDetails.questQuestionaireOption.map((item) => {
      optionsPayload.map((data) => {
        if (item.displaySequence === data.displaySequence) {
          data['questQuestionaireOptionId'] = item.questQuestionaireOptionId;
          data.questQuestionaireOptionMatching['questQuestionaireOptionMatchingId'] = item.questQuestionaireOptionMatching.questQuestionaireOptionMatchingId
        }
      })
    })
    let payload = {
      "quest": {
        questId: props.questId
      },
      "questQuestionaireOption": optionsPayload,
      "question": values.question,
      "matchingHeader1": values.columnA,
      "matchingHeader2": values.columnB,
      "questQuestionaireId": editQuestionDetails.questQuestionaireId,
      "questionType": "MFP"
    }
    url = url + `?questQuestionaireDto=${encodeURIComponent(JSON.stringify(payload))}`
    HTTPService.put(url).then((response) => {
      setLoader(false);
      if (response.status.success.toLowerCase() === 'success') {
        toast.success(response.status.message);
        resetForm();
        props.getQuestionList();
      }
    })
      .catch((error) => {
        setLoader(false);
        toast.error(error.response.data.status.message);
      })
  }

  const getButtonName = () => {
    if (editQuestionDetails === null) {
      return "Add Question"
    }
    else {
      return "Save Question"
    }
  }

  return (
    <div className="mcq-quest matching-quest">
      {
        editLoader ? (
          <div className="loader-block">
            <Spinner animation="grow" variant="primary" />
          </div>
        ) : (
            <Formik
              initialValues={initialValues}
              validationSchema={Schema.matchingSchema}
              enableReinitialize={true}
              onSubmit={onSubmit}
            >
              {({
                handleSubmit,
                values,
                getFieldProps
              }) => {
                return (
                  <Form className="mcq-quest-form matching-quest-form" onSubmit={handleSubmit} autoComplete="off">
                    <div className="question">
                      <Form.Group controlId="formQuestion">
                        <Form.Label className="quest-heading">Question</Form.Label>
                        <Form.Control name="question" type="text" placeholder="Type a Question here" 
                          disabled={props.questStatus !== 'Assign Quest'} {...getFieldProps('question')} />
                        <ErrorMessage component="div" name="question" className="error-msg" />
                      </Form.Group>
                    </div>
                    <div className="answer">
                      <Row className="column-name mb-4">
                        <Col lg={5}>
                          <Form.Group className="mb-3" controlId="formColumnA">
                            <Form.Control name="columnA" type="text" placeholder="Column A name" 
                              disabled={props.questStatus !== 'Assign Quest'} {...getFieldProps('columnA')} />
                            <ErrorMessage component="div" name="columnA" className="error-msg" />
                          </Form.Group>
                        </Col>
                        <Col lg={5}>
                          <Form.Group className="mb-3" controlId="formColumnA">
                            <Form.Control name="columnB" type="text" placeholder="Column B name" 
                              disabled={props.questStatus !== 'Assign Quest'} {...getFieldProps('columnB')} />
                            <ErrorMessage component="div" name="columnB" className="error-msg" />
                          </Form.Group>
                        </Col>
                      </Row>
                      <FieldArray
                        name="options"
                        render={arrayHelpers => {
                          let optionsList = values && values.options;
                          return (
                            <div>
                              {optionsList && optionsList.length > 0 ? (
                                optionsList.map((item, index) => (
                                  <>
                                    <Row className="choice-name">
                                      <Col lg={5}>
                                        <Form.Group className="mb-3">
                                          <Form.Control
                                            type="text"
                                            disabled={props.questStatus !== 'Assign Quest'}
                                            name={`options.${index}.['columnA-option']`}
                                            placeholder={`Enter choice ${index + 1}`}
                                            {...getFieldProps(`options.${index}.['columnA-option']`)}
                                          />
                                          <ErrorMessage component="div" name={`options.${index}.['columnA-option']`} className="error-msg" />
                                        </Form.Group>                                        
                                      </Col>
                                      <Col lg={2}>
                                        <span className="icon">
                                          <MdOutlineArrowRightAlt className="icon-arrow" />
                                        </span>
                                      </Col>
                                      <Col lg={5}>
                                        <Form.Group className="mb-3">
                                          <Form.Control
                                            type="text"
                                            disabled={props.questStatus !== 'Assign Quest'}
                                            name={`options.${index}.['columnB-option']`}
                                            placeholder={`Enter choice ${index + 1} pair`}
                                            {...getFieldProps(`options.${index}.['columnB-option']`)}
                                          />
                                          <ErrorMessage component="div" name={`options.${index}.['columnB-option']`} className="error-msg" />
                                        </Form.Group>                                        
                                      </Col>
                                    </Row>
                                  </>
                                ))
                              ) : null}
                              <div className="d-grid">
                                {
                                  values && values.options.length < 5 ? (
                                    <div className="d-grid mt-3">
                                      <Button variant="outline-primary"
                                        className="btn-dashed"
                                        disabled={props.questStatus !== 'Assign Quest'}
                                        onClick={() => arrayHelpers.push({ 'columnA-option': '', 'columnB-option': '' })}
                                      >
                                        <MdAdd /> Add a matching pair
                                      </Button>
                                    </div>
                                  ) : null
                                }
                              </div>
                            </div>
                          )
                        }}
                      />
                    </div>
                    <div className="text-end mt-5 pt-2">
                      <Button variant="primary" type='submit' disabled={loader || props.questStatus !== 'Assign Quest'}>
                        {!loader ? getButtonName() : 'Saving...'}
                      </Button>
                    </div>
                  </Form>
                )
              }}
            </Formik>
        )
      }
    </div>
  );
};

export default Matching;
