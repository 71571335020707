import React, { useState, useEffect } from "react";
import Header from "../../common/Header";
import MyQuests from "./MyQuests";
import ActivityFeed from "./ActivityFeed";
import MyStudents from "./MyStudents";
import Leaderboard from "./Leaderboard";
import MyReports from "./MyReports";
import { cypherService } from "../../../service/cypherService";
import { EndpointService } from "../../../service/endpointService";
import HTTPService from "../../../service/HTTPService";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import moment from 'moment';

const Dashboard = () => {

  const [filterList, setFilterList] = useState([]);
  const [loader, setLoader] = useState({
    'dashboard': false,
    'activityFeedback': false,
    'leaderboard': false,
    'report': false,
    'student': false,
    'quest': false,
  });
  const [activityFeedbackList, setActivityFeedbackList] = useState(null);
  const [leaderboardList, setLeaderboardList] = useState(null);
  const [questList, setQuestList] = useState(null);
  const [reportList, setReportList] = useState(null);
  const [studentList, setStudentList] = useState(null);
  const [questCount, setQuestCount] = useState(0);
  const [studentCount, setStudentCount] = useState(0);
  const [reportCount, setReportCount] = useState(0);
  const [filterParams, setFilterParams] = useState({
    'activityFeedback': null,
    'leaderboard': null,
    'report': null,
    'student': null,
    'quest': null,
    'questStatus': null
  })

  useEffect(() => {
    cypherService.setSessionStorage('selectedQuestListFilterGrade', null);
    cypherService.setSessionStorage('selectedQuestListFilterGradeStatus', null);
    getAllGrades();
  }, [])

  const getDashboardDetails = (tempFilterParams, type) => {
    setFilterParams({ ...tempFilterParams });
    let tempLoader = loader;
    tempLoader[type] = true;
    setLoader({ ...tempLoader });
    let userId = cypherService.getSessionStorage('userId');
    let url = EndpointService.getDashboardDetailsUrl + userId;
    url = url + `?activityFeed-gradeId=${tempFilterParams.activityFeedback}&leaderboard-gradeId=${tempFilterParams.leaderboard}`;
    url = url + `&quest-gradeId=${tempFilterParams.quest}&quest-status=${tempFilterParams.questStatus}`;
    url = url + `&report-gradeId=${tempFilterParams.report}&student-gradeId=${tempFilterParams.student}`;
    HTTPService.get(url).then((response) => {
      tempLoader[type] = false;
      setLoader({ ...tempLoader });
      setDashboardDetails(response);
      cypherService.setSessionStorage('dashboardDetails', JSON.stringify(response));
    })
      .catch((error) => {
        tempLoader[type] = false;
        setLoader({ ...tempLoader });
        toast.error(error.response.data.status.message);
      })
  }

  const setDashboardDetails = (response) => {
    if (response.status.success.toLowerCase() === 'success') {
      if (response.data.activityFeed !== null) {
        let tempActivityList = response.data.activityFeed;
        let activityFeedBackKeys = Object.keys(tempActivityList);
        let activityFeedBackValues = Object.values(tempActivityList);
        let tempFeedBack = {};
        activityFeedBackKeys && activityFeedBackKeys.length > 0 && activityFeedBackKeys.map((dateKey, index) => {
          if (moment(dateKey).format('DD-MM-YYYY') === moment().format('DD-MM-YYYY')) {
            tempFeedBack['Today'] = activityFeedBackValues[index];
          }
          else if (moment(dateKey).format('DD-MM-YYYY') === moment().subtract(1, 'days').format('DD-MM-YYYY')) {
            tempFeedBack['Yesterday'] = activityFeedBackValues[index];
          }
          else {
            tempFeedBack[moment(dateKey).format('DD-MM-YYYY')] = activityFeedBackValues[index];
          }
        })
        setActivityFeedbackList({ ...tempFeedBack })
      }
      else {
        setActivityFeedbackList({});
      }
      setQuestCount(response.data.questDetailsCount);
      setStudentCount(response.data.studentCount);
      setReportCount(response.data.userReportCount);
      setLeaderboardList([...(response.data.leaderBoard ? response.data.leaderBoard : [])]);
      setQuestList([...(response.data.myQuest ? response.data.myQuest : [])]);
      setStudentList([...(response.data.myStudents ? response.data.myStudents : [])]);
      let tempReportList = [];
      response.data.userReport && response.data.userReport.map((item) => {
        tempReportList.push({
          ...item,
          'userReportStudentCount': response.data.userReportStudentCount
        })
      })
      setReportList([...tempReportList]);
    }
  }

  const getAllGrades = () => {
    const apiUrl = EndpointService.getAllGrade;
    let tempFilterList = [];
    HTTPService.get(apiUrl, null)
      .then((response) => {
        Object.entries(response.data).map((item) => {
          tempFilterList.push({
            gradeId: item[0],
            gradeName: item[1]
          })
        })
        setFilterList([...tempFilterList]);
        setGradeFilterList(tempFilterList);
      })
      .catch((error) => {
        setGradeFilterList(tempFilterList);
        throw error;
      });
  }

  const setGradeFilterList = (temp_FilterList) => {
    let tempFilterList = temp_FilterList;
    let tempGradeFilterList = [];
    let tempGradeIdList = [];
    let tempFilterParams = filterParams;
    tempFilterList && tempFilterList.map((item, index) => {
      if (!tempGradeIdList.includes(item.gradeId)) {
        tempGradeFilterList.push(item);
        tempGradeIdList.push(item.gradeId)
      }
      if (index === 0) {
        tempFilterParams.activityFeedback = cypherService.getSessionStorage('selectedActivityFeedFilterGrade') ? cypherService.getSessionStorage('selectedActivityFeedFilterGrade') : item.gradeId;
        tempFilterParams.leaderboard = cypherService.getSessionStorage('selectedLeaderBoardFilterGrade') ? cypherService.getSessionStorage('selectedLeaderBoardFilterGrade') : item.gradeId;
        tempFilterParams.student = cypherService.getSessionStorage('selectedStudentFilterGrade') ? cypherService.getSessionStorage('selectedStudentFilterGrade') : item.gradeId;
        tempFilterParams.report = cypherService.getSessionStorage('selectedReportFilterGrade') ? cypherService.getSessionStorage('selectedReportFilterGrade') : item.gradeId;
        tempFilterParams.quest = cypherService.getSessionStorage('selectedFilterGrade') ? cypherService.getSessionStorage('selectedFilterGrade') : item.gradeId;
        tempFilterParams.questStatus = cypherService.getSessionStorage('selectedFilterGradeStatus') ? cypherService.getSessionStorage('selectedFilterGradeStatus') : 'All';
        cypherService.setSessionStorage('selectedFilterGrade', tempFilterParams.quest);
        cypherService.setSessionStorage('selectedStudentFilterGrade', tempFilterParams.student);
        cypherService.setSessionStorage('selectedReportFilterGrade', tempFilterParams.report);
        cypherService.setSessionStorage('selectedLeaderBoardFilterGrade', tempFilterParams.leaderboard);
        cypherService.setSessionStorage('selectedActivityFeedFilterGrade', tempFilterParams.activityFeedback);
        cypherService.setSessionStorage('selectedFilterGradeStatus', tempFilterParams.questStatus);
      }
    })
    setFilterList([...tempGradeFilterList]);
    getDashboardDetails(tempFilterParams, 'dashboard');
  }

  return (
    <>
      <section className="main-container">
        <Header />
        <section className="dashboard-container">
        {
          loader && loader.dashboard ? (
            <div className="dashboard-loader"><Spinner animation="grow" variant="primary" /></div>
          ) : (
            <>
              <MyQuests filterList={filterList} loader={loader} setLoader={setLoader} filterParams={filterParams} questList={questList} getDashboardDetails={getDashboardDetails} questCount={questCount}/>
              <Leaderboard filterList={filterList} loader={loader} filterParams={filterParams} leaderboardList={leaderboardList} getDashboardDetails={getDashboardDetails} />
              <ActivityFeed filterList={filterList} loader={loader} filterParams={filterParams} activityFeedbackList={activityFeedbackList} getDashboardDetails={getDashboardDetails} />
              <MyStudents filterList={filterList} loader={loader} filterParams={filterParams} studentList={studentList} getDashboardDetails={getDashboardDetails} studentCount={studentCount}/>
              <MyReports filterList={filterList} loader={loader} filterParams={filterParams} reportList={reportList} getDashboardDetails={getDashboardDetails} reportCount={reportCount}/>
            </>
          )
        }
        </section>
      </section>
    </>
  );
};

export default Dashboard;
