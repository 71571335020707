import React from "react";
import { Button, Modal } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from "react-router";

const LogoutModal = (props) => {

  const navigate = useNavigate()
  const logout = () => {
    navigate('/')
    sessionStorage.clear();
    localStorage.clear();
  }

  return (
    <Modal {...props} size="md" centered className="logout-modal">
      <Modal.Header>
        <Modal.Title>Log out</Modal.Title>
      </Modal.Header>
      <Modal.Body className="show-grid">
        <p>Are you sure you want to log out?</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-primary" onClick={props.onHide}>
          Cancel
        </Button>
        <Button onClick={()=>{
          logout()
        }}>Log out</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default LogoutModal;
