import React, { useEffect, useState } from "react";
import {
  Breadcrumb,
  Card,
  Form,
  InputGroup,
  ProgressBar,
  Spinner,
} from "react-bootstrap";
import Header from "../../common/Header";
import {
  MdHome,
  MdOpenInFull,
  MdSearch
} from "react-icons/md";
import { useNavigate } from "react-router";
import TableData from "./Table";
import HTTPService from "../../../service/HTTPService";
import { EndpointService } from "../../../service/endpointService";
import { cypherService } from "../../../service/cypherService";
import { debounce } from "lodash";
import moment from "moment";

const ParentMyQuestList = () => {

  const [loader, setLoader] = useState(false);
  const [tableItem, setTableItem] = useState([]);
  const [parentSearchTxtBoxValue, setParentSearchTxtBoxValue] = useState("");
  const [start, setStart] = useState(0);
  const [limit, setLimit] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const gradeId = cypherService.getSessionStorage('studentGradeId');
  const studentId = cypherService.getSessionStorage("studentId");

  const navigate = useNavigate();

  useEffect(() => {
    let tempQuestCount = cypherService.getSessionStorage('expandParentQuestCount') ? cypherService.getSessionStorage('expandParentQuestCount') : null;
    let tempQuestList = cypherService.getSessionStorage('expandParentQuestList') ? JSON.parse(cypherService.getSessionStorage('expandParentQuestList')) : null;
    if (tempQuestCount && tempQuestList) {
      let tempDataItems = [];
      tempQuestList.length > 0 && tempQuestList.map((item) => {
        tempDataItems.push({
          ...item,
          scorePercent: item.scorePercentage
        })
      })
      setTableItem([...tempDataItems]);
      setTotalCount(tempQuestCount);
    }
    else {
      getTableItem("", 0, 10);
    }
  }, []);

  const getTableItem = (searchText, tempStart, tempLimit) => {
    setLoader(true);
    let apiUrl = EndpointService.getParentQuestListUrl + `${studentId}/range?gradeId=${gradeId}&start=${tempStart}&limit=${tempLimit}`;
    if (searchText !== '') {
      apiUrl = apiUrl + `&search=${searchText}`;
    }
    HTTPService.get(apiUrl, null)
      .then((response) => {
        const items = response.data;
        setLoader(false);
        let tempDataItems = [];
        if (response.status.success.toLowerCase() == "success") {
          items.length > 0 && items.map((item) => {
            tempDataItems.push({
              ...item,
              scorePercent: item.scorePercentage
            })
          })
          setTotalCount(response.totalResults);
        }
        setTableItem([...tempDataItems]);
      })
      .catch((error) => {
        throw error;
      });
  };

  const expandCard = (e) => {
    let elem = e.target.parentElement.closest(".card");
    if (elem.classList.contains("expand")) {
      elem.classList.remove("expand");
    } else {
      elem.classList.add("expand");
    }
    setTimeout(() => {
      navigate("/parent/dashboard");
    }, 200);
  };

  const getProgressBar = (value) => {
    if (Number(value) < 40) {
      return (<ProgressBar variant="danger" now={Number(value)} />)
    }
    else if (Number(value) >= 40 && Number(value) < 80) {
      return (<ProgressBar variant="warning" now={Number(value)} />)
    }
    else {
      return (<ProgressBar variant="success" now={Number(value)} />)
    }
  }

  const columns = [
    {
      Header: "Quest Name",
      accessor: "questName"
    },
    {
      Header: "Score",
      accessor: "scorePercent",
    },
    {
      Header: "Assigned",
      accessor: "assignedDate",
      Cell: (row) => {
        return (moment(row.cell.value).format("DD/MM/YYYY"))

      }
    },
    {
      Header: "Deadline",
      accessor: "deadLine",
      Cell: (row) => {
        return (moment(row.cell.value).format("DD/MM/YYYY"))

      }
    },
    {
      Header: "Duration (mins)",
      accessor: "duration",
    },
    {
      Header: "Progress",
      accessor: "scorePercentage",
      Cell: (row) => (getProgressBar(row.cell.value)),
    },
  ];

  const onSearch = debounce((event) => {
    setParentSearchTxtBoxValue(event.target.value);
    getTableItem(event.target.value, start, limit);
  }, 1000);

  const data = tableItem;

  return (
    <>
      <Header />
      <section className="main-container">
        <div className="dashboard-container parent-dashboard-container">
        {loader ? <div className="loader"><Spinner animation="grow" variant="primary" /></div> : null}
          <Card className="my-quests my-quests-list expand">          
            <div className="list-header">
              <Breadcrumb className="m-0">
                <Breadcrumb.Item onClick={() => navigate('/parent/dashboard')}>
                  <MdHome /> Dashboard
                </Breadcrumb.Item>
                <Breadcrumb.Item active>My Quest</Breadcrumb.Item>
              </Breadcrumb>
              <div className="search">
                <InputGroup>
                  <Form.Control
                    placeholder="Search"
                    onChange={(e) => onSearch(e)}
                  />
                  <InputGroup.Text>
                    <MdSearch />
                  </InputGroup.Text>
                </InputGroup>
              </div>
            </div>
            <Card.Title className="p-0">
              <div
                className="expand"
                onClick={(e) => {
                  expandCard(e);
                }}
              >
                <MdOpenInFull />
              </div>
            </Card.Title>
            <Card.Body className="card-body">
              <TableData
                columns={columns}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                start={start}
                setStart={setStart}
                limit={limit}
                setLimit={setLimit}
                getTableItem={getTableItem}
                totalCount={totalCount}
                data={data}
                parentSearchTxtBoxValue={parentSearchTxtBoxValue}
              />
            </Card.Body>
          </Card>
        </div>
      </section>
    </>
  );
};

export default ParentMyQuestList;
