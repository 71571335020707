import * as Yup from "yup";

let atleastOneSpecialCharRegex = new RegExp(
  "^(?=.*[a-z])(?=.*[!@#$%^&*])(?=.{8,})"
);
let bussinessEmail = new RegExp(
  "^[a-zA-Z0-9._%+-]+@(?!gmail.com)(?!yahoo.com)(?!hotmail.com)(?!yahoo.co.in)(?!aol.com)(?!live.com)(?!outlook.com)[a-zA-Z0-9_-]+.[a-zA-Z0-9-.]{2,61}$"
);
// Login
const validationSchema = Yup.object({
  reportName: Yup.string().required("Report name is required").nullable(),
  questName: Yup.string().required("Quest name is required").nullable(),
});
const initialValues = {
  reportName: "",
  questName: "",
};

export const Schema = {
  validationSchema,
  initialValues,
};
