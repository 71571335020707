import * as Yup from "yup";
let atleastOneSpecialCharRegex = new RegExp("^(?=.*[a-z])(?=.*[!@#\$%\^&\*])(?=.{8,})");
let bussinessEmail = /^[a-zA-Z0-9._%+-]+@(?!gmail.com)(?!yahoo.com)(?!hotmail.com)(?!yahoo.co.in)(?!aol.com)(?!live.com)(?!outlook.com)[a-zA-Z0-9_-]+.[a-zA-Z0-9-.]{2,61}$/;

// Login
const validationSchema = Yup.object({
  emailId: Yup.string()
    .required("Email address is required")
    .email("Invalid email address"),
  password: Yup.string().required("Password is required"),
});

const initialValues = {
  emailId: "",
  password: "",
};

// Forgot password
const forgotValidationSchema = Yup.object({
  emailId: Yup.string()
    .required("Email address is required")
    .email("Invalid email address"),
});

const forgotInitialValues = {
  emailId: "",
};

// Reset password
const resetPwdValidationSchema = Yup.object({
  password: Yup.string().required('Password is required')
        .nullable()
        .strict()
        .trim('Space not allowed')
        .min(8, 'Password should be min 8')
        .max(40, "Password should be max 40")
        .matches(atleastOneSpecialCharRegex, "Must contain atleast 1 special character"), 
    confirmPassword: Yup.string().required('Confirm password is required')
        .nullable()
        .strict()
        .trim('Space not allowed')
        .min(8, 'Password should be min 8')
        .max(40, "Password should be max 40")
        .oneOf([Yup.ref('password')], 'Passwords do not match')
});

const resetPwdInitialValues = {
  password: "",
  confirmPassword: "",
};

export const Schema = {
  validationSchema,
  initialValues,
  forgotInitialValues,
  forgotValidationSchema,
  resetPwdValidationSchema,
  resetPwdInitialValues
};
