import React, { useEffect, useState } from 'react'
import { Button, Form, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import { EndpointService } from "../../../service/endpointService";
import HTTPService from "../../../service/HTTPService";
import {  MdAddPhotoAlternate, MdHighlightOff } from 'react-icons/md';
import environment from '../../../environments/environment';

const ArrangeInOrderImage = (props) => {

  const [initialValues, setIntitialValues] = useState();
  const [loader, setLoader] = useState(false);
  const [submitFlag, setSubmitFlag] = useState(false);
  const [imageList, setImageList] = useState([null, null, null, null]);
  const [imageError, setImageError] = useState('');
  const [uploadImageError, setUploadImageError] = useState('');
  const [editLoader, setEditLoader] = useState(false);
  const [editQuestionDetails, setEditQuestionDetails] = useState(null);

  useEffect(() => {
    setIntitialValues({
      question: '',
      options: [null, null, null, null]
    });
  }, [])

  useEffect(() => {
    if (props.selectedQuestionDetail) {
      getEditQuestionDetail()
    }
    else {
      setIntitialValues({
        question: '',
        options: [null, null, null, null],
      });
    }
  }, [props.selectedQuestionDetail])

  const getEditQuestionDetail = () => {
    setEditLoader(true);
    let url = EndpointService.getQuestQuestionUrl + props.selectedQuestionDetail.questQuestionaireId;
    HTTPService.get(url).then((response) => {
      if (response.status.success.toLowerCase() === 'success') {
        setEditLoader(false);
        let tempQuestionDetails = response.data;
        tempQuestionDetails.questQuestionaireOption.sort(sortByDisplaySequence);
        setEditQuestionDetails(tempQuestionDetails);
        if (tempQuestionDetails) {
          let tempEditValue = {
            question: tempQuestionDetails.question,
            options: []
          }
          let tempOptionValues = [null, null, null, null];
          tempOptionValues.map((item, index) => {
            tempQuestionDetails.questQuestionaireOption.map((data) => {
              if (index + 1 === data.displaySequence) {
                tempOptionValues[index] = data.option ? environment.imageBaseUrl + data.option : null;
              }
            })
          })
          tempEditValue.options = tempOptionValues
          setImageList([...tempEditValue.options]);
          setIntitialValues({ ...tempEditValue });
        }
      }
    })
      .catch((error) => {
        toast.error(error.response.data.status.message);
      })
  }

  const sortByDisplaySequence = (valueOne, valueTwo) => {
    if (valueOne.displaySequence < valueTwo.displaySequence) {
      return -1;
    }
    if (valueOne.displaySequence > valueTwo.displaySequence) {
      return 1;
    }
    return 0;
  }

  const deleteEditImage = (index) => {
    let questionaireOptionId = editQuestionDetails.questQuestionaireOption[index].questQuestionaireOptionId;
    let url = EndpointService.deleteQuestionireOptionImageUrl + questionaireOptionId;
    HTTPService.delete(url).then((response) => {
      if (response.status.success.toLowerCase() === 'success') {
        toast.success(response.status.message);
        let tempInitialValue = initialValues;
        let tempImageList = imageList;
        tempInitialValue.options[index] = null;
        tempImageList[index] = null;
        setIntitialValues({ ...tempInitialValue });
        setImageList([...tempImageList]);
      }
    })
      .catch((error) => {
        toast.error(error.response.data.status.message);
      })
  }

  const checkError = () => {
    let tempHasError = false;
    if (initialValues.question === '') {
      tempHasError =true;
    }
    imageList.map((item) => {
      if (item === null) {
        tempHasError = true;
      }
    })
    return tempHasError;
  }

  const onSubmit = () => {
    setImageError('');
    let hasError = checkError();
    setSubmitFlag(true);
    if (!hasError) {
      if (editQuestionDetails === null) {
        saveQuestion();
      }
      else {
        updateQuestion();
      }
    }
    else {
      setUploadImageError('Please upload all the images');
    }
  }

  const saveQuestion = () => {
    setLoader(true);
    let url = EndpointService.saveQUestionaireUrl;
    let optionsPayload = [];
    let formData = new FormData();
    imageList && imageList.map((item, index) => {
      optionsPayload.push({
        "displaySequence": index + 1,
      })
      formData.append('optionImages', item);
    })
    let payload = {
      "quest": {
        questId: props.questId
      },
      "questQuestionaireOption": optionsPayload,
      "question": initialValues.question,
      "questionType": "AOI"
    };
    url = url + `?questQuestionaireDto=${encodeURIComponent(JSON.stringify(payload))}`;
    HTTPService.post(url, formData).then((response) => {
      setLoader(false);
      if (response.status.success.toLowerCase() === 'success') {
        toast.success(response.status.message);
        setIntitialValues({
          question: '',
          options: [null, null, null, null],
        });
        setImageList([null, null, null, null]);
        setSubmitFlag(false);
        props.getQuestionList();
      }
    })
      .catch((error) => {
        setLoader(false);
        toast.error(error.response.data.status.message);
      })
  }

  const updateQuestion = () => {
    setLoader(true);
    let url = EndpointService.saveQUestionaireUrl;
    let optionsPayload = [];
    let formData = new FormData();
    imageList && imageList.map((item, index) => {
      if (typeof item !== 'string') {
        optionsPayload.push({
          "displaySequence": index + 1,
        })
        formData.append('optionImages', item);
      }
    })
    let payload = {
      "quest": {
        questId: props.questId
      },
      "questQuestionaireOption": optionsPayload,
      "question": initialValues.question,
      "questQuestionaireId": editQuestionDetails.questQuestionaireId,
      "questionType": "AOI"
    };
    debugger
    url = url + `?questQuestionaireDto=${encodeURIComponent(JSON.stringify(payload))}`;
    HTTPService.put(url, formData).then((response) => {
      setLoader(false);
      if (response.status.success.toLowerCase() === 'success') {
        toast.success(response.status.message);
        setIntitialValues({
          question: '',
          options: [null, null, null, null],
        });
        setImageList([null, null, null, null]);
        setSubmitFlag(false);
        props.getQuestionList();
      }
    })
      .catch((error) => {
        setLoader(false);
        toast.error(error.response.data.status.message);
      })
  }

  const imageChange = (event, index) => {
    setUploadImageError('');
    let error = '';
    let message = false;
    if ((event.target.files[0].type != "image/png" && event.target.files[0].type !== 'image/jpg' && event.target.files[0].type !== 'image/jpeg')) {
      message = true;
      error = "Please upload a image in jpg, jpeg or png format";
      setImageError("Please upload a image in jpg, jpeg or png format")
    }
    let mb = (event.target.files[0].size / 1000000);
    let fixedMb = 5
    if ((parseFloat(mb)) > parseFloat(fixedMb)) {
      if (!message) {
        message = true;
        error = "Please upload an image with size less then 5MB";
        setImageError("Please upload an image with size less then 5MB")
      }
    }
    if (!message) {
      let selectedImage = event.target.files[0];
      let tempSelectedImagesPayload = initialValues;
      let tempImages = imageList;
      tempImages[index] = selectedImage;
      tempSelectedImagesPayload.options[index] = URL.createObjectURL(selectedImage);
      setIntitialValues({...tempSelectedImagesPayload})
      setImageList([...tempImages]);
      if (submitFlag) {
        let requiredError = '';
        tempImages.map((item) => {
          if (item === null) {
            requiredError = 'Please upload all the images'
          }
        })
        setUploadImageError(requiredError);
      }
    }
    setImageError(error);
  }
 
  const questionChange = (event) => {
    let tempValue = initialValues;
    tempValue.question = event.target.value;
    setIntitialValues({ ...tempValue });
  }

  const removeImage = (index) => {
    if (props.questStatus === 'Assign Quest') {
      if (typeof imageList[index] !== 'string') {
        let tempInitialValue = initialValues;
        let tempImageList = imageList;
        tempInitialValue.options[index] = null;
        tempImageList[index] = null;
        setIntitialValues({ ...tempInitialValue });
        setImageList([...tempImageList]);
      }
      else {
        deleteEditImage(index)
      }
    }
  }

  const getButtonName = () => {
    if (editQuestionDetails === null) {
      return "Add Question"
    }
    else {
      return "Save Question"
    }
  }

  return (
    <div className="mcq-quest arr-in-order-image">
      {
        editLoader ? (
          <div className="loader-block">
            <Spinner animation="grow" variant="primary" />
          </div>
        ) : (
            <Form className="mcq-quest-form" autoComplete='off'>
              <div className="question">
                <Form.Group controlId="formQuestion" className="form-group mb-4">
                  <Form.Label className="quest-heading">Question</Form.Label>
                  <Form.Control name="question" value={initialValues && initialValues.question} disabled={props.questStatus !== 'Assign Quest'}
                    type="text" placeholder="Type a Question here" onChange={(e) => questionChange(e)} />
                  <div className="error-msg">
                    {submitFlag && initialValues.question === '' ? 'Please enter the question' : ''}
                  </div>
                </Form.Group>
              </div>
              <div className="answer ps-0">
                {
                  initialValues && initialValues.options.length > 0 && initialValues.options.map((item, index) => {
                    if (item) {
                      return (
                        <div className="image-preview mb-3">
                          <img src={initialValues.options[index]} alt='Answer-Image' />
                          <span className="icon" onClick={() => removeImage(index)}><MdHighlightOff className="icon-upload" /></span>
                        </div>
                      )
                    }
                    else {
                      return (
                        <Form.Group controlId="formFile1" className="form-group mb-3">
                          <Form.Label>
                            <Form.Control type="file" accept='images' disabled={props.questStatus !== 'Assign Quest'}
                               onChange={(e) => imageChange(e, index)} />
                            <span className="icon"><MdAddPhotoAlternate className="icon-upload" /></span>
                            <span>Upload Image</span>
                          </Form.Label>
                        </Form.Group>
                      )
                    }
                  })
                }
              </div>
              <div className="error-msg">
                {submitFlag && (imageError || uploadImageError)}
              </div>
              <div className="text-end mt-5 pt-2">
                <Button variant="primary" onClick={() => onSubmit()} disabled={loader || props.questStatus !== 'Assign Quest'}>
                  {!loader ? getButtonName() : 'Saving...'}
                </Button>
              </div>
            </Form>
        )
      }
    </div>
  );
};

export default ArrangeInOrderImage;
