import React from "react";
import { Card } from "react-bootstrap";
import avatar from "../../../assets/images/dashboard/avatar.png";

const Profile = (props) => {

  const { profile } = props;

  const getName = (value) => {
    let tempName = value.firstName;
    if (value.lastName) {
      tempName = tempName + " " + value.lastName;
    }
    return tempName;
  }

  return (
    <Card className="my-students student-profile">
      {/* <Card.Title>
      </Card.Title> */}
      <Card.Body>
        <div className="personal-info">
          <div className="profile-pic">
            <img src={avatar} alt={`student-picture`} />
          </div>
          <h5 className="student-name">{profile && getName(profile.student)}</h5>
          <span className="grade">{profile && profile.student.grade.gradeName}</span>
        </div>
        <ul className="quest-info list-unstyled">
          <li className="list-item">
            <span>Total quests</span>
            <span>{profile && (profile.totalQuests ? profile.totalQuests : 0)}</span>
          </li>
          <li className="list-item">
            <span>Passed quests</span>
            <span>{profile && (profile.passedQuests ? profile.passedQuests : 0)}</span>
          </li>
          <li className="list-item">
            <span>Highest score</span>
            <span>{profile && (profile.highestScore ? profile.highestScore : 0)}</span>
          </li>
          <li className="list-item">
            <span>Last quest score</span>
            <span>{profile && (profile.lastQuestScore ? profile.lastQuestScore : 0)}</span>
          </li>
        </ul>
      </Card.Body>
    </Card>
  );
};

export default Profile;
