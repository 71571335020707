import React, { useState, useEffect } from "react";
import Header from "../../common/Header";
import MyQuests from "./MyQuests";
import LeastAttemptQuest from "./LeastAttemptQuest";
import { cypherService } from "../../../service/cypherService";
import { EndpointService } from "../../../service/endpointService";
import HTTPService from "../../../service/HTTPService";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import AllTeachers from "./AllTeachers";

const Dashboard = () => {

  const [filterList, setFilterList] = useState([]);
  const [loader, setLoader] = useState({
    'leastAttempt': false,
    'quest': false,
    'teacher': false,
    'dashboard': false
  });
  const [leastAttemptList, setLeastAttemptList] = useState(null);
  const [questList, setQuestList] = useState(null);
  const [teacherList, setTeacherList] = useState(null);
  const [questCount, setQuestCount] = useState(0);
  const [teachersCount, setTeachersCount] = useState(0);
  const [filterParams, setFilterParams] = useState({
    'leastAttempt': null,
    'quest': null,
  })

  useEffect(() => {
    getNewGrades();
  }, [])

  const getTeacherDetails = (teacherDetails) => {
    let tempTeacherDetails = [];
    teacherDetails && teacherDetails.map((item) => {
      let tempData = {};
      tempData['originalDetails'] = item;
      let name = item.firstName;
      if (item.lastName) {
        name = name + ' ' + item.lastName;
      }
      tempData['name'] = name;
      tempData['userId'] = item.userId;
      tempData['address'] = '';
      if (item.addressLine1) {
        tempData['address'] = item.addressLine1;
      }
      if (item.addressLine2 && tempData['address'] !== '') {
        tempData['address'] = tempData['address'] + ', ' + item.addressLine2;
      }
      else if (item.addressLine2 && tempData['address'] === ''){
        tempData['address'] = tempData['address'] + item.addressLine2;
      }
      if (tempData['address'] === '') {
        tempData['address'] = '-';
      }
      tempData['contactNo'] = item.contactNumber;
      tempData['email'] = item.emailId; 
      tempData['subjectLabel'] = 'View Subjects';
      tempData['gradeLabel'] = 'View Grades';
      let subjectList = [];
      let gradeList = [];
      item.userGradeSubjectMap && item.userGradeSubjectMap.map((data) => {
        if (!subjectList.includes(data.subject.subjectName)) {
          subjectList.push(data.subject.subjectName);
        }
        if (!gradeList.includes(data.grade.gradeName)) {
          gradeList.push(data.grade.gradeName);
        }
      })
      tempData['subject'] = subjectList.join(', ');
      tempData['grade'] = gradeList;
      tempTeacherDetails.push(tempData);
    })
    setTeacherList([...tempTeacherDetails]);
  }

  const getDashboardDetails = (tempFilterParams, type) => {
    setFilterParams({ ...tempFilterParams });
    let tempLoader = loader;
    tempLoader[type] = true;
    setLoader({ ...tempLoader });
    let userId = cypherService.getSessionStorage('userId');
    let url = EndpointService.getSchoolDashboardUrl + userId;
    url = url + `?least-attempted-quest-grade-id=${tempFilterParams.leastAttempt}&quest-gradeId=${tempFilterParams.quest}`;
    HTTPService.get(url).then((response) => {
      tempLoader[type] = false;
      setLoader({ ...tempLoader });
      if (response.status.success.toLowerCase() === 'success') {
        cypherService.setSessionStorage('dashboardDetails', JSON.stringify(response));
        if (response.data.teachersList !== null) {
          getTeacherDetails(response.data.teachersList)
        }
        setQuestCount(response.data.questCount);
        setTeachersCount(response.data.teachersCount);
        setQuestList([...(response.data.allQuest ? response.data.allQuest : [])]);
        setLeastAttemptList([...response.data.leastAttemptedQuestList]);
      }
    })
      .catch((error) => {
        tempLoader[type] = false;
        setLoader({ ...tempLoader });
        toast.error(error.response.data.status.message);
      })
  }

  const getValue = (dataOne, dataTwo) => {
    if (dataOne) {
      return dataOne;
    }
    else {
      return dataTwo;
    }
  }

  const getNewGrades = () => {
    const apiUrl = EndpointService.getAllGrade;
    HTTPService.get(apiUrl, null)
      .then((response) => {
        let tempFilterList = [];
        Object.entries(response.data).map((item) => {
          tempFilterList.push({
            gradeName: item[1],
            gradeId: item[0]
          })
        })
        cypherService.setSessionStorage('allGradeList', JSON.stringify(tempFilterList));
        setFilterList([...tempFilterList]);
        let tempFilterParams = filterParams;
        tempFilterList && tempFilterList.map((item, index) => {
          if (index === 0) {
            tempFilterParams.leastAttempt = getValue(cypherService.getSessionStorage('selectedAttemptGrade'), item.gradeId);
            tempFilterParams.quest = getValue(cypherService.getSessionStorage('selectedQuestGrade'), item.gradeId);
            cypherService.setSessionStorage('selectedQuestGrade', tempFilterParams.quest);
            cypherService.setSessionStorage('selectedAttemptGrade', tempFilterParams.leastAttempt);
          }
        })
        setFilterParams({ ...tempFilterParams });
        getDashboardDetails(tempFilterParams, 'dashboard');
      })
      .catch((error) => {
        throw error;
      });
  }

  return (
    <>
      <section className="main-container">
        <Header />
        <section className="dashboard-container school-dashboard-container">
        {
          loader && loader.dashboard ? (
            <div className="dashboard-loader"><Spinner animation="grow" variant="primary" /></div>
          ) : (
            <>                
              <LeastAttemptQuest filterList={filterList} loader={loader} filterParams={filterParams} leastAttemptList={leastAttemptList} getDashboardDetails={getDashboardDetails} />
              <AllTeachers filterList={filterList} setLoader={setLoader} loader={loader} setFilterParams={setFilterParams}
                filterParams={filterParams} teachersCount={teachersCount} teacherList={teacherList} getDashboardDetails={getDashboardDetails} 
              />  
              <MyQuests filterList={filterList} loader={loader} filterParams={filterParams} questCount={questCount} questList={questList} getDashboardDetails={getDashboardDetails}/>                 
                            
            </>
          )
        }
        </section>
      </section>
    </>
  );
};

export default Dashboard;
