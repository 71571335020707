import React, { useEffect, useState } from "react";
import {
  Breadcrumb,
  Card,
  Dropdown,
  DropdownButton,
  Form,
  InputGroup,
  Spinner,
} from "react-bootstrap";
import Header from "../../common/Header";
import {
  MdHome,
  MdOpenInFull,
  MdSearch,
} from "react-icons/md";
import { useNavigate } from "react-router";
import { cypherService } from "../../../service/cypherService";
import TableData from "./Table";
import HTTPService from "../../../service/HTTPService";
import { EndpointService } from "../../../service/endpointService";
import { debounce } from "lodash";

const SchoolMyQuestList = () => {
  const [loader, setLoader] = useState(false);
  const [tableItem, setTableItem] = useState([]);
  const [parentSearchTxtBoxValue, setParentSearchTxtBoxValue] = useState("");
  const [filterList, setFilterList] = useState([]);
  const [start, setStart] = useState(0);
  const [limit, setLimit] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [filterParams, setFilterParams] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    setGradeFilterList();
  }, []);

  const getTableItem = (tempFilterParams, searchText, tempStart, tempLimit) => {
    setLoader(true);
    let apiUrl = EndpointService.questList + `${tempFilterParams}/range?quest-status=All&start=${tempStart}&limit=${tempLimit}`;
    if (searchText !== '') {
      apiUrl = apiUrl + `&search=${searchText}`;
    }
    HTTPService.get(apiUrl, null)
      .then((response) => {
        const items = response.data;
        setTableItem(items);
        if (response.status.success == "Success") {
          setLoader(false);
          setTotalCount(response.totalResults);
        }
        if (response.status.success == "Fail") {
          setLoader(false);
        }
      })
      .catch((error) => {
        throw error;
      });
  };

  const expandCard = (e) => {
    let elem = e.target.parentElement.closest(".card");
    if (elem.classList.contains("expand")) {
      elem.classList.remove("expand");
    } else {
      elem.classList.add("expand");
    }
    setTimeout(() => {
      navigate("/school/dashboard");
    }, 200);
  };

  const setGradeFilterList = () => {
    let gradeList = cypherService.getSessionStorage('allGradeList') ? JSON.parse(cypherService.getSessionStorage('allGradeList')) : null;
    if (gradeList && gradeList != 'null') {
      setFilterList([...gradeList]);
      let tempSelectedGrade = cypherService.getSessionStorage('selectedQuestGrade');
      setFilterParams(tempSelectedGrade);
      let tempQuestCount = cypherService.getSessionStorage('expandSchoolQuestCount') ? cypherService.getSessionStorage('expandSchoolQuestCount') : null;
      let tempQuestList = cypherService.getSessionStorage('expandSchoolQuestList') ? JSON.parse(cypherService.getSessionStorage('expandSchoolQuestList')) : null;
      if ((tempQuestCount && tempQuestList)) {
        setTableItem(tempQuestList);
        setTotalCount(tempQuestCount);
      }
      else {
        getTableItem(tempSelectedGrade, parentSearchTxtBoxValue, start, limit);
      }
    }
    else {
      const apiUrl = EndpointService.getAllGrade;
      HTTPService.get(apiUrl, null)
        .then((response) => {
          let tempFilterList = [];
          Object.entries(response.data).map((item) => {
            tempFilterList.push({
              gradeName: item[1],
              gradeId: item[0]
            })
          })
          setFilterList([...tempFilterList]);
          let tempSelectedGrade = cypherService.getSessionStorage('selectedQuestGrade');
          setFilterParams(tempSelectedGrade);
          let tempQuestCount = cypherService.getSessionStorage('expandSchoolQuestCount') ? cypherService.getSessionStorage('expandSchoolQuestCount') : null;
          let tempQuestList = cypherService.getSessionStorage('expandSchoolQuestList') ? JSON.parse(cypherService.getSessionStorage('expandSchoolQuestList')) : null;
          if ((tempQuestCount && tempQuestList)) {
            setTableItem(tempQuestList);
            setTotalCount(tempQuestCount);
          }
          else {
            getTableItem(tempSelectedGrade, parentSearchTxtBoxValue, start, limit);
          }
        })
        .catch((error) => {
          throw error;
        });
    }
  }

  const onSearch = debounce((event) => {
    let tempFilterParams = filterParams;
    setParentSearchTxtBoxValue(event.target.value);
    getTableItem(tempFilterParams, event.target.value, start, limit);
  }, 1000);

  const columns = [
    {
      Header: "Name of Quest",
      accessor: "questName"
    },
    {
      Header: "Grade",
      accessor: "gradeName",
    },
    {
      Header: "Status",
      accessor: "questStatus",
      Cell: (row) => {
        if (row.cell.value === 'Assign Quest') {
          return (
            <span className="quest-name color-blue-no-underline">{row.cell.value}</span>
          )
        }
        if (row.cell.value === 'Active') {
          return (
            <span className="color-green">{row.cell.value}</span>
          )
        }
        if (row.cell.value === 'Expired') {
          return (
            <span className="color-red">{row.cell.value}</span>
          )
        }
        else {
          return (<span className="quest-name">{row.cell.value}</span>)
        }
      }
    },
    {
      Header: "Students",
      accessor: "studentsCount",
    },
    {
      Header: "Asssigned",
      accessor: "assignedDate",
    },
    {
      Header: "Deadline",
      accessor: "deadLine",
    }
  ];

  const data = tableItem;

  const getFilterName = () => {
    return filterList && filterList.map((item) => {
      if (filterParams.toString() === item.gradeId.toString()) {
        return item.gradeName
      }
    })
  }

  const filterStatusChange = (value) => {
    let tempFilterParams = filterParams
    if (filterParams !== value.gradeId) {
      tempFilterParams = value.gradeId;
    }
    setFilterParams(tempFilterParams);
    getTableItem(tempFilterParams, parentSearchTxtBoxValue, start, limit)
  }

  return (
    <>
      <Header />
      <section className="main-container">
        <div className="dashboard-container school-dashboard-container">
        {loader ? <div className="loader"><Spinner animation="grow" variant="primary" /></div> : null}
          <Card className="my-quests my-quests-list expand">          
            <div className="list-header">
              <Breadcrumb className="m-0">
                <Breadcrumb.Item onClick={() => navigate('/school/dashboard')}>
                  <MdHome /> Dashboard
                </Breadcrumb.Item>
                <Breadcrumb.Item active>My Quest</Breadcrumb.Item>
              </Breadcrumb>
              <div className="filter-wrapper">
                <DropdownButton
                  id="dropdown-grade"
                  size="sm"
                  title={getFilterName()}
                  variant="light"
                  className="filter"
                >
                  {
                    filterList && filterList.map((item) => (
                      <Dropdown.Item onClick={() => filterStatusChange(item)}>
                        <Form.Check
                          type="radio"
                          aria-label="radio 1"
                          value={item.gradeId}
                          checked={item.gradeId.toString() === filterParams.toString()}
                        />
                        <span>{item.gradeName}</span>
                      </Dropdown.Item>
                    ))
                  }
                </DropdownButton>
              </div>
              <div className="search">
                <InputGroup>
                  <Form.Control
                    placeholder="Search"
                    onChange={(e) => onSearch(e)}
                  />
                  <InputGroup.Text>
                    <MdSearch />
                  </InputGroup.Text>
                </InputGroup>
              </div>
            </div>
            <Card.Title className="p-0">
              <div
                className="expand"
                onClick={(e) => {
                  expandCard(e);
                }}
              >
                <MdOpenInFull />
              </div>
            </Card.Title>
            <Card.Body className="card-body">
              <TableData
                columns={columns}
                filterParams={filterParams}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                start={start}
                setStart={setStart}
                limit={limit}
                setLimit={setLimit}
                getTableItem={getTableItem}
                totalCount={totalCount}
                data={data}
                parentSearchTxtBoxValue={parentSearchTxtBoxValue}
                setParentSearchTxtBoxValue={setParentSearchTxtBoxValue}
              />
            </Card.Body>
          </Card>
        </div>
      </section>
    </>
  );
};

export default SchoolMyQuestList;
