import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import Logo from '../../assets/images/cloudnow-logo-blue.png'
import Avatar from '../../assets/images/dashboard/avatar.png'
import LogoutModal from "./LogoutModal";

const Header = () => {
  const [modalShow, setModalShow] = useState(false);

  const logout = () => {
    setModalShow(true)
  }

  return (
    <>
      <header className="header">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-6">
              <a className="org-logo">
                <img
                  src={Logo}
                  alt="org-logo"
                  className="org-logo-img"
                />
              </a>
            </div>
            <div className="col-6">
              <Dropdown className="text-end">
                <Dropdown.Toggle variant="secondary" id="dropdown-basic" className="avatar">
                  <img
                    src={Avatar}
                    className="avatar-img"
                    alt="org-logo"
                  />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={logout}>Logout</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </div>
      </header>
      <LogoutModal show={modalShow} onHide={() => setModalShow(false)} />
    </>
  );
};

export default Header;
