import * as Yup from "yup";

const validationSchema = Yup.object({
  firstName: Yup.string().required("First name is required").nullable(),
  lastName: Yup.string().required("Last name is required").nullable(),
  emailId: Yup.string()
    .required("Email address is required")
    .email("Invalid email address")
    .nullable(),
  dateOfBirth: Yup.date().required("Date of birth is required").nullable(),
  gender: Yup.string().required("Gender is required").nullable(),
  contactNumber: Yup.string()
    .required("Mobile number is required")
    .matches(/^[0-9]+$/, "Please enter only numbers")
    .min(10, "Please enter 10 digit mobile number")
    .max(10, "Please enter 10 digit mobile number"),
  addressLine1: Yup.string().required("Address is required").nullable(),
  addressLine2: Yup.string().required("Address is required").nullable(),
  state: Yup.string().required("State is required").nullable(),
  pin: Yup.string()
    .required("Pincode is required")
    .matches(/^[0-9]+$/, "Please enter only numbers")
    .min(6, "Please enter 6 digit pincode number")
    .max(6, "Please enter 6 digit pincode number"),
  userId: Yup.string().nullable(),
});

const initialValues = {
  firstName: "",
  lastName: "",
  contactNumber: "",
  emailId: "",
  dateOfBirth: "",
  gender: "",
  addressLine1: "",
  addressLine2: "",
  state: "",
  pin: "",
  userId: "",
};

export const Schema = {
  validationSchema,
  initialValues,
};
