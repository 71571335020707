export class EndpointService {
  static loginUrl = "hw-elms-master/user/login";
  static forgotPwdUrl = "hw-elms-master/user/forgot-password";
  static setPwdUrl = "hw-elms-master/user/set-password";
  static getAllGrade = "hw-elms-master/grade/key-value";
  static getAllSubject = "hw-elms-master/subject/key-value";
  static createQuest = "hw-elms-master/quest/";
  static questList = "hw-elms-master/quest/grade/";
  static getQuestById = "hw-elms-master/quest/";
  static deleteQuest = "hw-elms-master/quest/";
  static createStudent = "hw-elms-master/student/";
  static studentList = "hw-elms-master/student/range";
  static getStudentById = "hw-elms-master/student/";
  static deleteStudent = "hw-elms-master/student/";
  static getQuestionListUrl = "hw-elms-master/questionaire/quest/";
  static getQuestQuestionUrl = 'hw-elms-master/questionaire/'
  static saveQUestionaireUrl = "hw-elms-master/questionaire/";
  static deleteQuestionireOptionImageUrl = "hw-elms-master/questionaire/questionaire-option/";
  static getAssignedQuestList = "hw-elms-master/game-quest-grade-character/";
  static getDashboardDetailsUrl = "hw-elms-master/teacher-dashboard/user/";
  static reportActionUrl = "hw-elms-master/userreport/";
  static getReportsListUrl = "hw-elms-master/userreport/user/";
  static getReportQuestListUrl = "hw-elms-master/userreport/quest";
  static getReportFieldsListUrl = "hw-elms-master/userreport/fields";
  static downloadUserReport = "hw-elms-master/userreport/download";
  static parentDashboardUrl = "hw-elms-master/parent-dashboard/";
  static getParentQuestListUrl = "hw-elms-master/quest/student/";
  static getSchoolDashboardUrl = "hw-elms-master/school-dashboard/user/";
  static getUserListUrl = "hw-elms-master/user/range";
  static saveUserUrl = "hw-elms-master/user/";
}
