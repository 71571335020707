import React, { useEffect, useState } from "react";
import {
  Breadcrumb,
  Button,
  Card,
  Dropdown,
  DropdownButton,
  Form,
  InputGroup,
  Spinner,
} from "react-bootstrap";
import Header from "../../common/Header";
import {
  MdDelete,
  MdFileCopy,
  MdHome,
  MdOpenInFull,
  MdOutlineMoreVert,
  MdSearch,
} from "react-icons/md";
import { useNavigate } from "react-router";
import MyStudentModal from "./MyStudentModal";
import DeleteModal from "./DeleteModal";
import { EndpointService } from "../../../service/endpointService";
import HTTPService from "../../../service/HTTPService";
import { Schema } from "./Schema";
import { cypherService } from "../../../service/cypherService";
import TableData from "./Table";
import { debounce } from "lodash";
import { toast } from "react-toastify";

const MyStudentList = () => {

  const [addStudentModal, setAddStudentModal] = useState(false);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [loader, setLoader] = useState(false);
  const [parentSearchTxtBoxValue, setParentSearchTxtBoxValue] = useState("");
  const [rowData, setRowData] = useState("");
  const [tableItem, setTableItem] = useState([]);
  const [studentUpdate, setStudentUpdate] = useState(false);
  const [editDateOfBirth, setEditDateOfBirth] = useState("");
  const [emailFromApi, setEmailFromApi] = useState("");
  const [initialValues, setInitialValues] = useState(Schema.initialValues);
  const [filterList, setFilterList] = useState([]);
  const [start, setStart] = useState(0);
  const [limit, setLimit] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [filterParams, setFilterParams] = useState(null);
  const [validationSchema] = useState(
    Schema.validationSchema
  );

  const navigate = useNavigate();

  useEffect(() => {
    cypherService.setSessionStorage('selectedGradeId', null);
    getAllGrades();
  }, []);

  const getAllGrades = () => {
    const apiUrl = EndpointService.getAllGrade;
    let tempFilterList = [];
    HTTPService.get(apiUrl, null)
      .then((response) => {
        Object.entries(response.data).map((item) => {
          tempFilterList.push({
            gradeId: item[0],
            gradeName: item[1]
          })
        })
        setFilterList([...tempFilterList]);
        setGradeFilterList(tempFilterList);
      })
      .catch((error) => {
        setGradeFilterList(tempFilterList);
        throw error;
      });
  }


  const setGradeFilterList = (temp_FilterList) => {
    let tempFilterList = temp_FilterList;
    let tempGradeFilterList = [];
    let tempGradeIdList = [];
    tempFilterList && tempFilterList.map((item, index) => {
      if (!tempGradeIdList.includes(item.gradeId)) {
        tempGradeFilterList.push(item);
        tempGradeIdList.push(item.gradeId)
      }
    })
    setFilterList([...tempGradeFilterList]);
    let tempSelectedGrade = cypherService.getSessionStorage('selectedStudentFilterGrade');
    setFilterParams(tempSelectedGrade);
    let tempStudentCount = cypherService.getSessionStorage('expandStudentCount') ? cypherService.getSessionStorage('expandStudentCount') : null;
    let tempStudentList = cypherService.getSessionStorage('expandStudentList') ? JSON.parse(cypherService.getSessionStorage('expandStudentList')) : null;
    if (tempStudentCount && tempStudentList) {
      setTableItem(tempStudentList);
      setTotalCount(tempStudentCount);
    }
    else {
      getTableItem(tempSelectedGrade, parentSearchTxtBoxValue, start, limit);
    }
  }

  const onSearch = debounce((event) => {
    let tempFilterParams = filterParams;
    setParentSearchTxtBoxValue(event.target.value);
    getTableItem(tempFilterParams, event.target.value, start, limit);
  }, 1000);

  // GetTableItem
  const getTableItem = (tempFilterParams, searchText, tempStart, tempLimit) => {
    setLoader(true);
    let apiUrl = EndpointService.studentList + `?gradeId=${tempFilterParams}&start=${tempStart}&limit=${tempLimit}`; 
    if (searchText !== '') {
      apiUrl = apiUrl + `&search=${searchText}`;
    }   
    HTTPService.get(apiUrl, null)
      .then((response) => {
        const items = response.data;
        setTableItem(items ? items : []);
        if (response.status.success == "Success") {
          setLoader(false);
          setTotalCount(response.totalResults);
        }
        if (response.status.success == "Fail") {
          setLoader(false);
        }
      })
      .catch((error) => {
        throw error;
      });
  };

  const filterStatusChange = (value) => {
    let tempFilterParams = filterParams;
    if (tempFilterParams !== value.gradeId) {
      tempFilterParams = value.gradeId;
    }
    setFilterParams(tempFilterParams )
    getTableItem(tempFilterParams, parentSearchTxtBoxValue, start, limit)
  }

  const expandCard = (e) => {
    let elem = e.target.parentElement.closest(".card");
    if (elem.classList.contains("expand")) {
      elem.classList.remove("expand");
    } else {
      elem.classList.add("expand");
    }
    setTimeout(() => {
      navigate("/teacher/dashboard");
    }, 200);
  };

  const columns = [
    {
      Header: "Student",
      accessor: "firstName",
    },    
    {
      Header: "Grade",
      accessor: "grade.gradeName",
    },
    {
      Header: "Gender",
      accessor: "gender",
    },
    {
      Header: "DOB",
      accessor: "dateOfBirth",
    },
    {
      Header: "Email",
      accessor: "emailId",
    },
    {
      Header: "Contact",
      accessor: "contactNumber",
    },
    {
      Header: " ",
      accessor: "action",
      Cell: (row) => (
        <DropdownButton
          align="end"
          title={<MdOutlineMoreVert />}
          id="dropdown-menu-align-end"
          variant="link"
          className="td-action"
        >
          <Dropdown.Item
            eventKey="1"
            onClick={() => {
              getStudentById(row);
            }}
          >
            <MdFileCopy /> Edit Details
          </Dropdown.Item>
          <Dropdown.Item
            className="trash-icon"
            eventKey="2"
            onClick={() => {
              deleteStudent(row);
            }}
          >
            <MdDelete /> Delete
          </Dropdown.Item>
        </DropdownButton>
      ),
    },
  ];

  const data = tableItem;

  const createStudent = () => {
    setAddStudentModal(true);
    setStudentUpdate(false);
    setEditDateOfBirth("");
    setEmailFromApi("");
    setInitialValues({
      ...initialValues,
      firstName: "",
      lastName: "",
      emailId: "",
      dateOfBirth: "",
      gender: "",
      contactNumber: "",
      grade: {
        gradeId: "",
      },
      studentId: ""
    });
  };

  const deleteStudent = (row) => {
    setRowData(row);
    setDeleteModalShow(true);
  };

  const getStudentById = (row) => {
    let studentId = row.row.original.studentId;
    let apiUrl = EndpointService.getStudentById + studentId;
    HTTPService.get(apiUrl, null)
      .then((response) => {
        const data = response.data;
        if (response.status.success == "Success") {
          setStudentUpdate(true);
          setEmailFromApi(data.emailId)
          setEditDateOfBirth(new Date(data.dateOfBirth));
          setInitialValues({
            ...initialValues,            
            firstName: data.firstName,
            lastName: data.lastName,
            emailId: data.emailId,
            dateOfBirth: data.dateOfBirth,
            gender: data.gender,
            contactNumber: data.contactNumber,
            grade: {
              gradeId: data.grade.gradeId,
            },
            studentId: data.studentId
          });
          setAddStudentModal(true);
        }

        if (response.status.success == "Fail") {
          toast.error(response.status.message);
        }
      })
      .catch((error) => {
        toast.error(error.response.data.status.message);
        throw error;
      });
  };

  const getFilterName = () => {
    return filterList && filterList.map((item) => {
      if (filterParams.toString() === item.gradeId.toString()) {
        return item.gradeName
      }
    })
  }

  return (
    <>
      <Header />
      <section className="main-container">
        <div className="dashboard-container">
        {loader ? <div className="loader"><Spinner animation="grow" variant="primary" /></div> : null}
          <Card className="my-students my-students-list expand">            
            <div className="list-header">
              <Breadcrumb className="m-0">
                <Breadcrumb.Item onClick={()=> navigate('/teacher/dashboard')}>
                  <MdHome /> Dashboard
                </Breadcrumb.Item>
                <Breadcrumb.Item active>My Students</Breadcrumb.Item>
              </Breadcrumb>
              <div className="filter-wrapper">
                <DropdownButton
                  id="dropdown-grade"
                  size="sm"
                  title={getFilterName()}
                  variant="light"
                  className="filter"
                >
                  {
                    filterList && filterList.map((item) => (
                      <Dropdown.Item onClick={() => filterStatusChange(item)}>
                        <Form.Check
                          type="radio"
                          aria-label="radio 1"
                          value={item.gradeId}
                          checked={item.gradeId.toString() === filterParams.toString()}
                        />
                        <span>{item.gradeName}</span>
                      </Dropdown.Item>
                    ))
                  }
                </DropdownButton>
              </div>
              <div className="search">
                <InputGroup>
                  <Form.Control
                    placeholder="Search"
                    aria-label="Search"
                    aria-describedby="search"
                    onChange={(e) => onSearch(e)}
                  />
                  <InputGroup.Text>
                    <MdSearch />
                  </InputGroup.Text>
                </InputGroup>
                <Button onClick={() => createStudent()}>Add Student</Button>
              </div>
            </div>
            <Card.Title className="p-0">
              <div
                className="expand"
                onClick={(e) => {
                  expandCard(e);
                }}
              >
                <MdOpenInFull />
              </div>
            </Card.Title>
            <Card.Body className="card-body">
              {
                data && data.length > 0 ? (
                  <TableData
                    columns={columns}
                    filterParams={filterParams}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    start={start}
                    setStart={setStart}
                    limit={limit}
                    setLimit={setLimit}
                    getTableItem={getTableItem}
                    totalCount={totalCount}
                    data={data}
                    parentSearchTxtBoxValue={parentSearchTxtBoxValue}
                  />
                ) : (
                  <div className="no-data">
                    No student found
                  </div>
                )
              }
            </Card.Body>
          </Card>
          <MyStudentModal
            show={addStudentModal}
            onHide={() => setAddStudentModal(false)}
            initialValues={initialValues}
            validationSchema={validationSchema}
            editDateOfBirth={editDateOfBirth}
            studentUpdate={studentUpdate}
            setFilterParams={setFilterParams}
            getTableItem={getTableItem}
            loader={loader}
            setLoader={setLoader}
            setStart={setStart}
            setLimit={setLimit}
            setParentSearchTxtBoxValue={setParentSearchTxtBoxValue}
            emailFromApi={emailFromApi}
          />
          <DeleteModal
            show={deleteModalShow}
            onHide={() => setDeleteModalShow(false)}
            rowData={rowData}
            loader={loader}
            setLoader={setLoader}
            getTableItem={getTableItem}
            setFilterParams={setFilterParams}
            setStart={setStart}
            setLimit={setLimit}
            setParentSearchTxtBoxValue={setParentSearchTxtBoxValue}
          />
        </div>
      </section>
    </>
  );
};

export default MyStudentList;
