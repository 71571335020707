import moment from "moment";
import React from "react";
import { Card, Dropdown, DropdownButton, Form, Spinner } from "react-bootstrap";
import avatar from "../../../assets/images/dashboard/avatar.png";
import { cypherService } from "../../../service/cypherService";

const ActivityFeed = (props) => {

  const { loader, filterParams, activityFeedbackList, filterList, getDashboardDetails } = props;

  const filterStatusChange = (value) => {
    let tempFilterParams = filterParams
    if (filterParams.activityFeedback !== value.gradeId) {
      tempFilterParams.activityFeedback = value.gradeId;
      cypherService.setSessionStorage('selectedActivityFeedFilterGrade', value.gradeId);
      getDashboardDetails(tempFilterParams, 'activityFeedback');
    }
  }

  const getFilterName = () => {
    return filterList && filterList.map((item) => {
      if (filterParams.activityFeedback == item.gradeId) {
        return item.gradeName
      }
    })
  }

  const getTableData = () => {
    if (activityFeedbackList && Object.keys(activityFeedbackList).length === 0) {
      return (
        <div className="no-data">
          No activity found
        </div>
      );
    }
    else {
      return (
        <ul className="activity-list">
        {
            activityFeedbackList && Object.keys(activityFeedbackList).map((key, index) => (
              <>
                <span className='feedback-list-header'>{key}</span>
                {
                  activityFeedbackList[key] && activityFeedbackList[key].map((item) => (
                    <li className="list-item">
                      <div className="profile-img">
                        <img src={avatar} alt="" className="img-fluid" />
                      </div>
                      <div className="notes">
                        <p>{item.message}</p>
                        <p>{moment(item.createdOn, 'DD-MM-YYYYTHH:mm:ss').format('LLL')}</p>
                      </div>
                    </li>
                  ))
                }
              </>
            ))
        }
        </ul>
      )
    }
  }
  
  return (
    <Card className="activity-feed">
      <Card.Title>
        <span>Activity Feed</span>
        {/* Filter */}
        <DropdownButton
            id="dropdown-grade"
            size="sm"
            title={getFilterName()}
            variant="light"
            className="filter"
            align="end"            
          >
            {
              filterList && filterList.map((item) => (
                <Dropdown.Item onClick={() => filterStatusChange(item)}>
                  <Form.Check
                    type="radio"
                    aria-label="radio 1"
                    value={item.gradeId}
                    checked={item.gradeId == filterParams.activityFeedback}
                  />
                  <span>{item.gradeName}</span>
                </Dropdown.Item>
              ))
            }                
          </DropdownButton>
      </Card.Title>
      <Card.Body>
          {
            loader.activityFeedback ? (
              <div className="loader"><Spinner animation="grow" variant="primary" /></div>
            ) : (
              getTableData()
            )
          }
      </Card.Body>
    </Card>
  );
};

export default ActivityFeed;
