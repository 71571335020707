import * as Yup from "yup";

let atleastOneSpecialCharRegex = new RegExp(
  "^(?=.*[a-z])(?=.*[!@#$%^&*])(?=.{8,})"
);
let bussinessEmail = new RegExp(
  "^[a-zA-Z0-9._%+-]+@(?!gmail.com)(?!yahoo.com)(?!hotmail.com)(?!yahoo.co.in)(?!aol.com)(?!live.com)(?!outlook.com)[a-zA-Z0-9_-]+.[a-zA-Z0-9-.]{2,61}$"
);

// Login
const validationSchema = Yup.object({
  firstName: Yup.string().required("First name is required").nullable(),
  lastName: Yup.string().required("Last name is required").nullable(),
  emailId: Yup.string()
    .required("Email address is required")
    .email("Invalid email address")
    .nullable(),
  dateOfBirth: Yup.date().required("Date of birth is required").nullable(),
  gender: Yup.string().required("Gender is required").nullable(),
  contactNumber: Yup.string()
    .required("Mobile number is required")
    .matches(/^[0-9]+$/, "Please enter only numbers")
    .min(10, "Please enter 10 digit mobile number")
    .max(10, "Please enter 10 digit mobile number"),
  grade: Yup.object({
    gradeId: Yup.string().required("Grade is required").nullable(),
  }).notRequired(),
  studentId: Yup.string()
});
const initialValues = {
  firstName: "",
  lastName: "",
  emailId: "",
  dateOfBirth: "",
  gender: "",
  contactNumber: "",
  grade: {
    gradeId: "",
  },
  studentId: ""
};

export const Schema = {
  validationSchema,
  initialValues,
};
