import React from "react";
import {
  Card,
  Dropdown,
  DropdownButton,
  Form,
  Spinner,
} from "react-bootstrap";
import { MdFactCheck, MdOpenInFull } from "react-icons/md";
import { useNavigate } from "react-router";
import { cypherService } from "../../../service/cypherService";

const MyReports = (props) => {

  const { loader, filterParams, reportList, filterList, getDashboardDetails } = props;
  const navigate = useNavigate();

  const filterStatusChange = (value) => {
    let tempFilterParams = filterParams
    if (filterParams.report !== value.gradeId) {
      tempFilterParams.report = value.gradeId;
      cypherService.setSessionStorage('selectedReportFilterGrade', value.gradeId);
      getDashboardDetails(tempFilterParams, 'report');
    }
  }

  const getTableData = () => {
    if (reportList && reportList.length === 0) {
      return (
        <div className="no-data">
          No reports found
        </div>
      );
    }
    else {
      return (
        <ul className="active-quests-list">
        {
            reportList && reportList.map((item, index) => (
              <li className={`list-item ${index === 0 ? "new-item" : ''}`} key={index}>
                <div className="quest-banner">
                  <MdFactCheck className="report-icon" />
                </div>
                <div>
                  <p className="quest-group-name">{item.reportName}</p>
                  <p className="students-count">{`${item.userReportStudentCount} Students`}</p>
                </div>
              </li>
            ))
        }
        </ul>
      )
    }
  }

  const getFilterName = () => {
    return filterList && filterList.map((item) => {
      if (filterParams.report == item.gradeId) {
        return item.gradeName
      }
    })
  }

  const expandCard = (e) => {
    let elem = e.target.parentElement.closest(".card");
    if (elem.classList.contains("expand")) {
      elem.classList.remove("expand");
    } else {
      elem.classList.add("expand");
    }
    setTimeout(() => {
      cypherService.setSessionStorage('expandReportCount', props.reportCount);
      cypherService.setSessionStorage('expandReportList', JSON.stringify(props.reportList));
      navigate("/teacher/my-report");
    }, 200);
  };

  return (
    <Card className="my-report">
      <Card.Title>
        <span>My Reports</span>
        <div
          className="expand"
          onClick={(e) => {
            expandCard(e);
          }}
        >
          <MdOpenInFull />
        </div>
        <DropdownButton
          id="dropdown-grade"
          size="sm"
          title={getFilterName()}
          variant="light"
          className="filter"
        >
          {
            filterList && filterList.map((item) => (
              <Dropdown.Item onClick={() => filterStatusChange(item)}>
                <Form.Check
                  type="radio"
                  aria-label="radio 1"
                  value={item.gradeId}
                  checked={item.gradeId == filterParams.report}
                />
                <span>{item.gradeName}</span>
              </Dropdown.Item>
            ))
          }    
        </DropdownButton>
      </Card.Title>
      <Card.Body>
        {
          loader.report ? (
            <div className="loader"><Spinner animation="grow" variant="primary" /></div>
          ) : (getTableData())
        }
      </Card.Body>
    </Card>
  );
};

export default MyReports;
