import { useState, useEffect } from 'react'
import { Navigate, Outlet } from 'react-router-dom';
import { cypherService } from '../service/cypherService';

function ProtectedRoute(props) {

    // Reference : https://www.robinwieruch.de/react-router-private-routes/
    const redirectPath = '/';
    const [user, setUser] = useState(cypherService.getSessionStorage("appToken"))

    if (!user) {
        return <Navigate to={redirectPath} replace />;
    }
    return <Outlet />
}
export default ProtectedRoute