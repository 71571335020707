import React from "react";
import { Card, Table } from "react-bootstrap";

const Leaderboard = (props) => {

  const { leaderboardList } = props;

  const getTableData = () => {
    if (leaderboardList && leaderboardList.length === 0) {
      return (
        <div className="no-data">
          No data found
        </div>
      );
    }
    else {
      return (
        <Table responsive className="homework-table dashboard-table">
          <thead>
            <tr>
              <th>#</th>
              <th>Student</th>
              <th>Quest</th>
              <th>Score (%)</th>
            </tr>
          </thead>
          <tbody>
            {
              leaderboardList && leaderboardList.map((item, index) => (
                <tr>
                  <td>{index + 1}</td>
                  <td>{item.student.firstName + (item.student.lastName ? (" " + item.student.lastName) : '')}</td>
                  <td>{item.quest.questName}</td>
                  <td>{item.scorePercentage}</td>
                </tr>
              ))
            }
          </tbody>
        </Table>
      )
    }
  }

  return (
    <Card className="leaderboard">
      <Card.Title>
        <span>Leaderboard</span>
      </Card.Title>
      <Card.Body>
        {getTableData()}
      </Card.Body>
    </Card>
  );
};

export default Leaderboard;
