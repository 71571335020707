import React from "react";
import {
  Card,
  Dropdown,
  DropdownButton,
  Form,
  Spinner,
} from "react-bootstrap";
import rank1 from "../../../assets/images/dashboard/rank_1_medal.svg";
import rank2 from "../../../assets/images/dashboard/rank_2_medal.svg";
import rank3 from "../../../assets/images/dashboard/rank_3_medal.svg";
import rank1Num from "../../../assets/images/dashboard/rank_1.svg";
import rank2Num from "../../../assets/images/dashboard/rank_2.svg";
import rank3Num from "../../../assets/images/dashboard/rank_3.svg";
import { cypherService } from "../../../service/cypherService";

const LeastAttemptQuest = (props) => {
  
  const {
    loader,
    filterParams,
    leastAttemptList,
    filterList,
    getDashboardDetails,
  } = props;

  const filterStatusChange = (value) => {
    let tempFilterParams = filterParams;
    if (filterParams.leastAttempt !== value.gradeId) {
      tempFilterParams.leastAttempt = value.gradeId;
      cypherService.setSessionStorage('selectedAttemptGrade', value.gradeId)
      getDashboardDetails(tempFilterParams, "leastAttempt");
    }
  };

  const getTableData = () => {
    if (leastAttemptList && leastAttemptList.length === 0) {
      return (
        <div className="no-data">No data found</div>
      )
    } 
    else {
      return (
        <div className="leaderboard-list">
          {
            leastAttemptList && leastAttemptList.map((item) => (
              <Card className="leaderboard-list-item">
                <Card.Body>
                  <div className="rank">
                    <div className="medal">
                      <img src={rank1} alt={`rank-image`} />
                    </div>
                    <div className="score">{item.studentCount}</div>
                  </div>
                  <div className="quest">
                    <h6 className="title">Quest Name</h6>
                    <p className="quest-name">{item.questName}</p>                    
                  </div>
                  <div className="grade">
                    <h6 className="title">Subject Name</h6>
                    <p className="grade-name">{item.subjectName}</p>
                  </div>
                </Card.Body>
                <div className="rank-with-number">
                  <img src={rank1Num} alt={`rank number image`} />
                </div>
              </Card>
            ))
          }
        </div>
      );
    }
  };

  const getFilterName = () => {
    return (
      filterList &&
      filterList.map((item) => {
        if (filterParams.leastAttempt === item.gradeId) {
          return item.gradeName;
        }
      })
    );
  };

  return (
    <Card className="leaderboard school-leaderboard">
      <Card.Title>
        <span>Least Attempted Quest</span>
        <DropdownButton
          id="dropdown-grade"
          size="sm"
          title={getFilterName()}
          variant="light"
          className="filter"
        >
          {filterList &&
            filterList.map((item) => (
              <Dropdown.Item onClick={() => filterStatusChange(item)}>
                <Form.Check
                  type="radio"
                  aria-label="radio 1"
                  value={item.gradeId}
                  checked={item.gradeId === filterParams.leastAttempt}
                />
                <span>{item.gradeName}</span>
              </Dropdown.Item>
            ))}
        </DropdownButton>
      </Card.Title>
      <Card.Body>
        {loader.leastAttempt ? (
          <div className="loader">
            <Spinner animation="grow" variant="primary" />
          </div>
        ) : (
          getTableData()
        )}
      </Card.Body>
    </Card>
  );
};

export default LeastAttemptQuest;
