import { ErrorMessage, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Logo from "../../assets/images/cloudnow-logo-blue.png";
import { EndpointService } from "../../service/endpointService";
import HTTPService from "../../service/HTTPService";
import { Schema } from "./Schema";
import { toast } from "react-toastify";
import { IoEyeOutline, IoEyeOffOutline } from "react-icons/io5";

const ResetPassword = () => {
  let navigate = useNavigate();

  const [loader, setLoader] = useState(false);
  const [secretKey, setSecretKey] = useState("");
  const [passwordType] = useState("password");
  const [loginFailMsg, setLoginFailMsg] = useState("");

  const showPassword = (e) => {
    let elem = e.target.parentElement.previousElementSibling;
    if (elem.type == "password") {
      elem.type = "text";
      return;
    }
    elem.type = "password";
    
  };

  useEffect(()=>{
    getSecurityKey();
    setLoginFailMsg("");
  },[])

  const getSecurityKey = ()=> {
    let queryString = window.location.search;
    let urlParams = new URLSearchParams(queryString);
    const secret = urlParams.get('secretkey');
    setSecretKey(secret);
  }

  const onSubmit = (values) => {
    setLoader(true);
    let payLoad = {
      ...values,
      apiKey: secretKey,
    };
    const apiUrl = EndpointService.setPwdUrl;
    HTTPService.post(apiUrl, payLoad)
      .then((response) => {
        if (response.status.success == "Success") {
          setLoader(false);
          toast.success(response.status.message);
          navigate("/");
        }
        if (response.status.success == "Fail") {
          setLoader(false);
          setLoginFailMsg(response.status.message)
        }
      })
      .catch((error) => {
        setLoader(false);
        setLoginFailMsg(error.response.data.status.message)
        throw error;
      });
  };

  return (
    <>
      <section className="login-container">
        <div className="logo">
          <img src={Logo} alt="logo" />
        </div>
        <div className="login-card">
          <header className="login-header">
            <h3>
              RESET PASSWORD
            </h3>
            <p>
              Enter a new password. Make sure it is not the same as the old
              password
            </p>
          </header>
          <Formik
            initialValues={Schema.resetPwdInitialValues}
            validationSchema={Schema.resetPwdValidationSchema}
            onSubmit={onSubmit}
            enableReinitialize={true}
          >
            {({
              handleSubmit,
              values,
              errors,
              getFieldProps,
            }) => {
              return (
                <Form className="login-form" onSubmit={handleSubmit}>
                  {loginFailMsg && loginFailMsg.length > 0 ? <p className="error-msg text-center">{loginFailMsg}</p> : null}
                  <Form.Group className="mb-3">
                    <Form.Label>New Password</Form.Label>
                    <div className="position-relative">
                      <Form.Control
                        type={passwordType}
                        name="password"
                        placeholder="New Password"
                        {...getFieldProps("password")}
                      />
                      <span
                        className="icon"
                        onClick={(e) => {
                          showPassword(e);
                        }}
                      >
                        {passwordType === "password" ? (
                          <IoEyeOutline />
                        ) : (
                          <IoEyeOffOutline />
                        )}
                      </span>
                    </div>
                    <ErrorMessage
                      name="password"
                      component="div"
                      className="error-msg"
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Re enter Password</Form.Label>
                    <div className="position-relative">
                      <Form.Control
                        type={passwordType}
                        name="confirmPassword"
                        placeholder="Re enter Password"
                        {...getFieldProps("confirmPassword")}
                      />
                      <span
                        className="icon"
                        onClick={(e) => {
                          showPassword(e);
                        }}
                      >
                        {passwordType === "password" ? (
                          <IoEyeOutline />
                        ) : (
                          <IoEyeOffOutline />
                        )}
                      </span>
                    </div>
                    <ErrorMessage
                      name="confirmPassword"
                      component="div"
                      className="error-msg"
                    />
                  </Form.Group>
                  <div className="d-grid pt-3">
                    <Button type="submit" className="login btn-block login-btn">
                    {!loader ? "Log in" : "Loading..."}
                    </Button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </section>
    </>
  );
};

export default ResetPassword;
