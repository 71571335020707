import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { cypherService } from "../../../service/cypherService";
import { EndpointService } from "../../../service/endpointService";
import HTTPService from "../../../service/HTTPService";

const DeleteModal = (props) => {
  const { rowData, onHide, getReportsList, setRowData, setFilterParams, setSearchFilterValue, setStart, setLimit, loader, setLoader } = props;
  const [reportName, setReportName] = useState("");
  
  useEffect(() => {
    if (rowData) {
      setReportName(rowData.reportName);
    }
  }, [rowData]);

  const deleteReport = () => {
    setLoader(true);
    const apiUrl = EndpointService.reportActionUrl + rowData.userReportId;
    HTTPService.delete(apiUrl, null)
      .then((response) => {
        onHide();
        setLoader(false);
        if (response.status.success == "Success") {
          toast.success(response.status.message);
          setSearchFilterValue('');
          setStart(0);
          setLimit(10);
          setRowData(null);
          let tempSelectedGrade = cypherService.getSessionStorage('selectedReportFilterGrade');
          setFilterParams(tempSelectedGrade);
          getReportsList(tempSelectedGrade, '', 0, 10);
        }
        if (response.status.success == "Fail") {
          setRowData(null);
          toast.error(response.status.message);
        }
      })
      .catch((error) => {
        setRowData(null);
        setLoader(false);
        toast.error(error.response.data.status.message);
        throw error;
      });
  };

  return (
    <Modal {...props} size="md" centered className="delete-modal">
      <Modal.Header>
        <Modal.Title>Delete</Modal.Title>
      </Modal.Header>
      <Modal.Body className="show-grid">
        <p>
          Are you sure you want to delete <strong>{reportName}?</strong>
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-primary" onClick={props.onHide}>
          Cancel
        </Button>
        <Button
          onClick={() => {
            deleteReport();
          }}
        >
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteModal;
