// import "antd/dist/antd.css";
import logo from "./logo.svg";
import "./App.scss";
import { BrowserRouter } from "react-router-dom";
import Routing from "./routes/Routing";
// import { Provider } from "react-redux";
// import store from "./store/store";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import {DndProvider} from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend"

function App() {
  return (
    <DndProvider backend={HTML5Backend}>
      <div className="App">
        {/* <Provider store={store}> */}
        <BrowserRouter>
          <ToastContainer
            position="top-right"
            autoClose={2000}
            hideProgressBar={false}
            newestOnTop={false}
            pauseOnFocusLoss
            pauseOnHover
          />
          <Routing />
        </BrowserRouter>
        {/* </Provider> */}
      </div>
    </DndProvider>
  );
}

export default App;
