import React, { useEffect, useState } from "react";
import { Card, Col, Dropdown, DropdownButton, Form, Row, Spinner } from "react-bootstrap";
import Header from "../../common/Header";
import isometric from "../../../assets/images/isometric_view.png";
import AssignQuestToCharModal from "./AssignQuestToCharModal";
import { cypherService } from "../../../service/cypherService";
import { EndpointService } from "../../../service/endpointService";
import HTTPService from "../../../service/HTTPService";
import { toast } from "react-toastify";
import DragItem from "./DragItem";
import DropItem from "./DropItem";
import DeleteAssignedQuestModal from "./DeleteAssignedQuestModal";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
import { useNavigate } from "react-router";

const AssignQuestion = () => {

  const [assignQuestModal, setAssignQuestModal] = useState(false);
  const [deleteQuestModal, setDeleteQuestModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const [questList, setQuestList] = useState([]);
  const [characterList, setCharacterList] = useState([]);
  const [selectedQuestForGame, setSelectedQuestForGame] = useState(null);
  const [selectedQuestToRemove, setSelectedQuestToRemove] = useState(null);
  const [filterList, setFilterList] = useState([]);
  const [filterParams, setFilterParams] = useState(null);
  const navigate = useNavigate();
  
  useEffect(()=>{
    getAllGrades();
  }, [])

  const getAssignedQuestList = (tempSelectedGrade) => {
    setLoader(true);
    let url = EndpointService.getAssignedQuestList + tempSelectedGrade;
    HTTPService.get(url)
      .then((response) => {
        setLoader(false);
        let tempQuestList = [];
        let tempCharacterList = [];
        if (response.status.success.toLowerCase() === 'success') {
          response.data.assignedQuest.map((item) => {
            if (item.quest.questId !== null) {
              tempQuestList.push(item);
            }
            tempCharacterList.push({
              "characterName": item.character.characterName,
              "characterId": item.character.characterId,
              "gameId": item.gameQuestGradeCharacterMapId,
              "selectedQuestId": item.quest.questId,
              "selectedQuestDetail": item
            })
          })
          response.data.unassignedQuest.map((item) => {
            tempQuestList.push(item);
          });
          tempCharacterList.sort(sortByCharacterID)
          setCharacterList([...tempCharacterList]);
          setQuestList([...tempQuestList]);
        }
      })
      .catch((error) => {
        setLoader(false);
        toast.error(error.response.data.status.message);
      })
  }

  const sortByCharacterID = (valueOne, valueTwo) => {
    if (valueOne.displaySequence < valueTwo.displaySequence) {
      return -1;
    }
    if (valueOne.displaySequence > valueTwo.displaySequence) {
      return 1;
    }
    return 0;
  }

  const onHide = () => {
    setAssignQuestModal(false);
    setSelectedQuestForGame(null);
  }

  const onDeleteModalHide = () => {
    setDeleteQuestModal(false);
    setSelectedQuestToRemove(null);
  }

  const onDragged = (rowData, characterDetail) => {
    let characterName = characterDetail.characterName;
    if (characterDetail.characterName.toLowerCase() === characterName.toLowerCase() && characterDetail.selectedQuestId === null) {
      let tempSelectedQuest = {
        'characterDetail': characterDetail,
        'questDetail': rowData
      }
      setSelectedQuestForGame({ ...tempSelectedQuest });
      setAssignQuestModal(true);
    }
  }

  const assignQuestToGame = () => {
    setLoader(true);
    setAssignQuestModal(false);
    let url = EndpointService.getAssignedQuestList;
    let userId = cypherService.getSessionStorage('userId');
    let payload = {
      "userId": userId,
      "gameQuestGradeCharacterMapId": selectedQuestForGame.characterDetail.gameId,
      "quest": {
        "questId": selectedQuestForGame.questDetail.questId
      }
    }
    HTTPService.post(url, payload).then((response) => {
      setLoader(false);
      if (response.status.success.toLowerCase() === 'success') {
        setSelectedQuestForGame(null);
        getAssignedQuestList(filterParams);
      }
    })
      .catch((error) => {
        setLoader(false);
        toast.error(error.response.data.status.message);
      })
  }

  const onSelectCharacter = (rowData) => {
    questList && questList.length > 0 && questList.map((item) => {
      if (item.character && (item.character.characterName.toLowerCase() === rowData.characterName.toLowerCase())) {
        let tempSelectedQuest = {
          'characterDetail': rowData,
          'questDetail': item
        }
        setSelectedQuestToRemove({ ...tempSelectedQuest });
        setDeleteQuestModal(true);
      }
    })
  }

  const removeAssignedCourse = () => {
    setLoader(true);
    let userId = cypherService.getSessionStorage('userId');
    let gameId = selectedQuestToRemove.questDetail.gameQuestGradeCharacterMapId;
    let url = EndpointService.getAssignedQuestList + `${gameId}?user-id=${userId}`;
    setDeleteQuestModal(false);
    HTTPService.put(url).then((response) => {
      setLoader(false);
      if (response.status.success.toLowerCase() === 'success') {
        setSelectedQuestToRemove(null);
        getAssignedQuestList(filterParams);
      }
    })
      .catch((error) => {
        setLoader(false);
        toast.error(error.response.data.status.message);
      })
  }

  const getAllGrades = () => {
    const apiUrl = EndpointService.getAllGrade;
    let tempFilterList = [];
    HTTPService.get(apiUrl, null)
      .then((response) => {
        Object.entries(response.data).map((item) => {
          tempFilterList.push({
            gradeId: item[0],
            gradeName: item[1]
          })
        })
        setFilterList([...tempFilterList]);
        setGradeFilterList(tempFilterList);
      })
      .catch((error) => {
        setGradeFilterList(tempFilterList);
        throw error;
      });
  }

  const setGradeFilterList = (temp_FilterList) => {
    let tempFilterList = temp_FilterList;
    let tempGradeFilterList = [];
    let tempGradeIdList = [];
    tempFilterList && tempFilterList.map((item, index) => {
      if (!tempGradeIdList.includes(item.gradeId)) {
        tempGradeFilterList.push(item);
        tempGradeIdList.push(item.gradeId)
      }
    })
    setFilterList([...tempGradeFilterList]);
    let tempSelectedGrade = cypherService.getSessionStorage('selectedGradeId');
    setFilterParams(tempSelectedGrade);
    getAssignedQuestList(tempSelectedGrade)
  }

  const getFilterName = () => {
    return filterList && filterList.map((item) => {
      if (filterParams.toString() === item.gradeId.toString()) {
        return item.gradeName
      }
    })
  }

  const filterStatusChange = (value) => {
    let tempFilterParams = filterParams
    if (filterParams !== value.gradeId) {
      tempFilterParams = value.gradeId;
      setFilterParams(tempFilterParams)
      getAssignedQuestList(tempFilterParams);
    }
  }

  return (
    <>
      <Header />
      <section className="main-container bg-white assign-quest">
        {/* Assign question */}
        {
          loader ? (
            <div className="loader-block">
              <Spinner animation="grow" variant="primary" />
            </div>
          ) : (
              <div className="assign-questions">
                <Row>
                  <Col className="quest-group-list-col">
                    <div className="page-header">
                    <div className="back-header">
                      <div className="back" onClick={() => navigate(-1)}>
                        <MdOutlineKeyboardBackspace />
                      </div>
                      <h4 className="quest-title">Assign Quests</h4>
                        <DropdownButton
                          id="dropdown-grade"
                          size="sm"
                          title={getFilterName()}
                          variant="light"
                          className="filter"
                        >
                          {
                            filterList && filterList.map((item) => (
                              <Dropdown.Item onClick={() => filterStatusChange(item)}>
                                <Form.Check
                                  type="radio"
                                  aria-label="radio 1"
                                  value={item.gradeId}
                                  checked={item.gradeId.toString() === filterParams.toString()}
                                />
                                <span>{item.gradeName}</span>
                              </Dropdown.Item>
                            ))
                          }
                        </DropdownButton>
                    </div>
                    </div>
                    <p className="hint-text">
                      Drag and drop a quest card from below onto a character on the
                      map to assign a quest
                    </p>
                    <div className="quest-group-list">
                      {
                        questList && questList.length > 0 && questList.map((item) => {
                          if (item.quest && item.quest.questId !== null) {
                            return (
                              <Card className={`assigned ${item.character.characterName.toLowerCase()}`}>
                                <Card.Body>
                                  <Card.Title className="quest-group-title">{item.quest.questName} - {item.quest.subject.subjectName}</Card.Title>
                                  <Card.Text className="quest-grade mb-1">{item.quest.grade.gradeName}</Card.Text>
                                  <Card.Text className="quest-count">{`${item.quest.noOfQuestions} Questions`}</Card.Text>
                                </Card.Body>
                              </Card>
                            )
                          }
                          else {
                            return (
                              <DragItem item={item}/>
                            )
                          }
                        })
                      }
                    </div>
                  </Col>
                  <Col className="graphics-col">
                    <div className="isometric">
                      <img src={isometric} className="img-fluid"/>
                      {
                        characterList.map((item) => (
                          <DropItem item={item} onDragged={onDragged} onSelectCharacter={onSelectCharacter }/>
                        ))
                      }
                    </div>
                    {/* <div className="save-assign-quest">
                      <Button variant="outline-primary" className="me-3">
                        Cancel
                      </Button>
                      <Button variant="primary">Save</Button>
                    </div> */}
                  </Col>
                </Row>
              </div>
          )
        }
      </section>
      <AssignQuestToCharModal show={assignQuestModal} selectedQuest={selectedQuestForGame}
        onHide={onHide} assignQuestToGame={assignQuestToGame}/>
      <DeleteAssignedQuestModal show={deleteQuestModal} selectedQuest={selectedQuestToRemove}
        onHide={onDeleteModalHide} removeAssignedCourse={removeAssignedCourse} />
    </>
  );
};

export default AssignQuestion;
