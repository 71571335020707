import React, { useEffect, useState } from 'react'
import { Button, Form, Spinner } from "react-bootstrap";
import { ErrorMessage, Formik } from "formik";
import { toast } from "react-toastify";
import { EndpointService } from "../../../service/endpointService";
import HTTPService from "../../../service/HTTPService";
import { Schema } from "./Schema";

const OpenQuestion = (props) => {

  const [initialValues, setIntitialValues] = useState();
  const [loader, setLoader] = useState(false);
  const [editLoader, setEditLoader] = useState(false);
  const [editQuestionDetails, setEditQuestionDetails] = useState(null);

  useEffect(() => {
    setIntitialValues({
      question: '',
      keywords: '',
    })
  }, [])

  useEffect(() => {
    if (props.selectedQuestionDetail) {
      getEditQuestionDetail()
    }
    else {
      setIntitialValues({
        question: '',
        keywords: ''
      })
    }
  }, [props.selectedQuestionDetail])

  const getEditQuestionDetail = () => {
    setEditLoader(true);
    let url = EndpointService.getQuestQuestionUrl + props.selectedQuestionDetail.questQuestionaireId;
    HTTPService.get(url).then((response) => {
      setEditLoader(false);
      if (response.status.success.toLowerCase() === 'success') {
        let tempQuestionDetails = response.data;
        setEditQuestionDetails(tempQuestionDetails);
        if (tempQuestionDetails) {
          let tempEditValue = {
            question: tempQuestionDetails.question,
            keywords: ''
          }
          tempQuestionDetails.questQuestionaireOption.map((item) => {
            tempEditValue.keywords = item.keyword
          })
          setIntitialValues({ ...tempEditValue });
        }
      }
    })
      .catch((error) => {
        setEditLoader(false);
        toast.error(error.response.data.status.message);
      })
  }

  const onSubmit = (values, { resetForm }) => {
    if (editQuestionDetails === null) {
      saveQuestion(values, resetForm);
    }
    else {
      updateQuestion(values, resetForm);
    }
  }

  const saveQuestion = (values, resetForm ) => {
    setLoader(true);
    let url = EndpointService.saveQUestionaireUrl;
    let payload = {
      "quest": {
        questId: props.questId
      },
      "questQuestionaireOption": [
        {
          "keyword": values.keywords

        }
      ],
      "question": values.question,
      "questionType": "OQ"
    }
    url = url + `?questQuestionaireDto=${encodeURIComponent(JSON.stringify(payload))}`
    HTTPService.post(url, payload).then((response) => {
      setLoader(false);
      if (response.status.success.toLowerCase() === 'success') {
        toast.success(response.status.message);
        resetForm();
        props.getQuestionList();
      }
    })
      .catch((error) => {
        setLoader(false);
        toast.error(error.response.data.status.message);
      })
  }

  const updateQuestion = (values, resetForm) => {
    setLoader(true);
    let url = EndpointService.saveQUestionaireUrl;
    let payloadOption = [
      {
        "keyword": values.keywords

      }
    ];
    editQuestionDetails.questQuestionaireOption.map((item) => {
      payloadOption.map((option) => {
        option["questQuestionaireOptionId"] = item.questQuestionaireOptionId
      })
    })
    let payload = {
      "quest": {
        questId: props.questId
      },
      "questQuestionaireOption": payloadOption,
      "question": values.question,
      "questQuestionaireId": editQuestionDetails.questQuestionaireId,
      "questionType": "OQ"
    }
    url = url + `?questQuestionaireDto=${encodeURIComponent(JSON.stringify(payload))}`
    HTTPService.put(url, payload).then((response) => {
      setLoader(false);
      if (response.status.success.toLowerCase() === 'success') {
        toast.success(response.status.message);
        resetForm();
        props.getQuestionList();
      }
    })
      .catch((error) => {
        setLoader(false);
        toast.error(error.response.data.status.message);
      })
  }

  const getButtonName = () => {
    if (editQuestionDetails === null) {
      return "Add Question"
    }
    else {
      return "Save Question"
    }
  }

  return (
    <div className="mcq-quest">
      {
        editLoader ? (
          <div className="loader-block">
            <Spinner animation="grow" variant="primary" />
          </div>
        ) : (
            <Formik
              initialValues={initialValues}
              validationSchema={Schema.openQuestionSchema}
              onSubmit={onSubmit}
              enableReinitialize={true}
            >
              {({
                handleChange,
                getFieldProps,
                handleSubmit
              }) => {
                return (
                  <Form className="mcq-quest-form" onSubmit={handleSubmit} autoComplete="off">
                    <div className="question">
                      <Form.Group controlId="formQuestion" className="mb-4">
                        <Form.Label className="quest-heading">Question</Form.Label>
                        <Form.Control
                          name='question'
                          disabled={props.questStatus !== 'Assign Quest'}
                          type="text"
                          placeholder="Type a Question here"
                          {...getFieldProps('question')}
                        />
                        <ErrorMessage name='question' component="div" className="error-msg" />
                      </Form.Group>
                      <Form.Group controlId="formQuestion">
                        <Form.Label className="quest-heading">Keywords</Form.Label>
                        <Form.Control
                          name='keywords'
                          disabled={props.questStatus !== 'Assign Quest'}
                          type="text"
                          as="textarea"
                          rows={4}
                          placeholder="Type keywords to the answer here"
                          {...getFieldProps('keywords')}
                        />
                        <ErrorMessage name='keywords' component="div" className="error-msg" />
                      </Form.Group>
                    </div>
                    <div className="text-end mt-5 pt-2">
                      <Button variant="primary" type='submit' disabled={loader || props.questStatus !== 'Assign Quest'}>
                        {!loader ? getButtonName() : "Saving..."}
                      </Button>
                    </div>
                  </Form>
                )
              }}
            </Formik>
        )
      }
    </div>
  )
}

export default OpenQuestion