import { ErrorMessage, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { EndpointService } from "../../../service/endpointService";
import HTTPService from "../../../service/HTTPService";
import { toast } from "react-toastify";
import { cypherService } from "../../../service/cypherService";

const AddTeacherModal = (props) => {

  const [dateOfBirth, setDateOfBirth] = useState("");
  const [gradeList, setGradeList] = useState([]);
  const [subjectList, setSubjectList] = useState([]);
  const [defaultMailId, setDefaultMailId] = useState('');

  const { type, filterParams, onHide, initialValues, validationSchema, teacherUpdate, getTableItem, getDashboardDetails, show,
      setParentSearchTxtBoxValue, setStart, setLimit, loader, setLoader } = props;

  useEffect(() => {
    if (props.teacherUpdate) {
      setDateOfBirth(new Date(initialValues.dateOfBirth))
    }
    else {
      setDateOfBirth("")
    }
  }, [props.teacherUpdate])

  useEffect(() => {
    if (props.initialValues) {
      setDefaultMailId(props.initialValues.emailId);
    }
  }, [props.initialValues])

  const formatDate = (date) => {
    let d = new Date(date);
    let day = '' + d.getDate();
    let month = '' + (d.getMonth() + 1);
    let year = d.getFullYear();
    if (month.length < 2)
      month = "0" + month;
    if (day.length < 2)
      day = "0" + day;
    return [year, month, day].join('-')
  }

  const onSubmit = (values) => {
    if (type !== 'dashboard') {
      onSubmitFromTeacherList(values);
    }
    else {
      onSubmitFromDashboard(values);
    }
  }

  const onSubmitFromDashboard = (values) => {
    onHide();
    let tempLoader = loader;
    tempLoader.teacher = true;
    setLoader({ ...tempLoader });
    let payLoad = values;
    payLoad['createdBy'] = cypherService.getSessionStorage('userId');
    delete payLoad['userId'];
    const apiUrl = EndpointService.saveUserUrl;
    HTTPService.post(apiUrl, payLoad)
      .then((response) => {
        let tempLoader = loader;
        tempLoader.teacher = false;
        setLoader({ ...tempLoader });
        if (response.status.success == "Success") {
          toast.success(response.status.message);
          getDashboardDetails(filterParams, 'dashboard');
        }
        if (response.status.success == "Fail") {
          toast.error(response.status.message);
        }
      })
      .catch((error) => {
        let tempLoader = loader;
        tempLoader.teacher = false;
        setLoader({ ...tempLoader });
        toast.error(error.response.data.status.message);
        throw error;
      });
  };

  const onSubmitFromTeacherList = (values) => {
    onHide();
    setLoader(true);
    let payLoad = values;
    if (teacherUpdate) {
      if (payLoad.emailId === defaultMailId) {
        delete payLoad["emailId"];
      }
      payLoad['updatedBy'] = cypherService.getSessionStorage('userId');
      const apiUrl = EndpointService.saveUserUrl;
      HTTPService.put(apiUrl, payLoad)
        .then((response) => {
          if (response.status.success == "Success") {
            setLoader(false);
            toast.success(response.status.message);
            setDefaultMailId('');
            setParentSearchTxtBoxValue('');
            setStart(0);
            setLimit(10);
            getTableItem('', 0, 10);
          }
          if (response.status.success == "Fail") {
            setLoader(false);
            toast.error(response.status.message);
          }
        })
        .catch((error) => {
          setLoader(false);
          toast.error(error.response.data.status.message);
          throw error;
        });
    } else {
      const apiUrl = EndpointService.saveUserUrl;
      payLoad['createdBy'] = cypherService.getSessionStorage('userId');
      HTTPService.post(apiUrl, payLoad)
        .then((response) => {
          if (response.status.success == "Success") {
            setLoader(false);
            toast.success(response.status.message);
            setParentSearchTxtBoxValue('');
            setStart(0);
            setLimit(10);
            getTableItem('', 0, 10);
          }
          if (response.status.success == "Fail") {
            setLoader(false);
            toast.error(response.status.message);
          }
        })
        .catch((error) => {
          setLoader(false);
          toast.error(error.response.data.status.message);
          throw error;
        });
    }
  };

  return (
    <Modal {...props} size="md" centered className="create-quest-modal">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        enableReinitialize={true}
      >
        {({
          handleSubmit,
          setFieldValue,
          getFieldProps,
        }) => {
          return (
            <Form className="login-form" onSubmit={handleSubmit}>
              <Modal.Header>
                <Modal.Title>{teacherUpdate ? "Update" : "Add"} Teacher</Modal.Title>
              </Modal.Header>
              <Modal.Body className="show-grid">
                <Container>
                  <Row>
                    <Col xs={12} md={6}>
                      <Form.Group className="mb-4">
                        <Form.Label>First name</Form.Label>
                        <Form.Control
                          type="text"
                          name="firstName"
                          placeholder="Enter the first name"
                          {...getFieldProps("firstName")}
                        />
                        <ErrorMessage
                          name="firstName"
                          component="div"
                          className="error-msg"
                        />
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={6}>
                      <Form.Group className="mb-4">
                        <Form.Label>Last name</Form.Label>
                        <Form.Control
                          type="text"
                          name="lastName"
                          placeholder="Enter the last name"
                          {...getFieldProps("lastName")}
                        />
                        <ErrorMessage
                          name="lastName"
                          component="div"
                          className="error-msg"
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={6}>
                      <Form.Group className="mb-4">
                        <Form.Label>Date of Birth</Form.Label>
                        <DatePicker
                          dateFormat="MM/dd/yyyy"
                          selected={dateOfBirth}
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          autoComplete="off"
                          className="form-control"
                          placeholderText="Pick a date"
                          onChange={(date) => {
                            setDateOfBirth(date);
                            setFieldValue("dateOfBirth", formatDate(date));
                          }}
                        />
                        <ErrorMessage
                          name="dateOfBirth"
                          component="div"
                          className="error-msg"
                        />
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={6}>
                      <Form.Group className="mb-4">
                        <Form.Label>Gender</Form.Label>
                        <Form.Select
                          onChange={(e) => {
                            setFieldValue("gender", e.target.value);
                          }}
                          {...getFieldProps("gender")}
                        >
                          <option value="" selected>Choose a gender</option>
                          <option value="Male">Male</option>
                          <option value="Female">Female</option>
                          <option value="Others">Others</option>
                        </Form.Select>
                        <ErrorMessage
                          name="gender"
                          component="div"
                          className="error-msg"
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={6}>
                      <Form.Group className="mb-4">
                        <Form.Label>Mobile Number</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter mobile number"
                          {...getFieldProps("contactNumber")}
                        />
                        <ErrorMessage
                          name="contactNumber"
                          component="div"
                          className="error-msg"
                        />
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={6}>
                      <Form.Group className="mb-4">
                        <Form.Label>Email Id</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Please enter the email id"
                          {...getFieldProps("emailId")}
                        />
                        <ErrorMessage
                          name="emailId"
                          component="div"
                          className="error-msg"
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12}>
                      <Form.Group className="mb-4">
                        <Form.Label>Address Line1</Form.Label>
                        <Form.Control
                          type="text"
                          name="addressLine1"
                          placeholder="Enter the address"
                          {...getFieldProps("addressLine1")}
                        />
                        <ErrorMessage
                          name="addressLine1"
                          component="div"
                          className="error-msg"
                        />
                      </Form.Group>
                    </Col>
                    <Col xs={12}>
                      <Form.Group className="mb-4">
                        <Form.Label>Address Line1</Form.Label>
                        <Form.Control
                          type="text"
                          name="addressLine2"
                          placeholder="Enter the address"
                          {...getFieldProps("addressLine2")}
                        />
                        <ErrorMessage
                          name="addressLine2"
                          component="div"
                          className="error-msg"
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={6}>
                      <Form.Group className="mb-4">
                        <Form.Label>State</Form.Label>
                        <Form.Control
                          type="text"
                          name="state"
                          placeholder="Enter the state"
                          {...getFieldProps("state")}
                        />
                        <ErrorMessage
                          name="state"
                          component="div"
                          className="error-msg"
                        />
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={6}>
                      <Form.Group className="mb-4">
                        <Form.Label>Pincode</Form.Label>
                        <Form.Control
                          type="text"
                          name="pin"
                          placeholder="Enter the pincode"
                          {...getFieldProps("pin")}
                        />
                        <ErrorMessage
                          name="pin"
                          component="div"
                          className="error-msg"
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </Container>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="outline-primary" onClick={props.onHide}>
                  Cancel
                </Button>
                <Button type="submit" className="login btn-block">
                  {teacherUpdate ? "Update Teacher" : "Add Teacher"}
                </Button>
              </Modal.Footer>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default AddTeacherModal;
