import moment from "moment";
import React from "react";
import { Card } from "react-bootstrap";
import avatar from "../../../assets/images/dashboard/avatar.png";

const ActivityFeed = (props) => {

  const { activityFeedbackList } = props;

  const getTableData = () => {
    if (activityFeedbackList && Object.keys(activityFeedbackList).length === 0) {
      return (
        <div className="no-data">
          No activity found
        </div>
      );
    }
    else {
      return (
        <ul className="activity-list">
        {
            activityFeedbackList && Object.keys(activityFeedbackList).map((key, index) => (
              <>
                <span className='feedback-list-header'>{key}</span>
                {
                  activityFeedbackList[key] && activityFeedbackList[key].map((item) => (
                    <li className="list-item">
                      <div className="profile-img">
                        <img src={avatar} alt="" className="img-fluid" />
                      </div>
                      <div className="notes">
                        <p>{item.message}</p>
                        <p>{moment(item.createdOn, 'DD-MM-YYYYTHH:mm:ss').format('LLL')}</p>
                      </div>
                    </li>
                  ))
                }
              </>
            ))
        }
        </ul>
      )
    }
  }

  return (
    <Card className="activity-feed">
      <Card.Title>
        <span>Activity Feed</span>
      </Card.Title>
      <Card.Body>
          {getTableData()}
      </Card.Body>
    </Card>
  );
};

export default ActivityFeed;
