import React, { useEffect, useState } from "react";
import { Button, Col, Nav, Row, Spinner, Tab } from "react-bootstrap";
import Header from "../../common/Header";
import MultiChoiceQuestion from "../questions/MultiChoiceQuestion";
import TrueFalse from "../questions/TrueFalse";
import FillInTheBlanks from "../questions/FillInTheBlanks";
import OpenQuestion from "../questions/OpenQuestion";
import Matching from "../questions/Matching";
import mcqImg from "../../../assets/images/questType/mcq.svg";
import trueFalseImg from "../../../assets/images/questType/true_false.svg";
import fillInTheBlanksImg from "../../../assets/images/questType/fill_in_the_blanks.svg";
import openQuestImg from "../../../assets/images/questType/open_question.svg";
import arrangeInOrderImg from "../../../assets/images/questType/arrange_in_order.svg";
import matchingImg from "../../../assets/images/questType/matching.svg";
import addQuestionImg from "../../../assets/images/add_questions.png";
import {
  MdAdd,
  MdDragIndicator,
  MdFilter,
  MdOutlineDragHandle,
  MdOutlineTextFields,
  MdChevronRight,
  MdPhotoLibrary,
  MdOutlineKeyboardBackspace,
} from "react-icons/md";
import { ImArrowLeft } from "react-icons/im";
import ArrangeInOrderImage from "../questions/ArrangeInOrderImage";
import ArrangeInOrderText from "../questions/ArrangeInOrderText";
import { EndpointService } from "../../../service/endpointService";
import HTTPService from "../../../service/HTTPService";
import { toast } from "react-toastify";
import { cypherService } from "../../../service/cypherService";
import { useNavigate } from "react-router";

const CreateQuestion = () => {
  const [arrangeInOrderImage, setArrangeInOrderImage] = useState(true);
  const [onLoadAnim, setOnLoadAnim] = useState(false);
  const [loader, setLoader] = useState(false);
  const [questId] = useState(
    cypherService.getSessionStorage("selectedQuestId")
  );
  const [questStatus, setQuestStatus] = useState(null);
  const [questName, setQuestName] = useState("");
  const [gradeName, setGradeName] = useState("");
  const [subjectName, setSubjectName] = useState("");
  const [questionList, setQuestionList] = useState([]);
  const [selectedQuestionDetail, setSelectedQuestionDetail] = useState(null);
  const [selectedTab, setSelectedTab] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    setOnLoadAnim(true);
    getQuestionList('init');
  }, []);

  const arrangeInOrder = (questType) => {
    if (questType == "image") {
      setArrangeInOrderImage(true);
    } else {
      setArrangeInOrderImage(false);
    }
  };

  const disableOnLoadAnim = () => {
    setOnLoadAnim(false);
  };

  const getQuestionList = (type = '') => {
    let url = EndpointService.getQuestionListUrl + questId;
    if (type === 'init') {
      setLoader(true);
    }
    HTTPService.get(url)
      .then((response) => {
        if (type === 'init') {
          setLoader(false);
        }
        if (response.status.success.toLowerCase() === "success") {
          setQuestionList([...response.data.questQuestionaireListDto]);
          if (response.data.quest && response.data.quest.questStatus) {
            setQuestStatus(response.data.quest.questStatus);
          }
          setSubjectName(
            response.data.quest.subject
              ? response.data.quest.subject.subjectName
              : ""
          );
          setGradeName(
            response.data.quest.grade ? response.data.quest.grade.gradeName : ""
          );
          setQuestName(
            response.data.quest ? response.data.quest.questName : ""
          );
          setSelectedQuestionDetail(null);
          if (response.data.questQuestionaireListDto.length !== 0) {
            disableOnLoadAnim();
          }
          if (
            response.data.questQuestionaireListDto.length !== 0 &&
            selectedTab === ""
          ) {
            setSelectedTab("MCQ");
          }
        }
      })
      .catch((error) => {
        if (type === 'init') {
          setLoader(false);
        }
        toast.error(error.response.data.status.message);
      });
  };

  const getQuestionsOfQuest = (selectedQuestion) => {
    setSelectedQuestionDetail(selectedQuestion);
    if (
      selectedQuestion.questionType === "AOI" ||
      selectedQuestion.questionType === "AOS"
    ) {
      if (selectedQuestion.questionType === "AOI") {
        setArrangeInOrderImage(true);
      } else {
        setArrangeInOrderImage(false);
      }
      setSelectedTab("arrangeInOrder");
    } else {
      setSelectedTab(selectedQuestion.questionType);
    }
  };

  const getQuestionaireListToDisplay = () => {
    return questionList.map((item) => {
      if (item.questionType === "TF") {
        return (
          <li className="list-item true-false">
            <div className="card" onClick={() => getQuestionsOfQuest(item)}>
              <span className="quest-type-img">
                <img src={trueFalseImg} />
              </span>
              <span className="quest-type">{item.question}</span>
            </div>
            {/* <div className="draggable">
                    <span className="line">
                      <MdOutlineDragHandle />
                    </span>
                  </div> */}
          </li>
        );
      } else if (item.questionType === "MCQ") {
        return (
          <li className="list-item mcq">
            <div className="card" onClick={() => getQuestionsOfQuest(item)}>
              <span className="quest-type-img">
                <img src={mcqImg} />
              </span>
              <span className="quest-type">{item.question}</span>
            </div>
            {/* <div className="draggable">
                <span className="line">
                  <MdOutlineDragHandle />
                </span>
              </div> */}
          </li>
        );
      } else if (item.questionType === "FIB") {
        return (
          <li className="list-item fill-in-the-blanks">
            <div className="card" onClick={() => getQuestionsOfQuest(item)}>
              <span className="quest-type-img">
                <img src={fillInTheBlanksImg} />
              </span>
              <span className="quest-type">{item.question}</span>
            </div>
            {/* <div className="draggable">
                    <span className="line">
                      <MdOutlineDragHandle />
                    </span>
                  </div> */}
          </li>
        );
      } else if (item.questionType === "OQ") {
        return (
          <li className="list-item open-quest">
            <div className="card" onClick={() => getQuestionsOfQuest(item)}>
              <span className="quest-type-img">
                <img src={openQuestImg} />
              </span>
              <span className="quest-type">{item.question}</span>
            </div>
            {/* <div className="draggable">
                    <span className="line">
                      <MdOutlineDragHandle />
                    </span>
                  </div> */}
          </li>
        );
      } else if (item.questionType === "MFP") {
        return (
          <li className="list-item match-the-following-pair">
            <div className="card" onClick={() => getQuestionsOfQuest(item)}>
              <span className="quest-type-img">
                <img src={matchingImg} />
              </span>
              <span className="quest-type">{item.question}</span>
            </div>
            {/* <div className="draggable">
                <span className="line">
                  <MdOutlineDragHandle />
                </span>
              </div> */}
          </li>
        );
      } else if (item.questionType === "AOI" || item.questionType === "AOS") {
        return (
          <li className="list-item arrange-in-order">
            <div className="card" onClick={() => getQuestionsOfQuest(item)}>
              <span className="quest-type-img">
                <img src={arrangeInOrderImg} />
              </span>
              <span className="quest-type">{item.question}</span>
            </div>
            {/* <div className="draggable">
                    <span className="line">
                      <MdOutlineDragHandle />
                    </span>
                  </div> */}
          </li>
        );
      } else {
        return null;
      }
    });
  };

  const navigateToQuestList = () => {
    navigate("/teacher/my-quest");
  };

  const tabChange = (tab) => {
    setSelectedQuestionDetail(null);
    setSelectedTab(tab);
  };

  return (
    <>
      <Header />
      <section className="main-container bg-white create-quest">
        {/* onload animation */}
        {loader ? (
          <div className="loader-block">
            <Spinner animation="grow" variant="primary" />
          </div>
        ) : (
          onLoadAnim && (
            <section className="onload-anim">
              <div className="anim-box">
                <div className="anim-icon">
                  <ImArrowLeft />
                </div>
                <div className="anim-text">
                  Choose a question type from the left to continue
                </div>                
              </div>
            </section>
          )
        )}
        {/* create question */}
        {!loader && (
          <div className="create-questions">
            <Row>
              <Col xl={7}>
                <div className="quest-header">
                  <div className="back-header">
                    <div className="back" onClick={() => navigate(-1)}>
                      <MdOutlineKeyboardBackspace />
                    </div>
                    <h3 className="quest-title">{`${
                      gradeName ? gradeName : ""
                    } - ${subjectName ? subjectName : ""}`}</h3>
                  </div>
                  <span className="quest-count">{`(${questionList.length})`}</span>
                </div>
                <div className="questions">
                  <Tab.Container
                    id="createQuestionTab"
                    defaultActiveKey={questionList.length !== 0 && "MCQ"}
                    activeKey={selectedTab}
                    onSelect={(e) => tabChange(e)}
                  >
                    <Nav
                      variant="pills"
                      className={
                        onLoadAnim
                          ? "flex-column quest-nav onload-nav"
                          : "flex-column quest-nav"
                      }
                      onSelect={() => {
                        disableOnLoadAnim();
                      }}
                    >
                      <Nav.Item>
                        <Nav.Link eventKey="MCQ" className="mcq">
                          <span className="quest-type-img">
                            <img src={mcqImg} />
                          </span>
                          <span className="quest-type">MCQ</span>
                          {/* icons */}
                          <div className="icons">
                            <span className="icon">
                              <MdAdd className="icon-plus" />
                              <MdChevronRight className="icon-arrow" />
                            </span>
                          </div>
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="TF" className="true-false">
                          <span className="quest-type-img">
                            <img src={trueFalseImg} />
                          </span>
                          <span className="quest-type">True / False</span>
                          {/* icons */}
                          <div className="icons">
                            <span className="icon">
                              <MdAdd className="icon-plus" />
                              <MdChevronRight className="icon-arrow" />
                            </span>
                          </div>
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="FIB" className="fill-in-the-blanks">
                          <span className="quest-type-img">
                            <img src={fillInTheBlanksImg} />
                          </span>
                          <span className="quest-type">Fill in the blanks</span>
                          {/* icons */}
                          <div className="icons">
                            <span className="icon">
                              <MdAdd className="icon-plus" />
                              <MdChevronRight className="icon-arrow" />
                            </span>
                          </div>
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="OQ" className="open-quest">
                          <span className="quest-type-img">
                            <img src={openQuestImg} />
                          </span>
                          <span className="quest-type">Open Question</span>
                          {/* icons */}
                          <div className="icons">
                            <span className="icon">
                              <MdAdd className="icon-plus" />
                              <MdChevronRight className="icon-arrow" />
                            </span>
                          </div>
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link
                          eventKey="arrangeInOrder"
                          className="arrange-in-order"
                        >
                          <span className="quest-type-img">
                            <img src={arrangeInOrderImg} />
                          </span>
                          <span className="quest-type">Arrange in Order</span>
                          {/* icons */}
                          <div className="icons two">
                            <span className="icon">
                              <MdPhotoLibrary
                                className="icon-image"
                                onClick={() => {
                                  arrangeInOrder("image");
                                }}
                              />
                            </span>
                            <span className="icon">
                              <MdOutlineTextFields
                                className="icon-text"
                                onClick={() => {
                                  arrangeInOrder("text");
                                }}
                              />
                            </span>
                          </div>
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="MFP" className="matching">
                          <span className="quest-type-img">
                            <img src={matchingImg} />
                          </span>
                          <span className="quest-type">Matching</span>
                          {/* icons */}
                          <div className="icons">
                            <span className="icon">
                              <MdAdd className="icon-plus" />
                              <MdChevronRight className="icon-arrow" />
                            </span>
                          </div>
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                    <Tab.Content>
                      <Tab.Pane
                        eventKey="MCQ"
                        mountOnEnter="true"
                        unmountOnExit="true"
                      >
                        <MultiChoiceQuestion
                          questId={questId}
                          questStatus={questStatus}
                          getQuestionList={getQuestionList}
                          selectedQuestionDetail={selectedQuestionDetail}
                        />
                      </Tab.Pane>
                      <Tab.Pane
                        eventKey="TF"
                        mountOnEnter="true"
                        unmountOnExit="true"
                      >
                        <TrueFalse
                          questId={questId}
                          questStatus={questStatus}
                          getQuestionList={getQuestionList}
                          selectedQuestionDetail={selectedQuestionDetail}
                        />
                      </Tab.Pane>
                      <Tab.Pane
                        eventKey="FIB"
                        mountOnEnter="true"
                        unmountOnExit="true"
                      >
                        <FillInTheBlanks
                          questId={questId}
                          questStatus={questStatus}
                          getQuestionList={getQuestionList}
                          selectedQuestionDetail={selectedQuestionDetail}
                        />
                      </Tab.Pane>
                      <Tab.Pane
                        eventKey="OQ"
                        mountOnEnter="true"
                        unmountOnExit="true"
                      >
                        <OpenQuestion
                          questId={questId}
                          questStatus={questStatus}
                          getQuestionList={getQuestionList}
                          selectedQuestionDetail={selectedQuestionDetail}
                        />
                      </Tab.Pane>
                      <Tab.Pane
                        eventKey="arrangeInOrder"
                        mountOnEnter="true"
                        unmountOnExit="true"
                      >
                        {arrangeInOrderImage && (
                          <ArrangeInOrderImage
                            questId={questId}
                            questStatus={questStatus}
                            getQuestionList={getQuestionList}
                            selectedQuestionDetail={selectedQuestionDetail}
                          />
                        )}
                        {!arrangeInOrderImage && (
                          <ArrangeInOrderText
                            questId={questId}
                            questStatus={questStatus}
                            getQuestionList={getQuestionList}
                            selectedQuestionDetail={selectedQuestionDetail}
                          />
                        )}
                      </Tab.Pane>
                      <Tab.Pane
                        eventKey="MFP"
                        mountOnEnter="true"
                        unmountOnExit="true"
                      >
                        <Matching
                          questId={questId}
                          questStatus={questStatus}
                          getQuestionList={getQuestionList}
                          selectedQuestionDetail={selectedQuestionDetail}
                        />
                      </Tab.Pane>
                    </Tab.Content>
                  </Tab.Container>
                </div>
              </Col>
              <Col xl={5} className="current-quest">
                <div className="quest-list-header">
                  <h4 className="quest-list-title mb-4">{questName}</h4>
                </div>
                {questionList.length === 0 ? (
                  <div className="empty-quest-list">
                    <img src={addQuestionImg} alt="" />
                    <p className="mt-4">Please add a question to continue</p>
                  </div>
                ) : (
                  <ul className="question-list">
                    {getQuestionaireListToDisplay()}
                  </ul>
                )}
                <div className="text-center btn-block">
                  <Button variant="outline-primary" className="me-3" onClick={() => navigateToQuestList()}>
                    Cancel
                  </Button>
                  <Button variant="primary" onClick={() => navigateToQuestList()}>Save</Button>
                </div>
              </Col>
            </Row>
          </div>
        )}
      </section>
    </>
  );
};

export default CreateQuestion;
