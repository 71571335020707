import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { cypherService } from "../../../service/cypherService";
import { EndpointService } from "../../../service/endpointService";
import HTTPService from "../../../service/HTTPService";

const DeleteModal = (props) => {

  const { rowData, onHide, getTableItem, filterParams, setParentSearchTxtBoxValue, loader, setLoader } = props;

  const [teacherName, setTeacherName] = useState("");
  const [userId, setUserId] = useState("");

  useEffect(() => {
    if (rowData) {
      setTeacherName(rowData.row.original.name);
      setUserId(rowData.row.original.userId);
    }
  }, [rowData]);

  const deleteTeacher = () => {
    onHide();
    setLoader(true);
    const apiUrl = EndpointService.saveUserUrl + userId;
    HTTPService.delete(apiUrl, null)
      .then((response) => {
        setLoader(false);
        if (response.status.success == "Success") {
          toast.success(response.status.message);
          setParentSearchTxtBoxValue('');
          getTableItem('', 0, 10);
        }
        if (response.status.success == "Fail") {
          toast.error(response.status.message);
        }
      })
      .catch((error) => {
        toast.error(error.response.data.status.message);
        throw error;
      });
  };

  return (
    <Modal {...props} size="md" centered className="delete-modal">
      <Modal.Header>
        <Modal.Title>Delete</Modal.Title>
      </Modal.Header>
      <Modal.Body className="show-grid">
        <p>
          Are you sure you want to delete <strong>{teacherName}?</strong>
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-primary" onClick={props.onHide}>
          Cancel
        </Button>
        <Button
          onClick={() => {
            deleteTeacher();
          }}
        >
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteModal;
