import React from "react";
import {
  Card,
  Dropdown,
  DropdownButton,
  Form,
  Spinner,
  Table,
} from "react-bootstrap";
import { MdOpenInFull } from "react-icons/md";
import { useNavigate } from "react-router";
import moment from "moment";
import { cypherService } from "../../../service/cypherService";

const MyQuests = (props) => {

  const { loader, filterParams, questList, filterList, getDashboardDetails } = props;

  const navigate = useNavigate();

  const expandCard = (e) => {
    let elem = e.target.parentElement.closest(".card");
    if (elem.classList.contains("expand")) {
      elem.classList.remove("expand");
    } else {
      elem.classList.add("expand");
    }
    setTimeout(() => {
      cypherService.setSessionStorage('expandSchoolQuestCount', props.questCount);
      cypherService.setSessionStorage('expandSchoolQuestList', JSON.stringify(props.questList));
      navigate("/school/my-quest");
    }, 200);
  };

  const filterStatusChange = (value) => {
    let tempFilterParams = filterParams
    if (filterParams.quest !== value.gradeId) {
      tempFilterParams.quest = value.gradeId;
      cypherService.setSessionStorage('selectedQuestGrade', value.gradeId)
      getDashboardDetails(tempFilterParams, 'quest');
    }
  }

  const getTableData = () => {
    if (questList && questList.length === 0) {
      return (
        <div className="no-data">
          No data found
        </div>
      );
    }
    else {
      return (
        <Table responsive className="homework-table dashboard-table">
          <thead>
            <tr>
              <th>Quest Name</th>
              <th>Status</th>
              <th>Grade</th>
              <th>Students</th>
              <th>Assigned</th>
              <th>Deadline</th>
            </tr>
          </thead>
          <tbody>
            {
              questList && questList.map((item) => (
                <tr>
                  <td>{item.questName}</td>
                  <td className="status">
                    <span className={`${item.questStatus.toLowerCase().replaceAll(' ', '')}`}>{item.questStatus}</span>
                  </td>
                  <td>{item.gradeName}</td>
                  <td>{item.studentsCount}</td>
                  <td>{moment(item.assignedDate).format("DD/MM/YYYY")}</td>
                  <td>{moment(item.deadLine).format("DD/MM/YYYY")}</td>
                </tr>
              ))
            }
          </tbody>
        </Table>
      )
    }
  }

  const getFilterName = () => {
    return filterList && filterList.map((item) => {
      if (filterParams.quest === item.gradeId) {
        return item.gradeName
      }
    })
  }

  return (
    <>
      <Card className="my-quests">
        <Card.Title>
          <span>All Quests</span>
          <div
            className="expand"
            onClick={(e) => {
              expandCard(e);
            }}
          >
            <MdOpenInFull />
          </div>
          <DropdownButton
            id="dropdown-grade"
            size="sm"
            title={getFilterName()}
            variant="light"
            className="filter"            
          >
            {
              filterList && filterList.map((item) => (
                <Dropdown.Item onClick={() => filterStatusChange(item)}>
                  <Form.Check
                    type="radio"
                    aria-label="radio 1"
                    value={item.gradeId}
                    checked={item.gradeId === filterParams.quest}
                  />
                  <span>{item.gradeName}</span>
                </Dropdown.Item>
              ))
            }             
          </DropdownButton>
        </Card.Title>
        <Card.Body className="card-body">
          {
            loader.quest ? (
              <div className="loader"><Spinner animation="grow" variant="primary" /></div>
            ) : (
              getTableData()
            )
          }
        </Card.Body>
      </Card>      
    </>
  );
};

export default MyQuests;
