import { Card } from 'react-bootstrap';
import { useDrag } from 'react-dnd';

function DragItem(props) {

    const { item } = props;

    const [{ isDragging }, drag] = useDrag(() => ({
        type: "quest",
        item: { id: item },
        collect: (monitor) => ({
            isDragging: !!monitor.isDragging(),
        }),
    }));
   
    return (
        <Card className="unassigned" id={item.questId} ref={drag}>
            <Card.Body>
                <Card.Title className="quest-group-title">{item.questName} - {item.subject.subjectName}</Card.Title>
                <Card.Text className="quest-grade mb-1">{item.grade.gradeName}</Card.Text>
                <Card.Text className="quest-count">{`${item.noOfQuestions} Questions`}</Card.Text>
            </Card.Body>
        </Card>
    );
}

export default DragItem;
