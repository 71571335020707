import React from "react";
import { Card, Dropdown, DropdownButton, Form, Spinner } from "react-bootstrap";
import avatar from "../../../assets/images/dashboard/avatar.png";
import { MdOpenInFull } from "react-icons/md";
import { useNavigate } from "react-router";
import { cypherService } from "../../../service/cypherService";

const MyStudents = (props) => {

  const { loader, filterParams, studentList, filterList, getDashboardDetails } = props;
  const navigate = useNavigate();

  const expandCard = (e) => {
    let elem = e.target.parentElement.closest(".card");
    if (elem.classList.contains("expand")) {
      elem.classList.remove("expand");
    } else {
      elem.classList.add("expand");
    } 
    setTimeout(() => {
      cypherService.setSessionStorage('expandStudentCount', props.studentCount);
      cypherService.setSessionStorage('expandStudentList', JSON.stringify(props.studentList));
      navigate("/teacher/my-student");
    }, 200);
  };

  const filterStatusChange = (value) => {
    let tempFilterParams = filterParams
    if (filterParams.student !== value.gradeId) {
      tempFilterParams.student = value.gradeId;
      cypherService.setSessionStorage('selectedStudentFilterGrade', value.gradeId)
      getDashboardDetails(tempFilterParams, 'student');
    }
  }

  const getTableData = () => {
    if (studentList && studentList.length === 0) {
      return (
        <div className="no-data">
          No student found
        </div>
      );
    }
    else {
      return (
        <ul className="activity-list">
          {
            studentList && studentList.map((item) => (
              <li className="list-item">
                <div className="profile-img">
                  <img src={avatar} alt="" className="img-fluid" />
                </div>
                <div className="notes">
                  <p>{item.firstName + (item.lastName ? (" " + item.lastName) : '')}</p>
                  <p>{item.grade.gradeName}</p>
                </div>
              </li>
            ))
          }
        </ul>
      )
    }
  }

  const getFilterName = () => {
    return filterList && filterList.map((item) => {
      if (filterParams.student == item.gradeId) {
        return item.gradeName
      }
    })
  }

  return (
    <Card className="my-students">
      <Card.Title>
        <span>My Students</span>
          <div
            className="expand"
            onClick={(e) => {
              expandCard(e);
            }}
          >
            <MdOpenInFull />
          </div>
          <DropdownButton
            id="dropdown-grade"
            size="sm"
            title={getFilterName()}
            variant="light"
            className="filter"            
          >
            {
              filterList && filterList.map((item) => (
                <Dropdown.Item onClick={() => filterStatusChange(item)}>
                  <Form.Check
                    type="radio"
                    aria-label="radio 1"
                    value={item.gradeId}
                    checked={item.gradeId == filterParams.student}
                  />
                  <span>{item.gradeName}</span>
                </Dropdown.Item>
              ))
            }                
          </DropdownButton>
      </Card.Title>
      <Card.Body>
        {
          loader.student ? (
            <div className="loader"><Spinner animation="grow" variant="primary" /></div>
          ) : (
              getTableData()
          )
        }
      </Card.Body>
    </Card>
  );
};

export default MyStudents;
