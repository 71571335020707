import React, { useState, useEffect } from "react";
import Header from "../../common/Header";
import MyQuests from "./MyQuests";
import ActivityFeed from "./ActivityFeed";
import Profile from "./Profile";
import Leaderboard from "./Leaderboard";
import ActiveQuests from "./ActiveQuests";
import { cypherService } from "../../../service/cypherService";
import { EndpointService } from "../../../service/endpointService";
import HTTPService from "../../../service/HTTPService";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import moment from 'moment';

const ParentDashboard = () => {

  const [activityFeedbackList, setActivityFeedbackList] = useState(null);
  const [leaderboardList, setLeaderboardList] = useState(null);
  const [questList, setQuestList] = useState(null);
  const [activeQuestList, setActiveQuestList] = useState(null);
  const [profile, setProfile] = useState(null);
  const [loader, setLoader] = useState(false);
  const [questCount, setQuestCount] = useState(0);

  useEffect(() => {
    getDashboardDetails();
  }, [])

  const getGradeId = (response) => {
    if (response && response.studentProfile && response.studentProfile.student) {
      cypherService.setSessionStorage('studentGradeId', response.studentProfile.student.grade.gradeId);
    }
  }

  const getDashboardDetails = () => {
    setLoader(true);
    let studentId = cypherService.getSessionStorage('studentId');
    let url = EndpointService.parentDashboardUrl + studentId;
    HTTPService.get(url).then((response) => {
      setLoader(false);
      setDashboardDetails(response);
      cypherService.setSessionStorage('parentDashboardDetails', JSON.stringify(response));
    })
      .catch((error) => {
        setLoader(false);
        toast.error(error.response.data.status.message);
      })
  }

  const setDashboardDetails = (response) => {
    if (response.status.success.toLowerCase() === 'success') {
      if (response.data.activityFeed !== null) {
        let tempActivityList = response.data.activityFeed;
        let activityFeedBackKeys = Object.keys(tempActivityList);
        let activityFeedBackValues = Object.values(tempActivityList);
        let tempFeedBack = {};
        activityFeedBackKeys && activityFeedBackKeys.length > 0 && activityFeedBackKeys.map((dateKey, index) => {
          if (moment(dateKey).format('DD-MM-YYYY') === moment().format('DD-MM-YYYY')) {
            tempFeedBack['Today'] = activityFeedBackValues[index];
          }
          else if (moment(dateKey).format('DD-MM-YYYY') === moment().subtract(1, 'days').format('DD-MM-YYYY')) {
            tempFeedBack['Yesterday'] = activityFeedBackValues[index];
          }
          else {
            tempFeedBack[moment(dateKey).format('DD-MM-YYYY')] = activityFeedBackValues[index];
          }
        })
        setActivityFeedbackList({ ...tempFeedBack })
      }
      else {
        setActivityFeedbackList({});
      }
      getGradeId(response.data);
      setQuestCount(response.data.studentQuestDetailsDtoCount);
      setLeaderboardList([...(response.data.leaderBoard ? response.data.leaderBoard : [])]);
      setQuestList([...(response.data.myQuest ? response.data.myQuest : [])]);
      setProfile({ ...(response.data.studentProfile ? response.data.studentProfile : {}) });
      setActiveQuestList([...(response.data.activeQuest ? response.data.activeQuest : [])]);
    }
  }

  return (
    <section className="main-container">
      <Header />
      <section className="dashboard-container parent-dashboard-container">
        {
          loader ? (
            <div className="dashboard-loader"><Spinner animation="grow" variant="primary" /></div>
          ) : (
            <>
              <Profile profile={profile} />
              <Leaderboard leaderboardList={leaderboardList} />
              <MyQuests questList={questList} questCount={questCount}/>
              <ActivityFeed activityFeedbackList={activityFeedbackList} />              
              <ActiveQuests activeQuestList={activeQuestList} />
            </>
          )
        }
      </section>
    </section>
  );
};

export default ParentDashboard;
