import * as Yup from "yup";

const trueOrFalseSchema = Yup.object({
    question: Yup.string().required('Please enter the question'),
    answer: Yup.string().required('Please select an answer').nullable(true)
})

const fillInTheBlanksSchema = Yup.object({
    question: Yup.string().required('Please enter the question'),
    answer: Yup.string().required('Please enter an answer')
})

const mcqSchema = Yup.object({
    question: Yup.string().required('Please enter the question'),
    options: Yup.array().of(
        Yup.object().shape({
            option: Yup.string().required("Please enter the option"),
        })
    ),
    answer: Yup.string().required('Please select an answer')
})

const openQuestionSchema = Yup.object({
    question: Yup.string().required('Please enter the question'),
    // answer: Yup.string().required('Please enter an   answer'),
    keywords: Yup.string().required('Please enter the keywords')
})  

const arrangeInOrderTextSchema = Yup.object({
    question: Yup.string().required('Please enter the question'),
    options: Yup.array().of(
        Yup.object().shape({
            option: Yup.string().required("Please enter the sentence"),
        })
    ),
})

const matchingSchema = Yup.object({
    question: Yup.string().required('Please enter the question'),
    columnA: Yup.string().required("Please enter the column A name"),
    columnB: Yup.string().required("Please enter the column B name"),
    options: Yup.array().of(
        Yup.object().shape({
            "columnA-option": Yup.string().required("This field is required"),
            "columnB-option": Yup.string().required("This field is required")
        })
    ),
})

export const Schema = {
    trueOrFalseSchema,
    mcqSchema,
    fillInTheBlanksSchema,
    matchingSchema,
    arrangeInOrderTextSchema,
    openQuestionSchema
} 