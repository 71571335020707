import axios from "axios";
import instance from "./AxiosConfig";

export default class HTTPService {
  static post = (apiUrl, payload) => {
    return instance
      .post(apiUrl, payload)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  };
  static delete = (apiUrl) => {
    return instance
      .delete(apiUrl)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  };
  static put = (apiUrl, payload) => {
    return instance
      .put(apiUrl, payload)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  };
  //config.headers['content-type'] = 'application/json';
  static get = (apiUrl, payload) => {
    return instance
      .get(apiUrl, {})
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  };
  static imageWithContentRequest = (apiUrl, payload) => {
    return instance
      .post(apiUrl, {
        data: payload,
      })
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  };
}
