import React, { useEffect, useState } from "react";
import {
  Breadcrumb,
  Button,
  Card,
  Dropdown,
  DropdownButton,
  Form,
  InputGroup,
  Spinner,
} from "react-bootstrap";
import Header from "../../common/Header";
import AddTeacherModal from "./CreateTeacherModal";
import {
  MdDelete,
  MdFileCopy,
  MdHome,
  MdOpenInFull,
  MdOutlineMoreVert,
  MdSearch,
} from "react-icons/md";
import { useNavigate } from "react-router";
import { cypherService } from "../../../service/cypherService";
import TableData from "./Table";
import HTTPService from "../../../service/HTTPService";
import { EndpointService } from "../../../service/endpointService";
import { Schema } from "./Schema";
import { toast } from "react-toastify";
import DeleteModal from "./DeleteModal";
import { debounce } from "lodash";

const AllTeachersList = () => {

  const [createTeacherModal, setCreateTeacherModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const [tableItem, setTableItem] = useState([]);
  const [parentSearchTxtBoxValue, setParentSearchTxtBoxValue] = useState("");
  const [initialValues, setInitialValues] = useState(Schema.initialValues);
  const [validationSchema] = useState(
    Schema.validationSchema
  );
  const [teacherUpdate, setTeacherUpdate] = useState(false);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [rowData, setRowData] = useState("");
  const [filterList, setFilterList] = useState([]);
  const [start, setStart] = useState(0);
  const [limit, setLimit] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [filterParams, setFilterParams] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    getTableItem(parentSearchTxtBoxValue, start, limit);
  }, []);

  // GetTableItem
  const getTableItem = (searchText, tempStart, tempLimit) => {
    setLoader(true);
    let apiUrl = EndpointService.getUserListUrl + `?start=${tempStart}&limit=${tempLimit}`;
    if (searchText !== '') {
      apiUrl = apiUrl + `&search=${searchText}`;
    }
    HTTPService.get(apiUrl, null)
      .then((response) => {
        let tempTableItems = [];
        if (response.status.success == "Success") {
          setLoader(false);
          tempTableItems = getTeacherDetails(response.data);
          setTotalCount(response.totalResults);
        }
        if (response.status.success == "Fail") {
          setLoader(false);
        }
        setTableItem([...tempTableItems]);
      })
      .catch((error) => {
        throw error;
      });
  };

  const getTeacherDetails = (teacherDetails) => {
    let tempTeacherDetails = [];
    teacherDetails && teacherDetails.map((item) => {
      let tempData = {};
      tempData['originalDetails'] = item;
      let name = item.firstName;
      if (item.lastName) {
        name = name + ' ' + item.lastName;
      }
      tempData['name'] = name;
      tempData['userId'] = item.userId;
      tempData['address'] = '';
      if (item.addressLine1) {
        tempData['address'] = item.addressLine1;
      }
      if (item.addressLine2 && tempData['address'] !== '') {
        tempData['address'] = tempData['address'] + ', ' + item.addressLine2;
      }
      else if (item.addressLine2 && tempData['address'] === '') {
        tempData['address'] = tempData['address'] + item.addressLine2;
      }
      if (tempData['address'] !== '' && item.pin) {
        tempData['address'] = tempData['address'] + ' ' + item.pin;
      }
      if (tempData['address'] === '') {
        tempData['address'] = '-';
      }
      tempData['contactNo'] = item.contactNumber;
      tempData['email'] = item.emailId;
      tempTeacherDetails.push(tempData);
    })
    return tempTeacherDetails;
  }

  const expandCard = (e) => {
    let elem = e.target.parentElement.closest(".card");
    if (elem.classList.contains("expand")) {
      elem.classList.remove("expand");
    } else {
      elem.classList.add("expand");
    }
    setTimeout(() => {
      navigate("/school/dashboard");
    }, 200);
  };

  const setGradeFilterList = () => {
    let gradeList = cypherService.getSessionStorage('allGradeList') ? JSON.parse(cypherService.getSessionStorage('allGradeList')) : null;
    if (gradeList && gradeList != 'null') {
      setFilterList([...gradeList]);
      let tempSelectedGrade = cypherService.getSessionStorage('selectedTeacherGrade');
      setFilterParams(tempSelectedGrade);
      getTableItem(parentSearchTxtBoxValue, start, limit);
    }
    else {
      const apiUrl = EndpointService.getAllGrade;
      HTTPService.get(apiUrl, null)
        .then((response) => {
          let tempFilterList = [];
          Object.entries(response.data).map((item) => {
            tempFilterList.push({
              gradeName: item[1],
              gradeId: item[0]
            })
          })
          setFilterList([...tempFilterList]);
          let tempSelectedGrade = cypherService.getSessionStorage('selectedTeacherGrade');
          setFilterParams(tempSelectedGrade);
          getTableItem(parentSearchTxtBoxValue, start, limit);
        })
        .catch((error) => {
          throw error;
        });
    }
  }

  const onSearch = debounce((event) => {
    setParentSearchTxtBoxValue(event.target.value);
    getTableItem(event.target.value, start, limit);
  }, 1000);

  const columns = [
    {
      Header: "Teacher",
      accessor: "name",
      Cell: (row) => (
        <span>{row.cell.value}</span>
      )
    },
    {
      Header: "Contact",
      accessor: "contactNo",
    },
    {
      Header: "Email",
      accessor: "email",
    },
    {
      Header: "Address",
      accessor: "address",
    },
    {
      Header: "",
      accessor: "action",
      Cell: (row) => (
        <DropdownButton
          align="end"
          title={<MdOutlineMoreVert />}
          id="dropdown-menu-align-end"
          variant="link"
          className="td-action"
        >
          <Dropdown.Item
            eventKey="1"
            onClick={() => {
              getTeacherById(row);
            }}
          >
            <MdFileCopy /> Edit Details
          </Dropdown.Item>
          <Dropdown.Item
            className="trash-icon"
            eventKey="2"
            onClick={() => {
              deleteTeacher(row);
            }}
          >
            <MdDelete /> Delete
          </Dropdown.Item>
        </DropdownButton>
      ),
    },
  ];

  const data = tableItem;

  const createTeacher = () => {
    setCreateTeacherModal(true);
    setTeacherUpdate(false);
    setInitialValues({
      ...initialValues,
      firstName: "",
      lastName: "",
      contactNumber: "",
      emailId: "",
      dateOfBirth: "",
      gender: "",
      addressLine1: "",
      addressLine2: "",
      state: "",
      pin: "",
      userId: ""
    })
  };

  const deleteTeacher = (row) => {    
    setRowData(row)
    setDeleteModalShow(true);
  };

  const getTeacherById = (e) => {
    let userId = e.cell.row.original.userId;
    let apiUrl = EndpointService.saveUserUrl + userId;
    HTTPService.get(apiUrl, null)
      .then((response) => {
        const data = response.data;
        if (response.status.success == "Success") {
          setTeacherUpdate(true);
          setInitialValues({
            ...initialValues,
            firstName: data.firstName,
            lastName: data.lastName,
            contactNumber: data.contactNumber,
            emailId: data.emailId,
            dateOfBirth: new Date(data.dateOfBirth),
            gender: data.gender ? data.gender.trim() : '',
            addressLine1: data.addressLine1,
            addressLine2: data.addressLine2,
            state: data.state,
            pin: data.pin,
            userId: data.userId
          });
          setCreateTeacherModal(true);
        }

        if (response.status.success == "Fail") {
          toast.error(response.status.message);
        }
      })
      .catch((error) => {
        toast.error(error.response.data.status.message);
        throw error;
      });
  };

  const getFilterName = () => {
    return filterList && filterList.map((item) => {
      if (filterParams.toString() === item.gradeId.toString()) {
        return item.gradeName
      }
    })
  }

  const filterStatusChange = (value) => {
    let tempFilterParams = filterParams
    if (filterParams.toString() !== value.gradeId.toString()) {
      tempFilterParams = value.gradeId;
      cypherService.setSessionStorage('selectedQuestListFilterGrade', value.gradeId);
    }
    setFilterParams(tempFilterParams);
    getTableItem(parentSearchTxtBoxValue, start, limit)
  }

  return (
    <>
      <Header />
      <section className="main-container">
        <div className="dashboard-container school-dashboard-container">
        {loader ? <div className="loader"><Spinner animation="grow" variant="primary" /></div> : null}
          <Card className="my-teachers my-teachers-list expand">          
            <div className="list-header">
              <Breadcrumb className="m-0">
                <Breadcrumb.Item onClick={() => navigate('/school/dashboard')}>
                  <MdHome /> Dashboard
                </Breadcrumb.Item>
                <Breadcrumb.Item active>All Teachers</Breadcrumb.Item>
              </Breadcrumb>
              <div className="filter-wrapper">
              </div>
              <div className="search">
                <InputGroup>
                  <Form.Control
                    placeholder="Search"
                    value={parentSearchTxtBoxValue}
                    onChange={(e) => onSearch(e)}
                  />
                  <InputGroup.Text>
                    <MdSearch />
                  </InputGroup.Text>
                </InputGroup>
                <Button
                  onClick={() => {
                    createTeacher();
                  }}
                >
                  Create Teacher
                </Button>
              </div>
            </div>
            <Card.Title className="p-0">
              <div
                className="expand"
                onClick={(e) => {
                  expandCard(e);
                }}
              >
                <MdOpenInFull />
              </div>
            </Card.Title>
            <Card.Body className="card-body">
              {
                data && data.length > 0 ? (
                  <TableData
                    columns={columns}
                    filterParams={filterParams}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    start={start}
                    setStart={setStart}
                    limit={limit}
                    setLimit={setLimit}
                    getTableItem={getTableItem}
                    totalCount={totalCount}
                    data={data}
                    parentSearchTxtBoxValue={parentSearchTxtBoxValue}
                    setParentSearchTxtBoxValue={setParentSearchTxtBoxValue}
                  />
                ) : (
                  <div className="no-data">
                    No teacher found
                  </div>
                )
              }
            </Card.Body>
          </Card>
          <AddTeacherModal
            type="teacherList"
            show={createTeacherModal}
            onHide={() => setCreateTeacherModal(false)}
            initialValues={initialValues}
            validationSchema={validationSchema}
            teacherUpdate={teacherUpdate}
            setTeacherUpdate={setTeacherUpdate}
            filterParams={filterParams}
            setFilterParams={setFilterParams}
            getTableItem={getTableItem}
            setStart={setStart}
            setLimit={setLimit}
            setParentSearchTxtBoxValue={setParentSearchTxtBoxValue}
            loader={loader}
            setLoader={setLoader}
          />
          <DeleteModal
            show={deleteModalShow}
            onHide={() => setDeleteModalShow(false)}
            rowData={rowData}
            loader={loader}
            setLoader={setLoader}
            filterParams={filterParams}
            getTableItem={getTableItem}
            setStart={setStart}
            setLimit={setLimit}
            setParentSearchTxtBoxValue={setParentSearchTxtBoxValue}
          />
        </div>
      </section>
    </>
  );
};

export default AllTeachersList;
