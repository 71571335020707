import { useDrop } from 'react-dnd';
import magnetPin from "../../../assets/images/characters/magnet_pin.png"
import spaceboyPin from "../../../assets/images/characters/spaceboy_pin.png";
import robotPin from "../../../assets/images/characters/robot_pin.png";
import lightbulbPin from "../../../assets/images/characters/lightbulb_pin.png";
import happyPin from "../../../assets/images/characters/happy_pin.png";
import { Popover } from 'antd';
import moment from 'moment';
import { Button } from 'react-bootstrap';
// import 'antd/dist/antd.css'

function DropItem(props) {

    const { item, onDragged, onSelectCharacter } = props;

    const [{ isOver, canDrop }, drop] = useDrop(() => ({
        accept: "quest",
        item: { id: 'quest' },
        drop: (data) => onDragged(data.id, item),
        collect: (monitor) => ({
            isOver: !!monitor.isOver(),
            canDrop: monitor.canDrop(),
        }),
    }));

    const getPopOver = (data) => {
        
        return (
            <div>
                <div><strong>Subject Name</strong> - {data.selectedQuestDetail.quest.subject.subjectName}</div>
                <div><strong>Grade Name</strong> - {data.selectedQuestDetail.quest.grade.gradeName}</div>
                <div><strong>Assigned Date</strong> - {moment(data.selectedQuestDetail.quest.assignedDate).format("DD-MM-YYYY")}</div>
                <div><strong>Expire Date</strong> - {moment(data.selectedQuestDetail.quest.deadLine).format("DD-MM-YYYY")}</div>
                <div><strong>Status</strong> - {data.selectedQuestDetail.quest.questStatus}</div>
                <div className='d-flex justify-content-center my-3'>
                    <Button variant="danger" onClick={() => onSelectCharacter(item)}>Unassign</Button>
                </div>
            </div>
        )
    }

    // const checkAvailable = (item) =>  {
    //     if (item.selectedQuestId)
    // }

    const getImageBlock = () => {
        console.log(canDrop, isOver)
        if (item.characterId === 1) {
            return (
                <>
                    {
                        item.selectedQuestId !== null ? (
                                <Popover content={getPopOver(item)} title={item.characterName} trigger="hover">
                                    <div className="char char-1 assigned" id="magnet" ref={drop}>
                                        <img src={magnetPin} id="magnet" alt="magnet" className="img-fluid" />
                                    </div>
                                </Popover>
                        ) : (
                                <div className={`char char-1 unassigned ${canDrop ? 'available' : ''}`} id="magnet" ref={drop}>
                                        <img src={magnetPin} id="magnet" alt="magnet" className="img-fluid" />
                                </div>
                        )
                    }
                </>
            )
        }
        else if (item.characterId === 2) {
            return (
                <>
                    {
                        item.selectedQuestId !== null ? (
                            <Popover content={getPopOver(item)} title={item.characterName} trigger="hover">
                                <div className="char char-2 assigned" id="spaceboy" ref={drop}>
                                    <img src={spaceboyPin} id="spaceboy" alt="spaceboy" className="img-fluid" />
                                </div>
                            </Popover>
                        ) : (
                                <div className={`char char-2 unassigned ${canDrop ? 'available' : ''}`} id="spaceboy" ref={drop}>
                                    <img src={spaceboyPin} id="spaceboy" alt="spaceboy" className="img-fluid" />
                                </div>
                        )
                    }
                </>
            )
        }
        else if (item.characterId === 3) {
            return (
                 <>
                    {
                        item.selectedQuestId !== null ? (
                            <Popover content={getPopOver(item)} title={item.characterName} trigger="hover">
                                <div className="char char-3 assigned" id="robot" ref={drop}>
                                    <img src={robotPin} id="robot" alt="robot" className="img-fluid" />
                                </div>
                            </Popover>
                        ) : (
                                <div className={`char char-3 unassigned ${canDrop ? 'available' : ''}`} id="robot" ref={drop}>
                                    <img src={robotPin} id="robot" alt="robot" className="img-fluid" />
                                </div>
                        )
                    }
                </>
            )
        }
        else if (item.characterId === 4) {
            return (
                <>
                    {
                        item.selectedQuestId !== null ? (
                            <Popover content={getPopOver(item)} title={item.characterName} trigger="hover">
                                <div className="char char-4 assigned" id="lightbulb" ref={drop}>
                                    <img src={lightbulbPin} id="lightbulb" alt="lightbulb" className="img-fluid" />
                                </div>
                            </Popover>
                        ) : (
                                <div className={`char char-4 unassigned ${canDrop ? 'available' : ''}`} id="lightbulb" ref={drop}>
                                    <img src={lightbulbPin} id="lightbulb" alt="lightbulb" className="img-fluid" />
                                </div>
                        )
                    }
                </>
            )
        }
        else if (item.characterId === 5) {
            return (
                <>
                    {
                        item.selectedQuestId !== null ? (
                            <Popover content={getPopOver(item)} title={item.characterName} trigger="hover">
                                <div className="char char-5 assigned" id="happy" ref={drop}>
                                    <img src={happyPin} id="happy" alt="happy" className="img-fluid" />
                                </div>
                            </Popover>
                        ) : (
                                <div className={`char char-5 unassigned ${canDrop ? 'available' : ''}`} id="happy" ref={drop}>
                                    <img src={happyPin} id="happy" alt="happy" className="img-fluid" />
                                </div>
                        )
                    }
                </>
            )
        }
        else {
            return null;
        }
    }

    return getImageBlock();
}

export default DropItem;
