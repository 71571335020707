import React, { useEffect, useState } from "react";
import {
  Breadcrumb,
  Card,
  ProgressBar,
  Spinner
} from "react-bootstrap";
import Header from "../../common/Header";
import { MdHome, MdClose } from "react-icons/md";
import { Scrollbars } from "react-custom-scrollbars-2";
import { useNavigate } from "react-router";
import MyStudentModal from "./MyReportModal";
import DeleteModal from "./DeleteModal";
import { EndpointService } from "../../../service/endpointService";
import HTTPService from "../../../service/HTTPService";
import { toast } from "react-toastify";
import { Schema } from "./Schema";
import { cypherService } from "../../../service/cypherService";
import TableData from "./Table";
import moment from "moment";

const MyReportDetailsList = () => {

  const [loader, setLoader] = useState(false);
  const [tableItems, setTableItems] = useState([]);
  const [data, setData] = useState([]);
  const [start, setStart] = useState(0);
  const [limit, setLimit] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [columns, setColumns] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    let reportDetails = cypherService.getSessionStorage('savedReportDetails') ? JSON.parse(cypherService.getSessionStorage('savedReportDetails')) : null;
    if (reportDetails && reportDetails != 'null') {
      setTableDetails(reportDetails);
    }
    else {
      getReportDetails();
    }
  }, []);

  const setTableDataValues = (elements, accessorList) => {
    let elementList = elements.split("~");
    let tableData = {};
    accessorList.map((item, index) => {
      if (item === "assigned" || item === "ended") {
        tableData[item] = elementList[index] === "-" ? elementList[index] : moment(elementList[index]).format("DD/MM/YYYY");
      }
      else if (item === "progress") {
        if (elementList[index] === "0") {
          tableData[item] = Number(elementList[index]);
        }
        else {
          let tempProgress = elementList[index].split("/");
          tableData[item] = (Number(tempProgress[0])/Number(tempProgress[1])) * 100;
        }
      }
      else {
        tableData[item] = elementList[index];
      }
    })
    return tableData;
  }

  const getProgressBar = (row) => {
    if (row.progress < 40) {
      return (
        <ProgressBar variant="danger" now={row.progress} />
      )
    }
    else if (row.progress >= 40 && row.progress < 80) {
      return (
        <ProgressBar variant="warning" now={row.progress} />
      )
    }
    else {
      return (
        <ProgressBar variant="success" now={row.progress} />
      )
    }
  }

  const setTableDetails = (reportItem) => {
    let tempColumnHeaders = [];
    let tableDataAccessor = [];
    let tempTableData = [];
    reportItem.columnHeaders && reportItem.columnHeaders.map((item) => {
      if (item.toLowerCase() !== "progress") {
        tempColumnHeaders.push({
          Header: item,
          accessor: item.toLowerCase(),
        })
      }
      else {
        tempColumnHeaders.push({
          Header: item,
          accessor: item.toLowerCase(),
          Cell: (row) => {
            return getProgressBar(row);
          }
        })
      }
      tableDataAccessor.push(item.toLowerCase())
    })
    setColumns([...tempColumnHeaders]);
    reportItem.reportData && reportItem.reportData.map((item) => {
      let tempData = setTableDataValues(item, tableDataAccessor)
      tempTableData.push(tempData);
    })
    setTableItems([...tempTableData]);
    setTotalCount(reportItem.listSize.totalResults);
    let tempData = [];
    tempTableData.map((item, index) => {
      if (index < 10) {
        tempData.push(item);
      }
    })
    setData([...tempData]);
  }

  const getReportDetails = () => {
    setLoader(true);
    let reportId = cypherService.getSessionStorage("selectedReportId");
    let apiUrl = EndpointService.reportActionUrl + `${reportId}/report`;
    HTTPService.get(apiUrl)
      .then((response) => {
        const reportItem = response.data;
        setLoader(false);
        let tempColumnHeaders = [];
        let tableDataAccessor = [];
        let tempTableData = [];
        if (response.status.success == "Success") {
          reportItem.columnHeaders && reportItem.columnHeaders.map((item) => {
            if (item.toLowerCase() !== "progress") {
              tempColumnHeaders.push({
                Header: item,
                accessor: item.toLowerCase(),
              })
            }
            else {
              tempColumnHeaders.push({
                Header: item,
                accessor: item.toLowerCase(),
                Cell: (row) => {
                  return getProgressBar(row);
                }
              })
            }
            tableDataAccessor.push(item.toLowerCase())
          })
          setColumns([...tempColumnHeaders]);
          reportItem.reportData && reportItem.reportData.map((item) => {
            let tempData = setTableDataValues(item, tableDataAccessor)
            tempTableData.push(tempData);
          })
          setTableItems([...tempTableData]);
          setTotalCount(reportItem.listSize.totalResults);
          let tempData = [];
          tempTableData.map((item, index) => {
            if (index < 10) {
              tempData.push(item);
            }
          })
          setData([...tempData]);
        }
        if (response.status.success == "Fail") {
          setColumns([]);
          setTableItems([]);
          setTotalCount(0);
        }
      })
      .catch((error) => {
        setLoader(false);
        throw error;
      });
  }

  return (
    <>
      <Header />
      <section className="main-container">
        <div className="dashboard-container">
        {loader ? <div className="loader"><Spinner animation="grow" variant="primary" /></div> : null}
          <Card className="my-quests my-quests-list expand">            
            <div className="list-header">
              <Breadcrumb className="m-0">
                <Breadcrumb.Item onClick={()=> navigate('/teacher/dashboard')}>
                  <MdHome /> Dashboard
                </Breadcrumb.Item>
                <Breadcrumb.Item onClick={() => navigate('/teacher/my-report')}>
                  {cypherService.getSessionStorage("selectedReportName")}
                </Breadcrumb.Item>
                <Breadcrumb.Item active>My Reports</Breadcrumb.Item>
              </Breadcrumb>
            </div>
            <Card.Title className="p-0">
              <div
                className="expand"
                onClick={() => navigate('/teacher/my-report')}
              >
                <MdClose />
              </div>
            </Card.Title>
            <Card.Body className="card-body">
              {
                data && data.length > 0 ? (
                  <TableData
                    columns={columns}
                    start={start}
                    setStart={setStart}
                    limit={limit}
                    setLimit={setLimit}
                    data={data}
                    setData={setData}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    tableItems={tableItems}
                    totalCount={totalCount}
                  />
                ) : (
                  <div className="no-data">
                    No report details found
                  </div>
                )
              }
            </Card.Body>
          </Card>
        </div>
      </section>
    </>
  );
};

export default MyReportDetailsList;
