import { ErrorMessage, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Logo from "../../assets/images/cloudnow-logo-blue.png";
import { cypherService } from "../../service/cypherService";
import { EndpointService } from "../../service/endpointService";
import HTTPService from "../../service/HTTPService";
import { Schema } from "./Schema";

const ForgotPassword = () => {

  let navigate = useNavigate();

  const [loader, setLoader] = useState(false);
  const [loginFailMsg, setLoginFailMsg] = useState("");
  const [loginSuccessMsg, setLoginSuccessMsg] = useState("");

  useEffect(() => {
    setLoginFailMsg("");
    setLoginSuccessMsg("");
  }, [])

  const onSubmit = (values) => {
    setLoader(true);
    let payLoad = {
      ...values,
      userDiscriminator: cypherService.getSessionStorage("discriminator"),
    };
    const apiUrl = EndpointService.forgotPwdUrl;
    HTTPService.post(apiUrl, payLoad)
      .then((response) => {
        if (response.status.success == "Success") {
          setLoader(false);
          setLoginSuccessMsg(response.status.message);
          setLoginFailMsg("");
        }
        if (response.status.success == "Fail") {
          setLoader(false);
          setLoginFailMsg(response.status.message)
        }
      })
      .catch((error) => {
        setLoader(false);
        setLoginFailMsg(error.response.data.status.message)
        throw error;
      });
  };

  return (
    <>
      <section className="login-container">
        <div className="logo">
          <img src={Logo} alt="logo" />
        </div>
        <div className="login-card">
          <header className="login-header">
            <h3>
              <span
                onClick={() => {
                  navigate("/");
                }}
              >
                {"<"}
              </span>
              RESET PASSWORD
            </h3>
            <p>
              A link to reset your password will be sent to the email ID below.
            </p>
          </header>
          <Formik
            initialValues={Schema.forgotInitialValues}
            validationSchema={Schema.forgotValidationSchema}
            onSubmit={onSubmit}
            enableReinitialize={true}
          >
            {({
              handleSubmit,
              errors,
              getFieldProps,
            }) => {
              return (
                <Form className="login-form" onSubmit={handleSubmit}>
                  {loginFailMsg && loginFailMsg.length > 0 ? <p className="error-msg text-center">{loginFailMsg}</p> : null}
                  {loginSuccessMsg && loginSuccessMsg.length > 0 ? <p className="text-blue text-center" style={{ color: "#4f42ff" }}>{loginSuccessMsg}</p> : null}
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Email address</Form.Label>
                    <Form.Control
                      type="email"
                      name="emailId"
                      placeholder="Enter email"
                      {...getFieldProps("emailId")}
                    />
                    <ErrorMessage
                      name="emailId"
                      component="div"
                      className="error-msg"
                    />
                  </Form.Group>
                  <div className="d-grid pt-3">
                    <Button type="submit" className="login btn-block login-btn">
                      {!loader ? "Continue" : "Loading..."}
                    </Button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </section>
    </>
  );
};

export default ForgotPassword;
