import { ErrorMessage, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Form, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import { EndpointService } from "../../../service/endpointService";
import HTTPService from "../../../service/HTTPService";
import { Schema } from "./Schema";

const TrueFalse = (props) => {

  const [initialValues, setIntitialValues] = useState();
  const [loader, setLoader] = useState(false);
  const [editLoader, setEditLoader] = useState(false);
  const [editQuestionDetails, setEditQuestionDetails] = useState(null);

  useEffect(() => {
    setIntitialValues({
      question: '',
      answer: ''
    })
  }, [])

  useEffect(() => {
    if (props.selectedQuestionDetail) {
      getEditQuestionDetail()
    }
    else {
      setIntitialValues({
        question: '',
        answer: ''
      })
    }
  }, [props.selectedQuestionDetail])

  const getEditQuestionDetail = () => {
    setEditLoader(true);
    let url = EndpointService.getQuestQuestionUrl + props.selectedQuestionDetail.questQuestionaireId;
    HTTPService.get(url).then((response) => {
      setEditLoader(false);
      if (response.status.success.toLowerCase() === 'success') {
        let tempQuestionDetails = response.data;
        setEditQuestionDetails(tempQuestionDetails);
        if (tempQuestionDetails) {
          let tempEditValue = {
            question: tempQuestionDetails.question,
            answer: ''
          }
          tempQuestionDetails.questQuestionaireOption.map((item) => {
            if (item.isCorrectAnswer) {
              tempEditValue.answer = item.option.toLowerCase();
            }
          })
          setIntitialValues({ ...tempEditValue })
        }
      }
    })
      .catch((error) => {
        setEditLoader(false);
        toast.error(error.response.data.status.message);
      })
  }

  const onSubmit = (values, { resetForm }) => {
    if (editQuestionDetails === null) {
      saveQuestion(values, resetForm);
    }
    else {
      updateQuestion(values, resetForm);
    }
  }

  const saveQuestion = (values, resetForm) => {
    setLoader(true);
    let url = EndpointService.saveQUestionaireUrl;
    let payload = {
      "quest": {
        questId: props.questId
      },
      "questQuestionaireOption": [
        {
          "option": "True",
          "displaySequence": 1,
          "isCorrectAnswer": values.answer === 'true' ? true : false
        },
        {
          "option": "False",
          "displaySequence": 2,
          "isCorrectAnswer": values.answer === 'false' ? true : false
        }
      ],
      "question": values.question,
      "questionType": "TF"
    }
    url = url + `?questQuestionaireDto=${encodeURIComponent(JSON.stringify(payload))}`
    HTTPService.post(url, payload).then((response) => {
      setLoader(false);
      if (response.status.success.toLowerCase() === 'success') {
        toast.success(response.status.message);
        resetForm();
        props.getQuestionList();
      }
    })
      .catch((error) => {
        setLoader(false);
        toast.error(error.response.data.status.message);
      })
  }

  const updateQuestion = (values, resetForm) => {
    setLoader(true);
    let url = EndpointService.saveQUestionaireUrl;
    let questionaireOption = [
      {
        "option": "True",
        "displaySequence": 1,
        "isCorrectAnswer": values.answer === 'true' ? true : false
      },
      {
        "option": "False",
        "displaySequence": 2,
        "isCorrectAnswer": values.answer === 'false' ? true : false
      }
    ];
    editQuestionDetails.questQuestionaireOption.map((item) => {
      questionaireOption.map((payloadOption) => {
        if (payloadOption.option.toLowerCase() === item.option.toLowerCase()) {
          payloadOption['questQuestionaireOptionId'] = item.questQuestionaireOptionId
        }
      })
    })
    let payload = {
      "quest": {
        questId: props.questId
      },
      "questQuestionaireOption": questionaireOption,
      "question": values.question,
      "questQuestionaireId": editQuestionDetails.questQuestionaireId,
      "questionType": "TF"
    }
    url = url + `?questQuestionaireDto=${encodeURIComponent(JSON.stringify(payload))}`
    HTTPService.put(url, payload).then((response) => {
      setLoader(false);
      if (response.status.success.toLowerCase() === 'success') {
        toast.success(response.status.message);
        resetForm();
        props.getQuestionList();
      }
    })
      .catch((error) => {
        setLoader(false);
        toast.error(error.response.data.status.message);
      })
  }

  const getButtonName = () => {
    if (editQuestionDetails === null) {
      return "Add Question"
    }
    else {
      return "Save Question"
    }
  }

  return (
    <div className="mcq-quest">
      {
        editLoader ? (
          <div className="loader-block">
            <Spinner animation="grow" variant="primary" />
          </div>
        ) : (
            <Formik
              initialValues={initialValues}
              validationSchema={Schema.trueOrFalseSchema}
              onSubmit={onSubmit}
              enableReinitialize={true}
            >
              {({
                handleSubmit,
                handleBlur,
                values,
                getFieldProps,
                handleChange,
              }) => {
                return (
                  <Form className="mcq-quest-form" onSubmit={handleSubmit} autoComplete="off">
                    <div className="question">
                      <Form.Group controlId="formQuestion">
                        <Form.Label className="quest-heading">Question</Form.Label>
                        <Form.Control name='question' type="text" disabled={props.questStatus !== 'Assign Quest'}
                          placeholder="Type a Question here" {...getFieldProps("question")} />
                        <ErrorMessage name='question' component="div" className="error-msg" />
                      </Form.Group>
                    </div>
                    <div className="answer px-0">
                      <Form.Group controlId="formAnswer">
                        <Form.Check
                          inline
                          value="true"
                          label="True"
                          checked={values && values.answer === 'true'}
                          disabled={props.questStatus !== 'Assign Quest'}
                          name="answer"
                          type="radio"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <Form.Check
                          inline
                          value="false"
                          checked={values && values.answer === 'false'}
                          disabled={props.questStatus !== 'Assign Quest'}
                          label="False"
                          name="answer"
                          type="radio"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <ErrorMessage name='answer' component="div" className="error-msg" />
                      </Form.Group>
                    </div>
                    <div className="text-end mt-5 pt-2">
                      <Button variant="primary" type='submit' disabled={loader|| props.questStatus !== 'Assign Quest'}>
                        {!loader ? getButtonName() : "Saving..."}
                      </Button>
                    </div>
                  </Form>
                )
              }}
            </Formik>
        )
      }
    </div>
  );
};

export default TrueFalse;
