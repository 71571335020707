import { ErrorMessage, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Logo from "../../assets/images/cloudnow-logo-blue.png";
import googleSignIn from "../../assets/images/login/google_sign_in.png";
import { cypherService } from "../../service/cypherService";
import { EndpointService } from "../../service/endpointService";
import HTTPService from "../../service/HTTPService";
import { Schema } from "./Schema";
import { IoEyeOutline, IoEyeOffOutline } from "react-icons/io5";

const Login = () => {
  let navigate = useNavigate();
  let discriminator = "Parent";
  const [loader, setLoader] = useState(false);
  const [parent, setParent] = useState(true);
  const [passwordType, setPasswordType] = useState("password");
  const [loginFailMsg, setLoginFailMsg] = useState("");

  const handleLoginType = (e) => {
    setLoginFailMsg("");
    let switchId = e.target.id;
    if (switchId == "switchTeacher") {
      setParent(false);
      discriminator = "Teacher";
      cypherService.setSessionStorage("discriminator", discriminator);
    } else {
      setParent(true);
      discriminator = "Parent";
      cypherService.setSessionStorage("discriminator", discriminator);
    }
  };

  useEffect(() => {
    setLoginFailMsg("");
    cypherService.setSessionStorage("discriminator", discriminator);
  }, []);

  const showPassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  const onSubmit = (values) => {
    setLoader(true);
    let discriminator = cypherService.getSessionStorage("discriminator");
    let payLoad = { ...values, userDiscriminator: discriminator };
    const apiUrl = EndpointService.loginUrl;
    HTTPService.post(apiUrl, payLoad)
      .then((response) => {
        const data = response.data;
        if (response.status.success == "Success") {
          setLoader(false);
          cypherService.setSessionStorage("appToken", response.token);
          if (data.userId) {
            cypherService.setSessionStorage("userId", data.userId);
            cypherService.setSessionStorage("createElement", null);
            if (data.isAdmin && data.isAdmin == "true") {
              navigate("/school/dashboard");
            } else {
              navigate("/teacher/dashboard");
            }
          } else {
            navigate("/parent/dashboard");
            cypherService.setSessionStorage("studentId", data.studentId);
          }
        }
        if (response.status.success == "Fail") {
          setLoader(false);
          setLoginFailMsg(response.status.message);
        }
      })
      .catch((error) => {
        setLoader(false);
        setLoginFailMsg(error.response.data.status.message);
        throw error;
      });
  };

  return (
    <>
      <section className="login-container">
        <div className="logo">
          <img src={Logo} alt="logo" />
        </div>
        <div className="login-card">
          <div className="switches-container">
            <input
              type="radio"
              id="switchParent"
              name="switchType"
              value="Parent"
              checked={parent}
              onChange={(e) => {
                handleLoginType(e);
              }}
            />
            <input
              type="radio"
              id="switchTeacher"
              name="switchType"
              value="Teacher"
              onChange={(e) => {
                handleLoginType(e);
              }}
            />
            <label htmlFor="switchParent">Parent</label>
            <label htmlFor="switchTeacher">Teacher</label>
            <div className="switch-wrapper">
              <div className="switch">
                <div>Parent</div>
                <div>Teacher</div>
              </div>
            </div>
          </div>
          <header className="login-header">
            <h3>Log in</h3>
          </header>
          <Formik
            initialValues={Schema.initialValues}
            validationSchema={Schema.validationSchema}
            onSubmit={onSubmit}
            enableReinitialize={true}
          >
            {({ handleSubmit, getFieldProps }) => {
              return (
                <Form className="login-form" onSubmit={handleSubmit}>
                  {loginFailMsg && loginFailMsg.length > 0 ? (
                    <p className="error-msg text-center">{loginFailMsg}</p>
                  ) : null}
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Email address</Form.Label>
                    <Form.Control
                      type="email"
                      name="emailId"
                      placeholder="Enter email"
                      {...getFieldProps("emailId")}
                    />
                    <ErrorMessage
                      name="emailId"
                      component="div"
                      className="error-msg"
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Password</Form.Label>
                    <div className="position-relative">
                      <Form.Control
                        type={passwordType}
                        name="password"
                        placeholder="Password"
                        {...getFieldProps("password")}
                      />
                      <span
                        className="icon"
                        onClick={() => {
                          showPassword();
                        }}
                      >
                        {passwordType === "password" ? (
                          <IoEyeOffOutline />
                        ) : (
                          <IoEyeOutline />
                        )}
                      </span>
                    </div>
                    <ErrorMessage
                      name="password"
                      component="div"
                      className="error-msg"
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicCheckbox">
                    <Button
                      variant="link"
                      onClick={() => {
                        navigate("/forgot-password");
                      }}
                    >
                      Forgot Password?
                    </Button>
                  </Form.Group>
                  <div className="d-grid pt-3 gap-3">
                    <Button type="submit" className="login-btn">
                      {!loader ? "Log in" : "Loading..."}
                    </Button>
                    <Button variant="light" className="login-btn">
                      <img src={googleSignIn} className="pe-1" />  
                      Sign in with google
                    </Button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </section>
    </>
  );
};

export default Login;
