import React from "react";
import {
  Card,
  ProgressBar,
} from "react-bootstrap";
import QuestBanner1 from "../../../assets/images/dashboard/quest-banner-1.png";

const ActiveQuests = (props) => {

  const { activeQuestList } = props;

  const getProgress = (value) => {
    let percent = 0;
    if (Number(value) !== 0) {
      let valuesList = value.attendedQuestions.split("/");
      percent = Math.round((Number(valuesList[0]) / Number(valuesList[1])) * 100);
    }
    if (percent < 40) {
      return (
        <ProgressBar variant="danger" now={percent} />
      )
    }
    else if (percent >= 40 && percent < 80) {
      return (
        <ProgressBar variant="warning" now={percent} />
      )
    }
    else {
      return (
        <ProgressBar variant="success" now={percent} />
      )
    }
  }

  return (
    <Card className="active-quests parent">
      <Card.Title>
        <span>Active Quests</span>
      </Card.Title>
      <Card.Body>
        <ul className="active-quests-list">
          {
            activeQuestList && activeQuestList.length > 0 && activeQuestList.map((item) => (
              <li className="list-item">
                <div className="quest-banner">
                  <img
                    src={QuestBanner1}
                    alt="quest-banner"
                    className="img-fluid"
                  />
                </div>
                <div className="quest-info">
                  <p className="quest-group-name">{item.questName}</p>
                  <div className="quest-status">
                    {getProgress(item)}
                    <span>{item.attendedQuestions}</span>
                  </div>
                </div>
              </li>
            ))
          }
        </ul>
      </Card.Body>
    </Card>
  );
};

export default ActiveQuests;
