import * as Yup from "yup";
let atleastOneSpecialCharRegex = new RegExp(
  "^(?=.*[a-z])(?=.*[!@#$%^&*])(?=.{8,})"
);
let bussinessEmail = new RegExp(
  "^[a-zA-Z0-9._%+-]+@(?!gmail.com)(?!yahoo.com)(?!hotmail.com)(?!yahoo.co.in)(?!aol.com)(?!live.com)(?!outlook.com)[a-zA-Z0-9_-]+.[a-zA-Z0-9-.]{2,61}$"
);
// Login
const validationSchema = Yup.object({
  questName: Yup.string().required("Quest name is required").nullable(),
  grade: Yup.object({
    gradeId: Yup.string().required("Grade is required").nullable(),
  }).notRequired(),
  subject: Yup.object({
    subjectId: Yup.string().required("Subject is required").nullable(),
  }).notRequired(),
  assignedDate: Yup.date().required("Date is required").nullable(),
  deadLine: Yup.date().required("Date is required").nullable(),
  questId: Yup.string().nullable(),
});
const initialValues = {
  questName: "",
  grade: {
    gradeId: "",
  },
  subject: {
    subjectId: "",
  },
  assignedDate: "",
  deadLine: "",
  questId: ""
};

export const Schema = {
  validationSchema,
  initialValues,
};
