import React from "react";
import { Button, Modal } from "react-bootstrap";
import magnet from "../../../assets/images/characters/magnet.png"
import spaceboy from "../../../assets/images/characters/spaceboy.png";
import robot from "../../../assets/images/characters/robot.png";
import lightbulb from "../../../assets/images/characters/lightbulb.png";
import happy from "../../../assets/images/characters/happy.png";

const AssignQuestToCharModal = (props) => {

  const { onHide, selectedQuest } = props;

  const getCharacterImage = () => {
    if (selectedQuest && selectedQuest.characterDetail.characterName.toLowerCase() === 'magnet') {
      return (
        <img className="character-img" src={magnet} alt='magnet' />
      )
    }
    else if (selectedQuest && selectedQuest.characterDetail.characterName.toLowerCase() === 'spaceboy') {
      return (
        <img className="character-img" src={spaceboy} alt='spaceboy' />
      )
    }
    else if (selectedQuest && selectedQuest.characterDetail.characterName.toLowerCase() === 'robot') {
      return (
        <img className="character-img" src={robot} alt='robot' />
      )
    }
    else if (selectedQuest && selectedQuest.characterDetail.characterName.toLowerCase() === 'lightbulb') {
      return (
        <img className="character-img" src={lightbulb} alt='lightbulb' />
      )
    }
    else if (selectedQuest && selectedQuest.characterDetail.characterName.toLowerCase() === 'happy') {
      return (
        <img className="character-img" src={happy} alt='happy' />
      )
    }
    else {
      return `${selectedQuest && selectedQuest.characterDetail.characterName} character`
    }
  }

  return (
    <Modal {...props} size="md" centered className="delete-modal">
      <Modal.Header>
        <Modal.Title>Assign Quest?</Modal.Title>
      </Modal.Header>
      <Modal.Body className="show-grid">
        <p>
          Do you want to assign <span>{selectedQuest ? selectedQuest.questDetail.subject.subjectName : ''}</span> subject to 
          <span>{getCharacterImage()} ?</span>
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-primary" onClick={props.onHide}>
          No
        </Button>
        <Button onClick={props.assignQuestToGame}>
          Yes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AssignQuestToCharModal;
