import { ErrorMessage, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";
import { cypherService } from "../../../service/cypherService";
import { EndpointService } from "../../../service/endpointService";
import HTTPService from "../../../service/HTTPService";

const MyStudentModal = (props) => {
  const { onHide, initialValues, validationSchema,
    studentUpdate, getTableItem, emailFromApi, setFilterParams,
    setParentSearchTxtBoxValue, setStart, setLimit, setLoader
  } = props;

  const [dateOfBirth, setDateOfBirth] = useState("");
  const [gradeList, setGradeList] = useState([]);

  useEffect(() => {
    getAllGrades();
  }, []);

  useEffect(() => {
    if (props.editDateOfBirth) {
      setDateOfBirth(new Date(props.editDateOfBirth))
    }
    if (!props.editDateOfBirth) {
      setDateOfBirth("")
    }
  }, [props.editDateOfBirth])

  const getAllGrades = () => {
    const apiUrl = EndpointService.getAllGrade;
    let tempFilterList = [];
    let tempGradeFilterList = [];
    let tempGradeIdList = [];
    HTTPService.get(apiUrl, null)
      .then((response) => {
        Object.entries(response.data).map((item) => {
          tempFilterList.push({
            gradeId: item[0],
            gradeName: item[1]
          })
        })
        tempFilterList && tempFilterList.map((item, index) => {
          if (!tempGradeIdList.includes(item.grade.gradeId)) {
            tempGradeFilterList.push(item.grade);
            tempGradeIdList.push(item.grade.gradeId)
          }
        })
        setGradeList(tempGradeFilterList);
      })
      .catch((error) => {
        setGradeList(tempFilterList);
        throw error;
      });
  }

  const formatDate = (date) => {
    let d = new Date(date);
    let day = "" + d.getDate();
    let month = "" + (d.getMonth() + 1);
    let year = d.getFullYear();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return [year, month, day].join("-");
  };

  const onSubmit = (values) => {
    setLoader(true);
    let getuserId = cypherService.getSessionStorage("userId")
    let payLoad = { ...values, userId: getuserId };
    if (emailFromApi) {
      if (emailFromApi == payLoad.emailId) {
        delete payLoad.emailId;
      }
    }
    if (!studentUpdate) {
      onHide();
      payLoad.studentId = "";
      const apiUrl = EndpointService.createStudent;
      HTTPService.post(apiUrl, payLoad)
        .then((response) => {
          if (response.status.success == "Success") {
            setLoader(false);
            toast.success(response.status.message);
            setParentSearchTxtBoxValue('');
            setStart(0);
            setLimit(10);
            let tempSelectedGrade = cypherService.getSessionStorage('selectedFilterGrade');
            setFilterParams(tempSelectedGrade);
            cypherService.setSessionStorage('createElement', 'student');
            getTableItem(tempSelectedGrade, '', 0, 10);
          }
          if (response.status.success == "Fail") {
            setLoader(false);
            toast.error(response.status.message);
          }
        })
        .catch((error) => {
          toast.error(error.response.data.status.message);
          throw error;
        });
    } else {
      const apiUrl = EndpointService.createStudent;
      HTTPService.put(apiUrl, payLoad)
        .then((response) => {
          if (response.status.success == "Success") {
            setLoader(false);
            toast.success(response.status.message);
            setParentSearchTxtBoxValue('');
            setStart(0);
            setLimit(10);
            let tempSelectedGrade = cypherService.getSessionStorage('selectedFilterGrade');
            setFilterParams(tempSelectedGrade);
            cypherService.setSessionStorage('createElement', 'student');
            getTableItem(tempSelectedGrade, '', 0, 10);
          }
          if (response.status.success == "Fail") {
            setLoader(false);
            toast.error(response.status.message);
          }
        })
        .catch((error) => {
          toast.error(error.response.data.status.message);
          throw error;
        });
    }
  };

  return (
    <Modal {...props} size="md" centered className="create-quest-modal">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        enableReinitialize={true}
      >
        {({
          handleSubmit,
          setFieldValue,
          values,
          errors,
          getFieldProps,
          handleChange,
        }) => {
          return (
            <Form className="login-form" onSubmit={handleSubmit}>
              <Modal.Header className="pt-3 pb-2">
                <Modal.Title>{!studentUpdate ? "Add" : "Update"} Student</Modal.Title>
              </Modal.Header>
              <Modal.Body className="show-grid">
                <Container>
                  <Row>
                    <Col xs={12} md={6}>
                      <Form.Group className="mb-4">
                        <Form.Label>First Name</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter the first name"
                          {...getFieldProps("firstName")}
                        />
                        <ErrorMessage
                          name="firstName"
                          component="div"
                          className="error-msg"
                        />
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={6}>
                      <Form.Group className="mb-4" >
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter the last name"
                          {...getFieldProps("lastName")}
                        />
                        <ErrorMessage
                          name="lastName"
                          component="div"
                          className="error-msg"
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12}>
                      <Form.Group className="mb-4" controlId="formEmailId">
                        <Form.Label>Email Id</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Please enter the email id"
                          {...getFieldProps("emailId")}
                        />
                        <ErrorMessage
                          name="emailId"
                          component="div"
                          className="error-msg"
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={6}>
                      <Form.Group className="mb-4">
                        <Form.Label>DOB</Form.Label>
                        <DatePicker
                          dateFormat="MM/dd/yyyy"
                          selected={dateOfBirth}
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          autoComplete="off"
                          className="form-control"
                          placeholderText="Pick a date"
                          onChange={(date) => {
                            setDateOfBirth(date);
                            setFieldValue("dateOfBirth", formatDate(date));
                          }}
                        />
                        <ErrorMessage
                          name="dateOfBirth"
                          component="div"
                          className="error-msg"
                        />
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={6}>
                      <Form.Group className="mb-4">
                        <Form.Label>Gender</Form.Label>
                        <Form.Select
                          onChange={(e) => {
                            setFieldValue("gender", e.target.value);
                          }}
                          {...getFieldProps("gender")}
                        >
                          <option value="" selected>Choose a gender</option>
                          <option value="Male">Male</option>
                          <option value="Female">Female</option>
                          <option value="Others">Others</option>
                        </Form.Select>
                        <ErrorMessage
                          name="gender"
                          component="div"
                          className="error-msg"
                        />
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={6}>
                      <Form.Group className="mb-4">
                        <Form.Label>Contact Number</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter contact number"
                          {...getFieldProps("contactNumber")}
                        />
                        <ErrorMessage
                          name="contactNumber"
                          component="div"
                          className="error-msg"
                        />
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={6}>
                      <Form.Group className="mb-4">
                        <Form.Label>Grade</Form.Label>
                        <Form.Select
                          onChange={(e) => {
                            setFieldValue("grade.gradeId", e.target.value);
                          }}
                          {...getFieldProps("grade.gradeId")}
                        >
                          <option value="" defaultValue>
                            Choose a grade
                          </option>
                          {gradeList && gradeList.length > 0
                            ? gradeList.map((opt, index) => (
                              <option key={index} value={opt.gradeId}>
                                {opt.gradeName}
                              </option>
                            ))
                            : null}
                        </Form.Select>
                        <ErrorMessage
                          name="grade.gradeId"
                          component="div"
                          className="error-msg"
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </Container>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="outline-primary" onClick={props.onHide}>
                  Cancel
                </Button>
                <Button type="submit">{!studentUpdate ? "Add" : "Update"} Student</Button>
              </Modal.Footer>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default MyStudentModal;
