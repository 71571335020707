import React, {useState} from "react";
import { Button, Card, Form, Spinner } from "react-bootstrap";
import { MdOpenInFull } from "react-icons/md";
import { useNavigate } from "react-router";
import { cypherService } from "../../../service/cypherService";
import AddTeacherModal from "../allTeachers/CreateTeacherModal";
import { Schema } from "../allTeachers/Schema";

const AllTeachers = (props) => {

  const { loader, filterParams, teacherList, filterList, getDashboardDetails, setLoader, setFilterParams } = props;
  const navigate = useNavigate();

  const [addTeacherModal, setAddTeacherModal] = useState(false);
  const [initialValues] = useState(Schema.initialValues);
  const [validationSchema] = useState(
    Schema.validationSchema
  );

  const expandCard = (e) => {
    let elem = e.target.parentElement.closest(".card");
    if (elem.classList.contains("expand")) {
      elem.classList.remove("expand");
    } else {
      elem.classList.add("expand");
    }
    setTimeout(() => {
      cypherService.setSessionStorage('expandTeacherCount', props.teacherCount);
      cypherService.setSessionStorage('expandTeacherList', JSON.stringify(props.teacherList));
      navigate("/school/all-teachers");
    }, 200);
  };


  const getTableData = () => {
    if (teacherList && teacherList.length === 0) {
      return (
        <div className="no-data">
          No teacher found
        </div>
      );
    }
    else {
      return (
        <ul className="activity-list">
          {
            teacherList && teacherList.map((item) => (
              <li className="list-item">
                <div className="teacher">
                  <h6>{item.name}</h6>
                  <p>{item.email}</p>
                  <p>{item.contactNo}</p>
                </div>
              </li>
            ))}
        </ul>
      );
    }
  };

  return (
    <>
      <Card className="my-teachers all-teachers">
        <Card.Title>
          <span>All teachers</span>
          <div
            className="expand"
            onClick={(e) => {
              expandCard(e);
            }}
          >
            <MdOpenInFull />
          </div>
      </Card.Title>
      <Card.Body>
        {
          loader.teacher ? (
            <div className="loader"><Spinner animation="grow" variant="primary" /></div>
          ) : (
            <>
              <ul className="activity-list">
                {getTableData()}
              </ul>
              <div className="d-grid">
                  <Button size="lg" onClick={() => setAddTeacherModal(true)}>
                    Add Teacher
                  </Button>
              </div>
            </>
          )
        }
      </Card.Body>
    </Card>
      {
        addTeacherModal && (
          <AddTeacherModal
            type="dashboard"
            filterParams={filterParams}
            setFilterParams={setFilterParams}
            getDashboardDetails={getDashboardDetails}
            loader={loader}
            setLoader={setLoader}
            show={addTeacherModal}
            onHide={() => setAddTeacherModal(false)}
            initialValues={initialValues}
            validationSchema={validationSchema}
          />
        )
      }
    </>
  );
};

export default AllTeachers;
