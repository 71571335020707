import React from "react";
import {
  useTable,
  usePagination,
  useGlobalFilter,
  useSortBy,
} from "react-table";
import { Form, Table } from "react-bootstrap";
import { MdOutlineArrowDropDown, MdOutlineArrowDropUp } from "react-icons/md";
import { Pagination } from 'antd';


// Our table component
function TableData({ columns, setCurrentPage, totalCount, currentPage, data, tableItems, start, setStart, limit, setLimit, setData }) {

  const customInsensitiveCompare = (
    rowA,
    rowB,
    columnId,
    desc
  ) => {
    const valueA = rowA.values[columnId].toString().toLowerCase();
    const valueB = rowB.values[columnId].toString().toLowerCase();

    if (desc) {
      return valueA.localeCompare(valueB) > 0 ? 1 : -1;
    }
    return valueB.localeCompare(valueA) > 0 ? -1 : 1;
  };

  const props = useTable(
    {
      columns,
      sortTypes: {
        alphanumeric: (row1, row2, columnName, desc) => {
          return customInsensitiveCompare(
            row1,
            row2,
            columnName,
            desc
          )
        },
      },
      data
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    setPageSize
  } = props;

  const pageChange = (pageIndex) => {
    let tempStart = (pageIndex * limit) - limit;
    setStart(tempStart);
    setCurrentPage(pageIndex);
    let tempData = [];
    tableItems.map((item, index) => {
      if (index >= tempStart && index < tempStart + limit) {
        tempData.push(item);
      }
    })
    setData([...tempData]);
  }

  const onPageSizeChange = (pageSize) => {
    setStart(0);
    setLimit(pageSize);
    setPageSize(pageSize);
    setCurrentPage(1);
    let tempData = [];
    tableItems.map((item, index) => {
      if (index >= 0 && index < 0 + pageSize) {
        tempData.push(item);
      }
    })
    setData([...tempData]);
  }

  const getHeaderSortIcon = (column) => {
    if (column.isSorted) {
      return (column.isSortedDesc ? <MdOutlineArrowDropDown className="sorting-icon up-arw" /> : <MdOutlineArrowDropUp className="sorting-icon down-arw" />)
    }
    else {
      return ""
    }
  }
  
  return (
    <>
      <Table responsive hover {...getTableProps()} className="homework-table">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}
                  <span>
                    {getHeaderSortIcon(column)}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr className="tablerow" {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </Table>
      <div className="d-flex align-items-center justify-content-end">
        <Pagination
          className="mx-5"
          current={currentPage}
          pageSize={limit}
          total={totalCount}
          onChange={(pageIndex) => pageChange(pageIndex)}
        />
        <Form.Select
          value={limit}
          onChange={(e) => onPageSizeChange(Number(e.target.value))}
          style={{ width: "auto" }}
        >
          {[10, 20, 30, 40, 50].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              {pageSize} / Page
            </option>
          ))}
        </Form.Select>
      </div>
    </>
  );
}

export default TableData;
