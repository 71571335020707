import React from "react";
import { Card, Dropdown, DropdownButton, Form, Spinner, Table } from "react-bootstrap";
import { MdOpenInFull } from "react-icons/md";
import { cypherService } from "../../../service/cypherService";

const Leaderboard = (props) => {

  const { loader, filterParams, leaderboardList, filterList, getDashboardDetails } = props;

  const expandCard = (e) => {
    let elem = e.target.parentElement.closest(".card");
    if (elem.classList.contains("expand")) {
      elem.classList.remove("expand");
    } else {
      elem.classList.add("expand");
    }
  };

  const filterStatusChange = (value) => {
    let tempFilterParams = filterParams
    if (filterParams.leaderboard !== value.gradeId) {
      tempFilterParams.leaderboard = value.gradeId;
      cypherService.setSessionStorage('selectedLeaderBoardFilterGrade', value.gradeId);
      getDashboardDetails(tempFilterParams, 'leaderboard');
    }
  }

  const getTableData = () => {
    if (leaderboardList && leaderboardList.length === 0) {
      return (
        <div className="no-data">
          No data found
        </div>
      );
    }
    else {
      return (
        <Table responsive className="homework-table dashboard-table">
          <thead>
            <tr>
              <th>#</th>
              <th>Student</th>
              <th>Quest</th>
              <th>Score (%)</th>
            </tr>
          </thead>
          <tbody>
            {
              leaderboardList && leaderboardList.map((item, index) => (
                <tr>
                  <td>{index + 1}</td>
                  <td>{item.student.firstName + (item.student.lastName ? (" " + item.student.lastName) : '')}</td>
                  <td>{item.quest.questName}</td>
                  <td>{item.scorePercentage}</td>
                </tr>
              ))
            }
          </tbody>
        </Table>
      )
    }
  }

  const getFilterName = () => {
    return filterList && filterList.map((item) => {
      if (filterParams.leaderboard == item.gradeId) {
        return item.gradeName
      }
    })
  }

  return (
    <Card className="leaderboard">
      <Card.Title>
        <span>Leaderboard</span>
        {/* <div
          className="expand"
          onClick={(e) => {
            expandCard(e);
          }}
        >
          <MdOpenInFull />
        </div> */}
        {/* Filter */}
        <DropdownButton
            id="dropdown-grade"
            size="sm"
            title={getFilterName()}
            variant="light"
            className="filter"
            align="end"            
          >
            {
              filterList && filterList.map((item) => (
                <Dropdown.Item onClick={() => filterStatusChange(item)}>
                  <Form.Check 
                    type="radio" 
                    aria-label="radio 1" 
                    value={item.gradeId} 
                    checked={item.gradeId == filterParams.leaderboard}
                  /> 
                  <span>{item.gradeName}</span>
                </Dropdown.Item>
              ))
            }           
          </DropdownButton>
      </Card.Title>
      <Card.Body>
        {
          loader.leaderboard ? (
            <div className="loader"><Spinner animation="grow" variant="primary" /></div>
          ) : (
              getTableData()
          )
        }
      </Card.Body>
    </Card>
  );
};

export default Leaderboard;
