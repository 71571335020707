import { ErrorMessage, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Badge, Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import { MdClose } from "react-icons/md";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { cypherService } from "../../../service/cypherService";
import { EndpointService } from "../../../service/endpointService";
import HTTPService from "../../../service/HTTPService";
import Select from 'react-select';

const MyReportModal = (props) => {

  const {
    onHide,
    initialValues,
    validationSchema,
    getReportsList,
    gradeId,
    setFilterParams,
    setSearchFilterValue,
    setStart,
    loader,
    rowData,
    setRowData,
    setLoader,
    setLimit,
  } = props;

  const navigate = useNavigate();

  const [questList, setQuestList] = useState([]);
  const [reportFieldsList, setReportfieldsList] = useState([]);
  const [defaultReportFieldList, setDefaultReportFieldList] = useState([]);
  const [selectedReportFields, setSelectedReportFields] = useState([]);
  const [submitFlag, setSubmitFlag] = useState(false);

  useEffect(() => {
    getQuestList();
    getReportFieldsList();
  }, []);

  const getReportFieldsList = () => {
    const apiUrl = EndpointService.getReportFieldsListUrl ;
    HTTPService.get(apiUrl, null)
      .then((response) => {
        if (response.data && response.data.length > 0) {
          if (rowData) {
            let tempReportField = response.data.map((item) => { return ({ label: item.displayName, value: item.reportColumnId }) });
            tempReportField.sort(sortByValue);
            let tempSelectedReportFields = [];
            let selectedIds = [];
            rowData.userReportColumnMap && rowData.userReportColumnMap.map((item) => {
              tempSelectedReportFields.push({ label: item.reportColumn.displayName, value: item.reportColumn.reportColumnId, userReportColumnMapId: item.userReportColumnMapId });
              selectedIds.push(item.reportColumn.reportColumnId);
              tempReportField.map((report) => {
                if (report.value === item.reportColumn.reportColumnId) {
                  report["userReportColumnMapId"] = item.userReportColumnMapId;
                }
              })
            })
            tempSelectedReportFields.sort(sortByValue);
            setSelectedReportFields([...tempSelectedReportFields]);
            let tempRemianingFields = [];
            tempReportField.map((item) => {
              if (!selectedIds.includes(item.value)) {
                tempRemianingFields.push(item);
              }
            })
            setDefaultReportFieldList([...tempRemianingFields]);
            setReportfieldsList([...tempReportField]);
          }
          else {
            let tempReportField = response.data.map((item) => { return ({ label: item.displayName, value: item.reportColumnId }) });
            setDefaultReportFieldList([...tempReportField]);
            setReportfieldsList([...tempReportField]);
          }
        }
      })
      .catch((error) => {
        throw error;
      })
  }

  const getQuestList = () => {
    const apiUrl = EndpointService.getReportQuestListUrl + `?gradeId=${gradeId}`;
    HTTPService.get(apiUrl, null)
      .then((response) => {
        setQuestList(response.data);
      })
      .catch((error) => {
        throw error;
      })
  }

  const setEditReportColumnPayload = (payLoad) => {
    let tempPayload = payLoad;
    let tempSelectedReportFields = selectedReportFields;
    let tempReportColumnList = defaultReportFieldList;
    tempSelectedReportFields.map((item) => {
      if (!Object.keys(item).includes('userReportColumnMapId')) {
        tempReportColumnList.map((report) => {
          if (Object.keys(report).includes('userReportColumnMapId')) {
            item["userReportColumnMapId"] = report["userReportColumnMapId"];
            delete report["userReportColumnMapId"];
          }
        })
      }
    })
    tempSelectedReportFields.map((item) => {
      let tempField = {
        "reportColumn": {
          "reportColumnId": item.value
        }
      }
      if (item.userReportColumnMapId) {
        tempField["userReportColumnMapId"] = item.userReportColumnMapId
      }
      tempPayload.userReportColumnMap.push(tempField )
    })
    return tempPayload;
  }

  const onSubmit = (values) => {
    setLoader(true);
    let userId = cypherService.getSessionStorage("userId");
    let payLoad = {
      "quest": {
        "questId": values.questName
      },
      "reportName": values.reportName,
      "user": {
        "userId": userId
      },
      "userReportColumnMap": []
    };
    if (selectedReportFields.length !== 0) {
      if (rowData === null) {
        selectedReportFields.map((item) => {
          payLoad.userReportColumnMap.push({ "reportColumn" :{ "reportColumnId": item.value }});
        })
        onHide();
        const apiUrl = EndpointService.reportActionUrl;
        HTTPService.post(apiUrl, payLoad)
          .then((response) => {
            if (response.status.success == "Success") {
              setLoader(false);
              toast.success(response.status.message);
              setSearchFilterValue("");
              setStart(0);
              setLimit(10);
              setRowData(null);
              let tempSelectedGrade = cypherService.getSessionStorage("selectedReportFilterGrade");
              setFilterParams(tempSelectedGrade);
              cypherService.setSessionStorage('createElement', 'report');
              cypherService.setSessionStorage('expandReportCount', null);
              cypherService.setSessionStorage('expandReportList', null);
              cypherService.setSessionStorage("savedReportDetails", JSON.stringify(response.data));
              navigate('/teacher/my-report-details')
              getReportsList(tempSelectedGrade, "", 0, 10);
            }
            if (response.status.success == "Fail") {
              setLoader(false);
              toast.error(response.status.message);
            }
          })
          .catch((error) => {
            toast.error(error.response.data.status.message);
            throw error;
          });
      } else {
        onHide();
        let editPayload = setEditReportColumnPayload(payLoad);
        editPayload['userReportId'] = rowData.userReportId;
        const apiUrl = EndpointService.reportActionUrl;
        HTTPService.put(apiUrl, editPayload)
          .then((response) => {
            if (response.status.success == "Success") {
              setLoader(false);
              toast.success(response.status.message);
              setSearchFilterValue("");
              setStart(0);
              setLimit(10);
              setRowData(null);
              let tempSelectedGrade = cypherService.getSessionStorage("selectedReportFilterGrade");
              setFilterParams(tempSelectedGrade);
              cypherService.setSessionStorage('createElement', 'report');
              cypherService.setSessionStorage('expandReportCount', null);
              cypherService.setSessionStorage('expandReportList', null);
              getReportsList(tempSelectedGrade, "", 0, 10);
            }
            if (response.status.success == "Fail") {
              setLoader(false);
              toast.error(response.status.message);
            }
          })
          .catch((error) => {
            toast.error(error.response.data.status.message);
            throw error;
          });
      }
    }
  };

  const sortByValue = (valueOne, valueTwo) => {
    if (valueOne.value < valueTwo.value) {
      return -1;
    }
    if (valueOne.value > valueTwo.value) {
      return 1;
    }
    return 0;
  }

  const handleChangeSelect = (event) => {
    let tempSelectedValues = selectedReportFields;
    tempSelectedValues.push(event[0]);
    tempSelectedValues.sort(sortByValue);
    setSelectedReportFields([...tempSelectedValues]);
    let tempDefaultReportFieldList = [];
    defaultReportFieldList && defaultReportFieldList.map((item) => {
      if (item.value !== event[0].value) {
        tempDefaultReportFieldList.push(item)
      }
    })
    tempDefaultReportFieldList.sort(sortByValue);
    setDefaultReportFieldList([...tempDefaultReportFieldList])
  }

  const removeSelectedField = (element, index) => {
    let tempSelectedValues = selectedReportFields;
    tempSelectedValues.splice(index,  1);
    tempSelectedValues.sort(sortByValue);
    setSelectedReportFields([...tempSelectedValues]);
    let tempDefaultReportFieldList = defaultReportFieldList;
    reportFieldsList && reportFieldsList.map((item) => {
      if (item.value === element.value) {
        tempDefaultReportFieldList.push(item)
      }
    })
    tempDefaultReportFieldList.sort(sortByValue);
    setDefaultReportFieldList([...tempDefaultReportFieldList])
  }

  return (
    <Modal {...props} size="md" centered className="create-quest-modal">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        enableReinitialize={true}
      >
        {({
          handleSubmit,
          values,
          getFieldProps,
        }) => {
          return (
            <Form className="login-form" onSubmit={handleSubmit}>
              <Modal.Header className="pt-3 pb-2">
                <Modal.Title>Create a report</Modal.Title>
              </Modal.Header>
              <Modal.Body className="show-grid">
                <Container>
                  <Row>
                    <Col xs={12} md={12}>
                      <Form.Group className="mb-4">
                        <Form.Label>Report name</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter the report name"
                          {...getFieldProps("reportName")}
                        />
                        <ErrorMessage
                          name="reportName"
                          component="div"
                          className="error-msg"
                        />
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={12}>
                      <Form.Group className="mb-4">
                        <Form.Label>Quest Name</Form.Label>
                        <Form.Select
                          {...getFieldProps("questName")}
                        >
                          <option value="" defaultValue>
                            Choose a quest
                          </option>
                          {
                            questList && questList.length > 0 ? (
                              questList.map((opt, index) => (
                                <option value={opt.questId}>
                                  {opt.questName}
                                </option>
                              ))
                            ) : null
                          }
                        </Form.Select>
                        <ErrorMessage
                          name="questName"
                          component="div"
                          className="error-msg"
                        />
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={12}>
                      <Form.Group className="mb-4">
                        <Form.Label>Report Fields</Form.Label>
                        <Select
                          isMulti
                          value={[]}
                          placeholder="Select Report Fields"
                          options={defaultReportFieldList}
                          onChange={(event) => {
                            handleChangeSelect(event)
                          }}
                          onBlur={() => setSubmitFlag(true)}
                        />
                        <div className="error-msg">{submitFlag && selectedReportFields.length === 0 ? 'Report Field is required' : ''}</div>
                        <div className="fields">
                          {
                            selectedReportFields && selectedReportFields.map((item, index) => (
                              <Badge pill bg="light" text="dark">
                                <span>{item.label}</span>
                                <span className="close" onClick={() => removeSelectedField(item, index)}><MdClose /></span>
                              </Badge>
                            ))
                          }
                        </div>
                      </Form.Group>
                    </Col>
                  </Row>
                </Container>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="outline-primary" onClick={props.onHide}>
                  Cancel
                </Button>
                <Button type="submit" onClick={() => setSubmitFlag(true)}>
                  {rowData ? "Update Report" : "Create Report"}
                </Button>
              </Modal.Footer>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default MyReportModal;
