import React, { useEffect, useState } from "react";
import {
  Breadcrumb,
  Button,
  Card,
  Dropdown,
  DropdownButton,
  Form,
  InputGroup,
  Spinner,
} from "react-bootstrap";
import Header from "../../common/Header";
import {
  MdDeleteOutline,
  MdEdit,
  MdHome,
  MdOpenInFull,
  MdSearch,
} from "react-icons/md";
import { TbFileDownload } from "react-icons/tb"
import { useNavigate } from "react-router";
import DeleteModal from "./DeleteModal";
import { EndpointService } from "../../../service/endpointService";
import HTTPService from "../../../service/HTTPService";
import { toast } from "react-toastify";
import { Schema } from "./Schema";
import { cypherService } from "../../../service/cypherService";
import MyReportModal from "./MyReportModal";
import { debounce } from "lodash";
import { Pagination } from 'antd';
import environment from "../../../environments/environment";


const MyReportList = () => {

  const [addReportModal, setAddReportModal] = useState(false);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [loader, setLoader] = useState(false);
  const [searchFilterValue, setSearchFilterValue] = useState("");
  const [rowData, setRowData] = useState(null);
  const [reportsList, setReportsList] = useState([]);
  const [initialValues, setInitialValues] = useState(Schema.initialValues);
  const [filterList, setFilterList] = useState([]);
  const [start, setStart] = useState(0);
  const [limit, setLimit] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [filterParams, setFilterParams] = useState(null);
  const [validationSchema] = useState(
    Schema.validationSchema
  );

  const navigate = useNavigate();

  useEffect(() => {
    cypherService.setSessionStorage('savedReportDetails', null)
    getAllGrades();
  }, []);

  const getAllGrades = () => {
    const apiUrl = EndpointService.getAllGrade;
    let tempFilterList = [];
    HTTPService.get(apiUrl, null)
      .then((response) => {
        Object.entries(response.data).map((item) => {
          tempFilterList.push({
            gradeId: item[0],
            gradeName: item[1]
          })
        })
        setFilterList([...tempFilterList]);
        setGradeFilterList(tempFilterList);
      })
      .catch((error) => {
        setGradeFilterList(tempFilterList);
        throw error;
      });
  }


  const setGradeFilterList = (temp_FilterList) => {
    let tempFilterList = temp_FilterList;
    let tempGradeFilterList = [];
    let tempGradeIdList = [];
    tempFilterList &&
      tempFilterList.map((item, index) => {
        if (!tempGradeIdList.includes(item.gradeId)) {
          tempGradeFilterList.push(item);
          tempGradeIdList.push(item.gradeId);
        }
      });
    setFilterList([...tempGradeFilterList]);
    let tempSelectedGrade = cypherService.getSessionStorage("selectedReportFilterGrade");
    setFilterParams(tempSelectedGrade);
    let tempReportCount = cypherService.getSessionStorage('expandReportCount') ? cypherService.getSessionStorage('expandReportCount') : null;
    let tempReportList = cypherService.getSessionStorage('expandReportList') ? JSON.parse(cypherService.getSessionStorage('expandReportList')) : null;
    if (tempReportCount && tempReportList) {
      setReportsList(tempReportList);
      setTotalCount(tempReportCount);
    }
    else {
      getReportsList(tempSelectedGrade, searchFilterValue, start, limit);
    }
  };

  const onSearch = debounce((event) => {
    setSearchFilterValue(event.target.value);
    getReportsList(filterParams, event.target.value, start, limit);
  }, 1000);

  const getReportsList = (tempFilterParams, searchText, tempStart, tempLimit) => {
    setLoader(true);
    let userId = cypherService.getSessionStorage('userId');
    let apiUrl = EndpointService.getReportsListUrl + `${userId}/range` + `?grade-id=${tempFilterParams}&start=${tempStart}&limit=${tempLimit}`;
    if (searchText !== "") {
      apiUrl = apiUrl + `&search=${searchText}`;
    }
    HTTPService.get(apiUrl, null)
      .then((response) => {
        const items = response.data;
        setReportsList(items ? items : []);
        if (response.status.success == "Success") {
          setLoader(false);
          setTotalCount(response.totalResults);
          cypherService.setSessionStorage('expandReportCount', response.totalResults);
          cypherService.setSessionStorage('expandReportList', JSON.stringify(response.data));
        }
        if (response.status.success == "Fail") {
          setLoader(false);
        }
      })
      .catch((error) => {
        setLoader(false);
        throw error;
      });
  }

  const filterStatusChange = (value) => {
    let tempFilterParams = filterParams;
    if (tempFilterParams !== value.gradeId) {
      tempFilterParams = value.gradeId;
    }
    setFilterParams(tempFilterParams);
    getReportsList(tempFilterParams, searchFilterValue, start, limit);
  };

  const expandCard = (e) => {
    let elem = e.target.parentElement.closest(".card");
    if (elem.classList.contains("expand")) {
      elem.classList.remove("expand");
    } else {
      elem.classList.add("expand");
    }
    setTimeout(() => {
      navigate("/teacher/dashboard");
    }, 200);
  }

  const createReport = () => {
    setAddReportModal(true);
    setInitialValues({...initialValues});
  };

  const deleteReport = (row, event) => {
    event.stopPropagation();
    setRowData(row);
    setDeleteModalShow(true);
  }

  const editReport = (row, event) => {
    event.stopPropagation();
    let reportId = row.userReportId;
    let apiUrl = EndpointService.reportActionUrl + reportId;
    HTTPService.get(apiUrl, null)
      .then((response) => {
        const data = response.data;
        if (response.status.success == "Success") {
          setInitialValues({
            reportName: data.reportName,
            questName: data.quest.questId,
          });
          setAddReportModal(true);
          setRowData(data);
          setAddReportModal(true);
        }
        if (response.status.success == "Fail") {
          toast.error(response.status.message);
        }
      })
      .catch((error) => {
        toast.error(error.response.data.status.message);
        throw error;
      });
  };

  const getFilterName = () => {
    return (
      filterList &&
      filterList.map((item) => {
        if (filterParams.toString() === item.gradeId.toString()) {
          return item.gradeName;
        }
      })
    );
  };

  const pageChange = (pageIndex) => {
    let tempStart = (pageIndex * limit) - limit;
    setStart(tempStart);
    setCurrentPage(pageIndex);
    getReportsList(filterParams, searchFilterValue, tempStart, limit);
  }

  const onPageSizeChange = (pageSize) => {
    setStart(0);
    setLimit(pageSize);
    setCurrentPage(1);
    getReportsList(filterParams, searchFilterValue, 0, pageSize);
  }

  const viewReportDetails = (element) => {    
    cypherService.setSessionStorage('savedReportDetails', null);
    cypherService.setSessionStorage("selectedReportName", element.reportName);
    cypherService.setSessionStorage("selectedReportId", element.userReportId);
    navigate("/teacher/my-report-details");
  }

  const downloadFile = (element, event) => {
    event.stopPropagation();   
    let reportId = element.userReportId;
    let apiUrl = EndpointService.downloadUserReport + '?userReportId=' + reportId;
    window.location.href = environment.baseUrl + apiUrl;
  }

  return (
    <>
      <Header />
      <section className="main-container">
        <div className="dashboard-container">
          {loader ? (
            <div className="loader">
              <Spinner animation="grow" variant="primary" />
            </div>
          ) : null}
          <Card className="my-report my-report-list expand">
            <div className="list-header">
              <Breadcrumb className="m-0">
                <Breadcrumb.Item onClick={() => navigate("/teacher/dashboard")}>
                  <MdHome /> Dashboard
                </Breadcrumb.Item>
                <Breadcrumb.Item active>My Reports</Breadcrumb.Item>
              </Breadcrumb>
              <div className="filter-wrapper">
                <DropdownButton
                  id="dropdown-grade"
                  size="sm"
                  title={getFilterName()}
                  variant="light"
                  className="filter"
                >
                  {filterList &&
                    filterList.map((item) => (
                      <Dropdown.Item onClick={() => filterStatusChange(item)}>
                        <Form.Check
                          type="radio"
                          aria-label="radio 1"
                          value={item.gradeId}
                          checked={item.gradeId.toString() === filterParams.toString()}
                        />
                        <span>{item.gradeName}</span>
                      </Dropdown.Item>
                    ))}
                </DropdownButton>
              </div>
              <div className="search">
                <InputGroup>
                  <Form.Control
                    placeholder="Search"
                    aria-label="Search"
                    aria-describedby="search"
                    onChange={(e) => onSearch(e)}
                  />
                  <InputGroup.Text>
                    <MdSearch />
                  </InputGroup.Text>
                </InputGroup>
                <Button onClick={() => createReport()}>Create Report</Button>
              </div>
            </div>
            <Card.Title className="p-0">
              <div
                className="expand"
                onClick={(e) => {
                  expandCard(e);
                }}
              >
                <MdOpenInFull />
              </div>
            </Card.Title>
            <Card.Body className="card-body">     
              <div className="saved-report">
              <h4>Saved Reports</h4>
                {
                  reportsList && reportsList.length > 0 ? (
                    <ul className="report-list">
                      {
                        reportsList && reportsList.map((item) => (
                          <li className="list-item" onClick={() => viewReportDetails(item)}>
                            <h5 className="report-name">{item.reportName}</h5>
                            <h6 className="grade">{item.quest.grade.gradeName}</h6>
                            <div className="action">
                              <span className="icon" onClick={(event) => downloadFile(item, event)}>
                                <TbFileDownload />
                              </span>
                              <span className="icon" onClick={(event) => editReport(item, event)}>
                                <MdEdit />
                              </span>
                              <span className="icon" onClick={(event) => deleteReport(item, event)}>
                                <MdDeleteOutline />
                              </span>
                            </div>
                          </li>
                        ))
                      }
                    </ul>
                  ) : (
                      <div className="no-data">
                        No reports found
                      </div>
                  )
                }
              </div>
            </Card.Body>
            <div className="d-flex align-items-center justify-content-end me-5 mb-5">
              <Pagination
                className="mx-5"
                current={currentPage}
                total={totalCount}
                onChange={(pageIndex) => pageChange(pageIndex)}
              />
              <Form.Select
                value={limit}
                onChange={(e) => onPageSizeChange(Number(e.target.value))}
                style={{ width: "auto" }}
              >
                {[10, 20, 30, 40, 50].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    {pageSize} / Page
                  </option>
                ))}
              </Form.Select>
            </div>
          </Card>
          {
            addReportModal && (
              <MyReportModal
                rowData={rowData}
                setRowData={setRowData}
                loader={loader}
                setLoader={setLoader}
                show={addReportModal}
                onHide={() =>{setAddReportModal(false); setRowData(null)}}
                gradeId={filterParams}
                initialValues={initialValues}
                validationSchema={validationSchema}
                setFilterParams={setFilterParams}
                getReportsList={getReportsList}
                setStart={setStart}
                setLimit={setLimit}
                setSearchFilterValue={setSearchFilterValue}
              />
            )
          }
          <DeleteModal
            loader={loader}
            setLoader={setLoader}
            show={deleteModalShow}
            onHide={() => {setDeleteModalShow(false); setRowData(null)}}
            rowData={rowData}
            setRowData={setRowData}
            getReportsList={getReportsList}
            setFilterParams={setFilterParams}
            setStart={setStart}
            setLimit={setLimit}
            setSearchFilterValue={setSearchFilterValue}
          />
        </div>
      </section>
    </>
  );
};

export default MyReportList;
