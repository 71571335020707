import { ErrorMessage, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from "react-router";
import { cypherService } from "../../../service/cypherService";
import { EndpointService } from "../../../service/endpointService";
import HTTPService from "../../../service/HTTPService";
import { toast } from "react-toastify";

const CreateQuestModal = (props) => {

  const [assignedDate, setAssignedDate] = useState("");
  const [deadlineDate, setDeadlineDate] = useState("");
  const [gradeList, setGradeList] = useState([]);
  const [subjectList, setSubjectList] = useState([]);  
  const navigate = useNavigate();

  const { onHide, initialValues, validationSchema, questUpdate, getTableItem, setFilterParams, 
    setParentSearchTxtBoxValue, setStart, setLimit, loader, setLoader, type, getDashboardDetails, filterParams } = props;

  useEffect(() => {
      getAllGrades();
      getAllSubjects();
  }, []);

  useEffect(() => {
    if ( props.editAssignDate ) {
      setAssignedDate(new Date(props.editAssignDate))
    }
    if ( !props.editAssignDate ) {
      setAssignedDate("")
    }
  }, [props.editAssignDate])

  useEffect(() => {
    if ( props.editDeadLineDate ) {
      setDeadlineDate(new Date(props.editDeadLineDate))
    }
    if ( !props.editDeadLineDate ) {
      setDeadlineDate("")
    }
  }, [props.editDeadLineDate])

  const getAllGrades = () => {
    const apiUrl = EndpointService.getAllGrade;
    HTTPService.get(apiUrl, null)
      .then((response) => {
        let tempFilterList = [];
        let tempGradeFilterList = [];
        let tempGradeIdList = [];
        Object.entries(response.data).map((item) => {
          tempFilterList.push({
            gradeId: item[0],
            gradeName: item[1]
          })
        })
        tempFilterList && tempFilterList.map((item, index) => {
          if (!tempGradeIdList.includes(item.gradeId)) {
            tempGradeFilterList.push(item);
            tempGradeIdList.push(item.gradeId)
          }
        })
        setGradeList([...tempGradeFilterList]);
      })
      .catch((error) => {
        setGradeList([]);
        throw error;
      });
  }

  const getAllSubjects = () => {
    const apiUrl = EndpointService.getAllSubject;
    HTTPService.get(apiUrl, null)
      .then((response) => {
        let tempFilterList = [];
        let tempSubjectFilterList = [];
        let tempSubjectIdList = [];
        Object.entries(response.data).map((item) => {
          tempFilterList.push({
            subjectId: item[0],
            subjectName: item[1]
          })
        })
        tempFilterList && tempFilterList.map((item, index) => {
          if (!tempSubjectIdList.includes(item.subjectId)) {
            tempSubjectFilterList.push(item);
            tempSubjectIdList.push(item.subjectId)
          }
        })
        setSubjectList([...tempSubjectFilterList]);
      })
      .catch((error) => {
        setSubjectList([]);
        throw error;
      });
  }

  const formatDate = (date) => {
    let d = new Date(date);
    let day = '' + d.getDate();
    let month = '' + (d.getMonth() + 1);
    let year = d.getFullYear();
    if(month.length < 2) 
      month = "0" + month;
    
    if(day.length < 2) 
      day = "0" + day;
    
    return [year, month, day].join('-')
  }

  const onSubmit = (values) => {
    if (type !== 'dashboard') {
      onSubmitFromQuestList(values);
    }
    else {
     onSubmitFromDashboard(values);
    }
  }

  const onSubmitFromQuestList = (values) => {
    onHide();
    setLoader(true);  
    let payLoad = values;    
    if(!questUpdate) {
      payLoad.questId = "";
      const apiUrl = EndpointService.createQuest;
      HTTPService.post(apiUrl, payLoad)
      .then((response) => {
        if (response.status.success == "Success") {          
          setLoader(false);
          toast.success(response.status.message);
          setParentSearchTxtBoxValue('');
          setStart(0);
          setLimit(10);
          let tempSelectedGrade = cypherService.getSessionStorage('selectedFilterGrade');
          let tempSelectedGradeStatus = cypherService.getSessionStorage('selectedFilterGradeStatus');
          let tempFilterParams = {
            "quest": tempSelectedGrade,
            "questStatus": tempSelectedGradeStatus
          }
          setFilterParams({ ...tempFilterParams });
          cypherService.setSessionStorage('createElement', 'quest');
          cypherService.setSessionStorage("selectedQuestId", response.data.questId);
          cypherService.setSessionStorage("expandQuestCount", null);
          cypherService.setSessionStorage("expandQuestList", null);
          navigate('/teacher/create-questions');
          getTableItem(tempFilterParams, '', 0, 10);
        }
        if (response.status.success == "Fail") {
          setLoader(false);
          toast.error(response.status.message);
        }
      })
      .catch((error) => {
        setLoader(false);
        toast.error(error.response.data.status.message);
        throw error;
      });
    } else {    
      const apiUrl = EndpointService.createQuest;
      HTTPService.put(apiUrl, payLoad)
      .then((response) => {
        if (response.status.success == "Success") {          
          setLoader(false);
          toast.success(response.status.message);
          setParentSearchTxtBoxValue('');
          setStart(0);
          setLimit(10);
          let tempSelectedGrade = cypherService.getSessionStorage('selectedFilterGrade');
          let tempSelectedGradeStatus = cypherService.getSessionStorage('selectedFilterGradeStatus');
          let tempFilterParams = {
            "quest": tempSelectedGrade,
            "questStatus": tempSelectedGradeStatus
          }
          setFilterParams({ ...tempFilterParams });
          cypherService.setSessionStorage('createElement', 'quest');
          cypherService.setSessionStorage("expandQuestCount", null);
          cypherService.setSessionStorage("expandQuestList", null);
          getTableItem(tempFilterParams, '', 0, 10);
        }
        if (response.status.success == "Fail") {
          setLoader(false);
          toast.error(response.status.message);
        }
      })
      .catch((error) => {
        setLoader(false);
        toast.error(error.response.data.status.message);
        throw error;
      });
    }
  };

  const onSubmitFromDashboard = (values) => {
    onHide();
    let tempLoader = loader;
    tempLoader.quest = true;
    setLoader({ ...tempLoader });
    let payLoad = values;
    payLoad.questId = "";
    const apiUrl = EndpointService.createQuest;
    HTTPService.post(apiUrl, payLoad)
      .then((response) => {
        if (response.status.success == "Success") {
          toast.success(response.status.message);
          cypherService.setSessionStorage("selectedQuestId", response.data.questId);
          navigate('/teacher/create-questions');
          getDashboardDetails(filterParams, 'quest');
        }
        if (response.status.success == "Fail") {
          let tempLoader = loader;
          tempLoader.quest = false;
          setLoader({ ...tempLoader });
          toast.error(response.status.message);
        }
      })
      .catch((error) => {
        let tempLoader = loader;
        tempLoader.quest = false;
        setLoader({ ...tempLoader });
        toast.error(error.response.data.status.message);
        throw error;
      });
  }

  return (
    <Modal {...props} size="md" centered className="create-quest-modal">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        enableReinitialize={true}
      >
        {({
          handleSubmit,
          setFieldValue,
          values,
          errors,
          getFieldProps,
          handleChange,
        }) => {
          return (
            <Form className="login-form" onSubmit={handleSubmit}>
              <Modal.Header>
                <Modal.Title>{!questUpdate ? "Create" : "Update"} a quest</Modal.Title>
              </Modal.Header>
              <Modal.Body className="show-grid">
                <Container>
                  <Row>
                    <Col xs={12}>
                      <Form.Group className="mb-4">
                        <Form.Label>Quest name</Form.Label>
                        <Form.Control
                          type="text"
                          name="questName"
                          placeholder="Enter the name of your quest"
                          {...getFieldProps("questName")}
                        />
                        <ErrorMessage
                          name="questName"
                          component="div"
                          className="error-msg"
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={6}>
                      <Form.Group className="mb-4">
                        <Form.Label>Grade</Form.Label>
                        <Form.Select
                          onChange={(e) => {
                            setFieldValue("grade.gradeId", e.target.value);
                          }}
                          {...getFieldProps("grade.gradeId")}
                        >
                          <option value="" defaultValue>
                            Choose a grade
                          </option>
                          {gradeList && gradeList.length > 0
                            ? gradeList.map((opt, index) => (
                                <option value={opt.gradeId}>
                                  {opt.gradeName}
                                </option>
                              ))
                            : null}
                        </Form.Select>
                        <ErrorMessage
                          name="grade.gradeId"
                          component="div"
                          className="error-msg"
                        />
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={6}>
                      <Form.Group className="mb-4">
                        <Form.Label>Subject</Form.Label>
                        <Form.Select
                          onChange={(e) => {
                            setFieldValue("subject.subjectId", e.target.value);
                          }}
                          {...getFieldProps("subject.subjectId")}
                        >
                          <option value="" defaultValue>
                            Choose a subject
                          </option>
                          {subjectList && subjectList.length > 0
                            ? subjectList.map((opt, index) => (
                                <option value={opt.subjectId}>
                                {opt.subjectName}
                                </option>
                              ))
                            : null}
                        </Form.Select>
                        <ErrorMessage
                          name="subject.subjectId"
                          component="div"
                          className="error-msg"
                        />
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={6}>
                      <Form.Group className="mb-4">
                        <Form.Label>Assigned on</Form.Label>
                        <DatePicker
                          dateFormat="MM/dd/yyyy"
                          selected={assignedDate}
                          onChange={(date) => {
                            setAssignedDate(date);
                            setFieldValue("assignedDate", formatDate(date));
                          }}
                          selectsStart
                          startDate={assignedDate}
                          endDate={deadlineDate}
                          minDate={new Date()}
                          className="form-control"
                          placeholderText="Pick a date"
                          onKeyDown={(e) => {
                            e.preventDefault();
                         }}
                        />
                        <ErrorMessage
                          name="assignedDate"
                          component="div"
                          className="error-msg"
                        />
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={6}>
                      <Form.Group className="mb-4" controlId="formQuestName">
                        <Form.Label>Deadline</Form.Label>
                        <DatePicker
                          dateFormat="MM/dd/yyyy"
                          selected={deadlineDate}
                          onChange={(date) => {
                            setDeadlineDate(date);
                            setFieldValue("deadLine", formatDate(date));
                          }}
                          selectsEnd
                          startDate={assignedDate}
                          endDate={deadlineDate}
                          minDate={assignedDate}
                          className="form-control"
                          placeholderText="Pick a date"
                          onKeyDown={(e) => {
                            e.preventDefault();
                         }}
                        />
                        <ErrorMessage
                          name="deadLine"
                          component="div"
                          className="error-msg"
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </Container>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="outline-primary" onClick={props.onHide}>
                  Cancel
                </Button>
                <Button type="submit" className="login btn-block">
                  {!questUpdate ? "Create Quest" : "Update Quest"}
                </Button>
              </Modal.Footer>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default CreateQuestModal;
